import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { keyframes, css } from "styled-components";
import tw from "twin.macro";
import ArrowLeft from "@utility/icons/ArrowLeft";
import ArrowRight from "@utility/icons/ArrowRight";

const NavButtonInner = styled.span`
  ${tw`flex flex-col items-center`}
  will-change: transform, opacity;
  transition: 0.25s transform ease-out, opacity 0.25s ease;
`;
const NavButton = styled.button`
  ${tw`overflow-hidden w-6`}
  height: 20px;
  &:hover,
  &:focus {
    ${tw`outline-none`}
    ${NavButtonInner} {
      ${tw`opacity-75 -translate-y-[49%] lg:-translate-y-[53%]`}
    }
  }
`;
const NavNumber = styled.span`
  ${tw`text-mono-700`}
`;
const Navigation = styled.div`
  ${tw`flex items-center`}
`;
const Progress = styled.span`
  ${tw`bg-mono-300 overflow-hidden inline-block mx-1 relative`}
  height: 1px;
  width: 40px;
  transform: translateY(0.5px);
`;
const progressLineAnim = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;
const ProgressLine = styled.span`
  ${tw`absolute bg-mono-700 left-0`}
  will-change: transform;
  transform: translateX(-100%);
  ${props =>
    css`
      animation: ${props.duration}ms ${progressLineAnim} linear forwards;
    `};
  height: 1px;
  width: 40px;
`;

export default function CarouselControls({ swiperRef, duration }) {
  const [activeIndex, setActiveIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const { swiper } = swiperRef.current;
      swiper.on("slideChange", () => {
        setActiveIndex(
          (swiper.realIndex + swiper.params.slidesPerView) /
            swiper.params.slidesPerView
        );
      });
    }
  }, [swiperRef?.current?.swiper]);
  useEffect(() => {
    function updateTotalPages() {
      setTotalPages(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
          return Math.ceil(
            swiperRef.current.swiper.slides.length /
              swiperRef.current.swiper.params.slidesPerView
          );
        }
      });
    }
    updateTotalPages();
    window.addEventListener("resize", updateTotalPages);
    return () => window.removeEventListener("resize", updateTotalPages);
  }, []);
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  return (
    <Navigation>
      <NavButton type="button" onClick={goPrev}>
        <NavButtonInner>
          <NavNumber className="type-1430 lg:type-1630-loose">
            0{activeIndex}
          </NavNumber>
          <ArrowLeft />
          <span className="sr-only">Previous Slide</span>
        </NavButtonInner>
      </NavButton>
      <Progress>
        <ProgressLine duration={duration} key={activeIndex} />
      </Progress>
      <NavButton type="button" onClick={goNext}>
        <NavButtonInner>
          <NavNumber className="type-1430 lg:type-1630-loose">
            0{totalPages}
          </NavNumber>
          <ArrowRight />
          <span className="sr-only">Next Slide</span>
        </NavButtonInner>
      </NavButton>
    </Navigation>
  );
}

CarouselControls.propTypes = {
  swiperRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired,
  duration: PropTypes.string
};

CarouselControls.defaultProps = {
  duration: "3000"
};
