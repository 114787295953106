import React from "react";
import * as CSS from "csstype";
import { styled } from "twin.macro";
import { Parallax as ParallaxContainer } from "rc-scroll-anim";
import { IAnimationType } from "rc-scroll-anim/lib/ScrollParallax";

type Props = {
  children: React.ReactNode;
  style: CSS.Properties;
  location?: string;
  animation: IAnimationType;
};

const StyledParallax = styled(ParallaxContainer)`
  transition-property: transform;
  transition-timing-function: ease-in;
  will-change: transform;
`;

const Parallax: React.FC<Props> = ({
  children,
  animation,
  style,
  location = ""
}) => {
  return (
    <StyledParallax animation={animation} style={style} location={location}>
      {children}
    </StyledParallax>
  );
};

export default Parallax;
