import React, { useState } from "react";
import tw, { styled } from "twin.macro";

type Props = {
  type: string;
  name: string;
  id?: string;
  inputClass?: string;
  message?: string;
  errorMessage?: string;
  onChange?: (
    e: React.FormEvent & {
      target: HTMLInputElement | HTMLFormElement;
    }
  ) => void;
  required?: boolean;
  style?: { borderColor: string };
};

const InputContainer = styled.div`
  ${tw`flex flex-col relative`}
  &:focus-within label {
    transform: translate(0.25rem, 5px) scale(0.75);
  }
`;

const Input = styled.input`
  ${tw`w-full font-extralight rounded-sm border border-solid border-mono-400 p-5 mb-2 text-mono-600 appearance-none outline-none`};
  height: 60px;
  border-radius: 3px;
  transition: border-color 0.5s ease-in-out;
  &:focus {
    ${tw`border-mono-500`}
  }
`;

const InputLabel = styled.label`
  ${tw`pointer-events-none px-5 text-mono-600 font-extralight absolute type-1630-loose`}
  transform: translate(0, 1.25rem) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  &.is-active {
    transform: translate(0.25rem, 5px) scale(0.75);
  }
`;

// https://dev.to/rafacdomin/creating-floating-label-placeholder-for-input-with-reactjs-4m1f
const FormInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      type,
      name,
      id = "",
      inputClass = "",
      message = "",
      errorMessage = "",
      onChange,
      required = false,
      style
    },
    ref
  ) => {
    const [isActive, setIsActive] = useState(false);

    const handleTextChange = text => {
      if (text !== "") {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };

    return (
      <InputContainer>
        <Input
          tw="type-1630-loose"
          type={type}
          name={name}
          id={id && id !== "" ? id : name}
          required={required}
          onChange={onChange}
          className={inputClass && inputClass !== "" ? inputClass : ""}
          onBlur={e => handleTextChange(e.target.value)}
          ref={ref}
          style={style}
        />
        <InputLabel htmlFor={name} className={isActive ? "is-active" : ""}>
          {message}
        </InputLabel>
        {!!errorMessage && (
          <span className="text-error type-1230 -mt-2">{errorMessage}</span>
        )}
      </InputContainer>
    );
  }
);

// https://github.com/yannickcr/eslint-plugin-react/issues/2269
FormInput.displayName = "FormInput";

export default FormInput;
