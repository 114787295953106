/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import tw, { styled } from "twin.macro";
import React, { useRef, useEffect } from "react";
import SwiperCustom from "react-id-swiper/lib/ReactIdSwiper.custom";
import { Autoplay, Swiper } from "swiper";
import CarouselNavigation from "@utility/CarouselControls";
import clsxm from "@helpers/clsxm";

const CarouselControls = styled.div(({ maxSlides, alignment }) => [
  tw`transform -translate-x-2/4 absolute bottom[-3.5rem] left-2/4`,
  alignment === "landscape-tablet" && maxSlides === 1 && tw`bottom[ -5.5rem]`,
  maxSlides === 3 && tw`bottom[-2.25rem]`,
  maxSlides !== 3 && tw`lg:(translate-x-0 flex static justify-end)`,
  alignment === "center" && tw`flex relative justify-center bottom-0 lg:(justify-center)`
]);

const Carousel = ({
  images,
  children,
  duration,
  maxSlides,
  alignment,
  containerClass,
  carouselHideControls,
  mobileOnly
}) => {
  const swiperRef = useRef(null);
  const params = {
    Swiper,
    modules: [Autoplay],
    lazy: false,
    watchSlidesProgress: true,
    keyboard: {
      enabled: true
    },
    speed: 1000,
    autoplay: {
      delay: duration,
      disableOnInteraction: false
    },
    loop: true,
    loopFillGroupWithBlank: true,
    simulateTouch: false
  };
  if (maxSlides === 2) {
    params.breakpoints = {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 60
      },
      // when window width is >= 992px
      992: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: alignment === "full-width" ? 20 : 60
      }
    };
  } else if (maxSlides === 3) {
    params.breakpoints = {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0
      },
      // when window width is >= 320px
      640: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 0
      },
      // when window width is >= 992px
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 0
      }
    };
  } else {
    params.slidesPerView = 1;
  }

  // Add eventlisteners for swiper after initializing
  useEffect(() => {
    if (
      swiperRef.current &&
      swiperRef.current.swiper &&
      typeof window !== "undefined"
    ) {
      const { swiper } = swiperRef.current;
      swiper.updateSize();
      // prevent blank initial slide on looping
      swiper.on("slideChangeTransitionStart", () => {
        swiper.slideToLoop(swiper?.realIndex, 1000, false);
      });
    }

    return () => {
      if (swiperRef.current && swiperRef.current.swiper) {
        const { swiper } = swiperRef.current;
        swiper.off("slideChangeTransitionStart");
      }
    };
  }, [swiperRef?.current?.swiper]);
  return (
    <>
      <SwiperCustom
        {...params}
        containerClass={`${containerClass} ${mobileOnly && "md:hidden"}`}
        ref={swiperRef}
      >
        {children}
      </SwiperCustom>
      {!carouselHideControls && (
        <CarouselControls
          maxSlides={maxSlides}
          alignment={alignment}
          className={clsxm(
            images &&
              images.length > maxSlides &&
              maxSlides !== 3 &&
              alignment !== "center" &&
              "md:col-offset-10",
            mobileOnly && "md:hidden"
          )}
        >
          <CarouselNavigation swiperRef={swiperRef} duration={duration} />
        </CarouselControls>
      )}
    </>
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.node.isRequired,
  duration: PropTypes.string,
  maxSlides: PropTypes.number,
  alignment: PropTypes.string,
  containerClass: PropTypes.string,
  carouselHideControls: PropTypes.bool,
  mobileOnly: PropTypes.bool
};

Carousel.defaultProps = {
  duration: "3000",
  maxSlides: 1,
  alignment: "Default",
  containerClass: "swiper-container",
  carouselHideControls: false,
  mobileOnly: false
};

export default Carousel;
