import React from "react";
import Moment from "react-moment";

type Props = {
  content: string;
  className?: string;
  format?: string;
};

const Date: React.FC<Props> = ({
  content,
  className,
  format = "MMMM D, YYYY"
}) => {
  if (content !== undefined) {
    return (
      <Moment format={format} className={className}>
        {content}
      </Moment>
    );
  }
  return <p>Define date</p>;
};

export default Date;
