import React from "react";
import tw, { styled } from "twin.macro";
import Link from "@utility/Link";
import { addSlash } from "@src/common";

const LinkImageContainer = styled(Link)`
  ${tw`relative w-full h-full block md:overflow-hidden flex-grow`}
  & > div {
    will-change: transform;
    transition: transform 0.5s cubic-bezier(0, 0, 0.13, 0.99);
  }
  img,
  video {
    ${tw`inline`}
    will-change: transform;
    transition: opacity 0.5s ease, transform 0.5s cubic-bezier(0, 0, 0.13, 0.99) !important;
  }
  &:hover,
  &:focus {
    & > div {
      transform: scale3d(0.97, 0.97, 1);
    }
    img,
    video {
      transform: scale3d(1.03, 1.03, 1) !important;
    }
  }
`;

const ImageContainer = styled.div`
  ${tw`relative w-full h-full block md:overflow-hidden flex-grow`}
`;

type Props = {
  link: string;
  children: React.ReactNode;
  title: string;
};
const ImageHoverContainer: React.FC<Props> = ({
  link,
  children,
  title,
  ...rest
}) => {
  return link ? (
    <LinkImageContainer to={addSlash(link)} title={title} {...rest}>
      <div>{children}</div>
    </LinkImageContainer>
  ) : (
    <ImageContainer title={title} {...rest}>
      <div>{children}</div>
    </ImageContainer>
  );
};

export default ImageHoverContainer;
