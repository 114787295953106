import React from "react";
import tw, { styled, css } from "twin.macro";
import { useInView } from "react-intersection-observer";
import { screen } from "@helpers/media";
import { REVEAL_DURATION } from "@src/common";

const addTransitionDelay = () => {
  let styles = "";
  for (let i = 0; i < 100; i += 1) {
    styles += `
    span:first-of-type span {
      transition-delay: ${REVEAL_DURATION}s, ${REVEAL_DURATION}s;
    }
    span:nth-of-type(${i + 1}) span {
      transition-delay: ${REVEAL_DURATION + i * 0.15}s, ${REVEAL_DURATION +
      i * 0.15}s;
    }
    `;
  }
  return css`
    ${styles}
  `;
};

const AnimatedView = styled.div(({ type }: Props) => [
  css`
    &.is-in-view {
      ${tw`opacity-100`}
      transform: translate3d(0, 0, 0);
      span span {
        ${tw`opacity-100`}
        transform: translate3d(0, 0, 0);
      }
    }
    ${addTransitionDelay()}
    > span {
      ${tw`overflow-hidden`}
      height: 42px;
      @media ${screen.md} {
        height: 66px;
      }
      @media ${screen.lg} {
        height: ${type === "large" ? "98px" : "78px"};
      }
      @media ${screen.xl} {
        height: 98px;
      }
      &.auto-height {
        height: auto;
      }
    }
    span {
      span {
        ${tw`opacity-0`}
        will-change: transform, opacity;
        transform: translate3d(0, 96px, 0);
        transition: transform 1.25s cubic-bezier(0.18, 0.89, 0.32, 1),
          opacity 0.75s cubic-bezier(0.32, 0.24, 0.15, 1);
      }
    }
  `
]);

type Props = {
  children: React.ReactNode;
  type?: "large";
};
const AnimateHeroText: React.FC<Props> = ({ children, type }) => {
  const [ref, inView] = useInView({
    triggerOnce: true
  });
  return (
    <AnimatedView
      ref={ref}
      className={`${inView ? "is-in-view" : ""}`}
      type={type}
    >
      {children}
    </AnimatedView>
  );
};

export default AnimateHeroText;
