/* eslint-disable no-nested-ternary */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import clsxm from "@helpers/clsxm";
import CarouselReveal from "@utility/CarouselReveal.js";
import Image from "@utility/Image";
import Carousel from "@utility/Carousel";
import WorkSectionContainer from "@utility/WorkSectionContainer";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1463%3A6228
 */

// Flexible image that can be inserted into a Blocks
// section within storyblok
// Note: this is expecting the field name of "image"

const ImageClasses = (alignment, type) => {
  let classes = "";
  if (type === "image") {
    if (alignment === "portrait") {
      classes = "md:col-10 md:col-offset-1 lg:col-8 lg:col-offset-2";
    } else if (alignment === "landscape-tablet") {
      classes = "md:col-10 md:col-offset-1 lg:col-6 lg:col-offset-3";
    }
  } else if (type === "caption") {
    if (alignment === "full-width") {
      classes = "col-12 md:col-offset-1 md:col-5";
    } else if (alignment === "portrait") {
      classes = "col-12 md:col-offset-1 md:col-4 lg:col-offset-2";
    } else if (alignment === "landscape-tablet") {
      classes = "col-12 md:col-4 md:col-offset-1 lg:col-offset-3";
    }
  } else if (type === "single-image") {
    if (alignment === "landscape-tablet") {
      classes = "rounded-2xl shadow-mobile xl:shadow-tablet";
    }
  }
  return classes;
};

const ImageWidth = alignment => {
  let width = "1440";
  if (alignment === "portrait" || alignment === "landscape-tablet") {
    width = "1024";
  }
  if (alignment === "full-width") {
    width = "2800";
  }
  return parseInt(width);
};

const ImageSizes = alignment => {
  let sizes = "";
  if (alignment === "portrait" || alignment === "landscape-tablet") {
    sizes =
      "(max-width: 768px) 100vw, (max-width: 992px) 83vw, (max-width: 1440px) 66vw, 1024px"; // 768, 638-823, 655-1056, 1024
  }
  return sizes;
};

const CaptionClasses = (slides, carousel_hide_controls) => {
  let classes = "mt-4 md:mt-8";
  if (slides && slides.length > 1 && !carousel_hide_controls) {
    classes = "-mt-2 md:-mt-5 lg:-mt-6";
  }
  return classes;
};

const Caption = styled.figcaption`
  &:before {
    content: "— ";
  }
`;

const OneUp = ({
  blok,
  blok: {
    has_background,
    align_to_top,
    align_to_bottom,
    alignment,
    caption,
    slides,
    slides_mobile,
    carousel_duration,
    carousel_hide_controls,
    viewport_display,
    work_config: { bg_wash_mobile }
  }
}) => {
  if (slides && slides.length >= 1) {
    const inline = slides.length === 1 || alignment !== "portrait";
    return (
      <WorkSectionContainer blok={blok} inline={inline}>
        <figure className="w-full">
          <div className={`col-12 ${ImageClasses(alignment, "image")}`}>
            {slides_mobile &&
              slides_mobile.length > 1 &&
              alignment === "full-width" && (
                <Carousel
                  images={slides_mobile}
                  alignment="full-width"
                  duration={carousel_duration}
                  carouselHideControls={carousel_hide_controls}
                  mobileOnly
                >
                  {slides_mobile.map((slide, index) => (
                    <Image
                      key={index}
                      image={slide.image}
                      blok={blok}
                      maxWidth={ImageWidth(alignment)}
                      sizes={`${ImageSizes(alignment)}`}
                    />
                  ))}
                </Carousel>
              )}
            {slides && slides.length > 1 ? (
              alignment === "landscape-tablet" ? (
                <Carousel
                  images={slides}
                  alignment="landscape-tablet"
                  duration={carousel_duration}
                  carouselHideControls={carousel_hide_controls}
                  containerClass="swiper-container rounded-2xl shadow-mobile xl:shadow-tablet mb-8"
                >
                  {slides.map((slide, index) => (
                    <Image
                      key={index}
                      image={slide.image}
                      blok={blok}
                      maxWidth={ImageWidth(alignment)}
                      sizes={`${ImageSizes(alignment)}`}
                    />
                  ))}
                </Carousel>
              ) : (
                <CarouselReveal
                  images={slides}
                  duration={carousel_duration}
                  carouselHideControls={carousel_hide_controls}
                  desktopOnly={
                    alignment === "full-width" &&
                    slides_mobile &&
                    slides_mobile.length > 1
                  }
                  hasCaption={!!caption}
                  bgWashMobile={bg_wash_mobile}
                >
                  <Image
                    blok={blok}
                    maxWidth={ImageWidth(alignment)}
                    sizes={`${ImageSizes(alignment)}`}
                  />
                </CarouselReveal>
              )
            ) : (
              <Image
                image={slides[0].image}
                blok={blok}
                maxWidth={ImageWidth(alignment)}
                sizes={`${ImageSizes(alignment)}`}
                className={ImageClasses(alignment, "single-image")}
                tw="flex"
              />
            )}
          </div>
          {caption !== "" && (
            <Caption
              className={clsxm(
                "type-1430 lg:type-1830 col-12",
                ImageClasses(alignment, "caption"),
                CaptionClasses(slides, carousel_hide_controls),
                !inline && "px-[15px]"
              )}
            >
              {caption}
            </Caption>
          )}
        </figure>
      </WorkSectionContainer>
    );
  }
  return (
    <>
      <p>Please update one up images component</p>
    </>
  );
};

OneUp.propTypes = {
  blok: PropTypes.shape({
    has_background: PropTypes.bool,
    align_to_top: PropTypes.bool,
    align_to_bottom: PropTypes.bool,
    alignment: PropTypes.oneOf(["portrait", "landscape-tablet", "full-width"])
      .isRequired,
    slides: PropTypes.array,
    slides_mobile: PropTypes.array,
    caption: PropTypes.string,
    carousel_duration: PropTypes.string,
    carousel_hide_controls: PropTypes.bool,
    viewport_display: PropTypes.string
  })
};

OneUp.defaultProps = {
  blok: {
    has_background: false,
    align_to_top: false,
    align_to_bottom: false,
    caption: "",
    carousel_duration: "3000",
    viewport_display: "block"
  }
};

export default OneUp;
