import React from "react";
import PropTypes from "prop-types";
import Link from "@utility/Link";
import Label from "@utility/Label";
import ScrollInView from "@utility/ScrollInView";
import RichText from "@utility/RichText";
import clsxm from "@helpers/clsxm";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1384%3A1
 */

const NewsListing = ({ blok, blok: { news_items } }) => {
  return (
    <div className="py-25 grid-wrapper">
      <div className="grid-row">
        <div className="col-12 md:col-offset-1 md:col-10 lg:col-offset-2 xl:col-offset-4 xl:col-8">
          <ScrollInView delayChildren="0.3">
            <div className="has-delay-container">
              <Label className="mb-8 lg:mb-20 has-delay">{blok.title}</Label>
              <ul className="w-full">
                {news_items &&
                  news_items.map((news, index) => (
                    <li
                      className={clsxm(
                        "has-delay block mb-12 lg:mb-4",
                        index === news_items.length - 1 && "lg-down:mb-0"
                      )}
                      key={`news-items-${index}`}
                    >
                      <div className="inline-block w-auto text-mono-700 type-1830 lg:type-2030">
                        {news.content.news_type === "full_post" ? (
                          <>
                            <span>
                              {news.content.title}
                              &nbsp;
                            </span>
                            <Link type="underline" to={`/${news.full_slug}/`}>
                              Read More
                            </Link>
                          </>
                        ) : (
                          <RichText content={news.content.simple_headline} />
                        )}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </ScrollInView>
        </div>
      </div>
    </div>
  );
};

NewsListing.propTypes = {
  blok: PropTypes.shape({
    title: PropTypes.string,
    news_items: PropTypes.arrayOf(
      PropTypes.shape({ cached_url: PropTypes.string })
    )
  })
};

NewsListing.defaultProps = {
  blok: {
    title: "",
    news_items: ""
  }
};

export default NewsListing;
