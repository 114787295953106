import React, { useState, useEffect } from "react";
import SbEditable from "storyblok-react";
import styled from "styled-components";
import tw from "twin.macro";
import { screen } from "@helpers/media";
import NavImageSet from "./NavImageSet";
import { NavAssetLayoutType } from "./types";

type Props = {
  sets: NavAssetLayoutType[];
  reset: boolean;
};

const ImageContainer = styled.div`
  ${tw`hidden`}
  @media ${screen.lg} {
    ${tw`w-full fixed inset-0 overflow-hidden h-screen flex`}
    z-index: -1;
  }
`;

// https://aheadcreative.co.uk/articles/avoiding-react-component-re-renders-with-react-memo/
const MemoizedNavImageSet = React.memo(NavImageSet);

const NavAssetLayout: React.FC<Props> = ({ sets, reset }) => {
  const [currentSet, setRotatingSet] = useState<number>(sets.length - 1);
  useEffect(() => {
    if (reset) {
      setRotatingSet(c => (c < sets.length - 1 ? c + 1 : 0));
    }
  }, [reset]);
  return (
    <ImageContainer>
      <SbEditable content={sets}>
        <div className="grid-wrapper w-full">
          <div className="grid-row">
            <MemoizedNavImageSet assets={sets[currentSet]} />
          </div>
        </div>
      </SbEditable>
    </ImageContainer>
  );
};

export default NavAssetLayout;
