import React from "react";
import SbEditable from "storyblok-react";
import ScrollInView from "@utility/ScrollInView";
import FeatureDriver from "./FeatureDriver";
import { FeatureDriverType } from "./types";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1380%3A18
 */

type Props = {
  blok: {
    title: string;
    feature_driver_blocks: FeatureDriverType[];
    _uid: string;
    component: string;
  };
};

const FeatureDriverListing: React.FC<Props> = ({
  blok,
  blok: { feature_driver_blocks, title }
}) => {
  return (
    <SbEditable content={blok}>
      <div className="pt-25 pb-10 md:pb-8 lg:mb-5 lg:pt-32 lg:pb-25 xl:pt-52">
        <div className="grid-wrapper">
          <div className="grid-row">
            <div className="col-10 md:col-6 md:col-offset-2 lg:col-5 lg:col-offset-5 xl:col-4">
              <ScrollInView>
                <div className="has-delay-container">
                  <h2 className="has-delay type-2430 lg:type-3630 mb-10 md:mb-0">
                    {title}
                  </h2>
                </div>
              </ScrollInView>
            </div>
          </div>
        </div>
        <ScrollInView>
          <div className="has-delay-container">
            {feature_driver_blocks &&
              feature_driver_blocks.map(feature_driver_block => (
                <FeatureDriver
                  key={feature_driver_block._uid}
                  blok={feature_driver_block}
                />
              ))}
          </div>
        </ScrollInView>
      </div>
    </SbEditable>
  );
};

export default FeatureDriverListing;
