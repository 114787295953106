import Intro from "@components/Intro";
import Gallery from "@components/Gallery";
import BeliefListing from "@components/BeliefListing";
import ComponentNotFound from "@components/ComponentNotFound";

const ComponentList = {
  intro: Intro,
  list_beliefs: BeliefListing,
  gallery_section: Gallery
};

const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
