import React from "react";
import VideoUtil from "@utility/Video";
import WorkSectionContainer from "@utility/WorkSectionContainer";
import { WorkConfigType } from "@src/types";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1462%3A6
 */

type VideoType = {
  autoplay?: boolean;
  loop?: boolean;
  video_link_sd: string;
  video_link_hd_medium: string;
  video_link_hd: string;
};
type Props = {
  blok: {
    component: string;
    video: VideoType[];
    _uid: string;
    work_config: WorkConfigType;
  };
};
const WorkVideo: React.FC<Props> = ({ blok, blok: { video } }) => {
  return (
    <WorkSectionContainer blok={blok}>
      <div className="w-full">
        <div className="col-12">
          <VideoUtil
            autoPlay={video[0]?.autoplay}
            loop={video[0]?.loop}
            sd={video[0].video_link_sd}
            hdmd={video[0].video_link_hd_medium}
            hd={video[0].video_link_hd}
          />
        </div>
      </div>
    </WorkSectionContainer>
  );
};

export default WorkVideo;
