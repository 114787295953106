import React from "react";
import SbEditable from "storyblok-react";
import Img from "gatsby-image";
import { getFluidGatsbyImage } from "gatsby-storyblok-image";
import tw, { css, styled } from "twin.macro";
import ScrollInView from "@utility/ScrollInView";
import { CUSTOM_BEZIER } from "@src/common";
import { DynamicGlyphType } from "@src/types";

type GlyphPositionType = {
  yPos: "top: 0;" | "top: 50%;" | "bottom: 0;";
  xPos: "left: 0;" | "left: 50%;" | "right: 0;";
  transform: string;
};

const GlyphPosition = styled.div(
  ({ yPos, xPos, transform }: GlyphPositionType) => [
    css`
  ${tw`absolute`}
  ${yPos}
  ${xPos}
  ${transform}
`
  ]
);

type ImageContainerType = {
  width: string;
  opac: string;
};

const ImageContainer = styled.div(({ width, opac }: ImageContainerType) => [
  css`
  ${tw`overflow-hidden`}
  width: ${width}px;
  opacity: ${opac};
  img {
    will-change: transform, opacity;
    transition: transform 0.3s ${CUSTOM_BEZIER},
      opacity 500ms ${CUSTOM_BEZIER} 0s !important;
  }
`
]);
type Props = {
  blok: DynamicGlyphType;
};
const DynamicGlyph: React.FC<Props> = ({
  blok,
  blok: {
    y_position,
    x_position,
    fade_in_delay,
    glyph,
    width = "200",
    y_offset,
    x_offset = "0",
    pixel_offsets = false,
    opacity = "100"
  }
}) => {
  let transform = `transform: translate(${
    x_position && x_position === "2" ? "-50%" : "0"
  },${y_position && y_position === "2" ? "-50%" : "0"})`;
  if ((y_offset && y_offset !== "0") || (x_offset && x_offset !== "0")) {
    transform = `transform: translate(${x_offset || "0"}${
      pixel_offsets ? "px" : "%"
    },${y_offset || "0"}${pixel_offsets ? "px" : "%"})`;
  }
  let opac = "1";
  if (opacity && parseInt(opacity)) {
    opac = (parseInt(opacity) / 100).toFixed(2);
  }
  const ySwitch = {
    "1": "top: 0;",
    "2": "top: 50%;",
    "3": "bottom: 0;"
  };
  const xSwitch = {
    "1": "left: 0;",
    "2": "left: 50%;",
    "3": "right: 0;"
  };
  const yPos = ySwitch[y_position || "2"];
  const xPos = xSwitch[x_position || "2"];

  const getImageContent = () => {
    const isSVG = glyph.indexOf(".svg") > -1;
    if (isSVG) {
      return <img src={glyph} alt="Glyphs by Engine Digital" />;
    }
    const glyphProps = getFluidGatsbyImage(glyph, {
      maxWidth: 900
    });
    return <Img fluid={glyphProps} />;
  };

  return (
    <GlyphPosition yPos={yPos} xPos={xPos} transform={transform}>
      <ScrollInView delaySelf={fade_in_delay || "0"} animateType="-fade">
        <ImageContainer width={width} opac={opac}>
          <SbEditable content={blok}>{getImageContent()}</SbEditable>
        </ImageContainer>
      </ScrollInView>
    </GlyphPosition>
  );
};

export default DynamicGlyph;
