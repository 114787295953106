import React from "react";
import HomePageTemplate from "./HomePage";
import AgencyPageTemplate from "./AgencyPage";
import ServicesPageTemplate from "./ServicesPage";
import WorkPageTemplate from "./WorkLanding";
import ContactPageTemplate from "./ContactPage";
import CareersPageTemplate from "./CareersPage";
import HerolessPageTemplate from "./HerolessPage";
import PerspectiveLandingTemplate from "./PerspectiveLanding";
import PerspectivePostTemplate from "./PerspectivePost";
import NewsPostTemplate from "./NewsPost";
import NewsLandingTemplate from "./NewsLanding";
import WorkPostTemplate from "./WorkPost";
import LottieAnimationPreview from "./LottiePreviewPage";
import LandingPageTemplate from "./LandingPage";
import Page from "./Page";

export default function templating(
  contentType,
  content,
  location = null,
  context
) {
  switch (contentType) {
    case "home_page":
      return (
        <HomePageTemplate
          blok={{ key: content._uid, ...content }}
          pageContext={context}
        />
      );
    case "agency_page":
      return (
        <AgencyPageTemplate
          blok={{ key: content._uid, ...content }}
          pageContext={context}
        />
      );
    case "services_page":
      return (
        <ServicesPageTemplate
          blok={{ key: content._uid, ...content }}
          pageContext={context}
        />
      );
    case "work_page":
      return (
        <WorkPageTemplate
          blok={{ key: content._uid, ...content }}
          pageContext={context}
        />
      );
    case "landing":
      return (
        <LandingPageTemplate
          blok={{ key: content._uid, ...content }}
          pageContext={context}
        />
      );
    case "contact_page":
      return (
        <ContactPageTemplate
          blok={{ key: content._uid, ...content }}
          pageContext={context}
        />
      );
    case "perspective_landing":
      return (
        <PerspectiveLandingTemplate
          blok={{ key: content._uid, ...content }}
          pageContext={context}
        />
      );
    case "perspective_post":
      return (
        <PerspectivePostTemplate
          blok={{ key: content._uid, ...content }}
          pageContext={context}
          full_slug={context ? context.story.full_slug : ""}
        />
      );
    case "news_post":
      return (
        <NewsPostTemplate
          blok={{ key: content._uid, ...content }}
          pageContext={context}
        />
      );
    case "news_landing":
      return (
        <NewsLandingTemplate
          blok={{ key: content._uid, ...content }}
          pageContext={context}
        />
      );
    case "work_post":
      return (
        <WorkPostTemplate
          blok={{ key: content._uid, ...content }}
          pageContext={context}
        />
      );
    case "heroless_page":
      return (
        <HerolessPageTemplate
          blok={{ key: content._uid, ...content }}
          pageContext={context}
        />
      );
    case "careers_page":
      return (
        <CareersPageTemplate
          blok={{ key: content._uid, ...content }}
          pageContext={context}
        />
      );
    case "hero_glyph_animation":
      return (
        <LottieAnimationPreview
          blok={{ key: content._uid, ...content }}
          pageContext={context}
        />
      );
    case "page":
      return (
        <Page blok={{ key: content._uid, ...content }} pageContext={context} />
      );
  }
}
