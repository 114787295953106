import React, { useState, useEffect, useCallback } from "react";
import { useInView } from "react-intersection-observer";
import { AnimatePresence, motion } from "framer-motion";
import SbEditable from "storyblok-react";
import tw, { css, styled } from "twin.macro";
import useWindowDimensions from "@utility/useWindowDimensions";
import ScrollInView from "@utility/ScrollInView";
import { screen } from "@helpers/media";
import Label from "@utility/Label";
import clsxm from "@helpers/clsxm";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1380%3A25
 */

type ImageType = {
  _uid: string;
  image: string;
};
type LoopType = {
  loop_desktop: boolean;
  loop_mobile: boolean;
};
type Props = {
  blok: {
    images: ImageType[];
    mobile_images: ImageType[];
    bg_wash_mobile: boolean;
    bg_wash_desktop: boolean;
    loop_desktop: boolean;
    loop_mobile: boolean;
    label?: boolean;
    title?: boolean;
  };
};

const ScrollInViewStyled = styled(ScrollInView)(
  ({ loop_desktop, loop_mobile }: LoopType) => [
    loop_desktop &&
      css`
        @media ${screen.md} {
          ${tw`absolute w-full inset-0`}
          &.is-in-view.is-animating-desktop,
          &.is-in-view.is-animating-mobile {
            ${tw`opacity-100`}
          }
          &.is-not-animating-desktop,
          &.is-not-animating-mobile {
            ${tw`opacity-0!`}
          }
        }
      `,
    loop_mobile &&
      css`
        @media ${screen.mddown} {
          ${tw`absolute w-full inset-0`}
          &.is-in-view.is-animating-mobile {
            ${tw`opacity-100`}
          }
          &.is-not-animating-mobile {
            ${tw`opacity-0!`}
          }
        }
      `
  ]
);

const getRandomKey = () => {
  return new Date().getTime() / 1000;
};

const LogoListing: React.FC<Props> = ({
  blok: {
    images,
    mobile_images,
    bg_wash_mobile,
    bg_wash_desktop,
    loop_desktop,
    loop_mobile,
    label,
    title
  }
}) => {
  const { windowWidth } = useWindowDimensions();
  const [ref, inView] = useInView({
    triggerOnce: true
  });

  const [count, setCount] = useState<number>(0);

  const chunkImages = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );

  const outputImageChunk = useCallback(() => {
    if (windowWidth < 768) {
      if (mobile_images.length) {
        return chunkImages(mobile_images, 6);
      }
      return chunkImages(images, 6);
    }

    return chunkImages(images, 5);
  }, [windowWidth, mobile_images, images]);

  useEffect(() => {
    if (
      (windowWidth < 768 && loop_mobile) ||
      (windowWidth >= 768 && loop_desktop)
    ) {
      if (inView) {
        const interval = setInterval(() => {
          if (count === outputImageChunk().length - 1) {
            setCount(0);
          } else {
            setCount(count + 1);
          }
        }, 4000);
        return () => clearInterval(interval);
      }
    }
  }, [count, inView, loop_desktop, loop_mobile, outputImageChunk, windowWidth]);

  const outputClasses = i => {
    const logoClasses = [];
    if (loop_desktop && windowWidth >= 768) {
      const desktopClasses =
        i === count ? "is-animating-desktop" : "is-not-animating-desktop";
      logoClasses.push(desktopClasses);
    }
    if (loop_mobile && windowWidth < 768) {
      const mobileClasses =
        i === count ? "is-animating-mobile" : "is-not-animating-mobile";
      logoClasses.push(mobileClasses);
    }
    return logoClasses.toString();
  };

  return (
    <div
      className={clsxm(
        "w-full",
        bg_wash_mobile ? "bg_wash-mobile py-16" : "my-16",
        bg_wash_desktop ? "bg_wash-desktop md:py-32" : "md:my-32"
      )}
    >
      <div className="grid-wrapper">
        <div className="grid-row">
          <div className="col-12 md:col-offset-1 md:col-10">
            <div ref={ref}>
              <ScrollInView>
                <div className="has-delay-container">
                  {!!label && (
                    <Label
                      className={clsxm("has-delay", title ? "mb-10" : "mb-16")}
                    >
                      {label}
                    </Label>
                  )}
                  {!!title && (
                    <h3 className="has-delay type-2830 mb-16 md:type-3630 max-w-[580px]">
                      {title}
                    </h3>
                  )}
                </div>
              </ScrollInView>
              {inView && (
                <AnimatePresence exitBeforeEnter>
                  <div
                    key={getRandomKey()}
                    css={[
                      tw`relative`,
                      loop_desktop &&
                        css`
                          @media ${screen.md} {
                            height: 107px;
                          }
                        `,
                      loop_mobile &&
                        css`
                          @media ${screen.mddown} {
                            height: 234px;
                          }
                          @media ${screen.smdown} {
                            height: 357px;
                          }
                        `
                    ]}
                  >
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      {outputImageChunk().map((imageChunk, i) => (
                        <ScrollInViewStyled
                          delayChildren="0.2"
                          key={`logo-garden-${i}`}
                          loop_desktop={loop_desktop}
                          loop_mobile={loop_mobile}
                          className={outputClasses(i)}
                        >
                          <div
                            className={clsxm(
                              "has-delay-container flex flex-wrap justify-around",
                              !loop_desktop && "md:mb-6",
                              !loop_mobile && "md-down:mb-4"
                            )}
                          >
                            {imageChunk &&
                              imageChunk.map(image => (
                                <div
                                  key={image._uid}
                                  className="has-delay w-1/2 mb-5 text-center sm:w-1/3 md:w-1/5 md:mb-0"
                                >
                                  <SbEditable content={image}>
                                    <img
                                      className="inline"
                                      src={image.image}
                                      width="190"
                                      height="100"
                                      alt="client logo"
                                    />
                                  </SbEditable>
                                </div>
                              ))}
                          </div>
                        </ScrollInViewStyled>
                      ))}
                    </motion.div>
                  </div>
                </AnimatePresence>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoListing;
