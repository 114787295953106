import React, { useState, useEffect, useContext } from "react";
import tw, { styled, css } from "twin.macro";
import { useInView } from "react-intersection-observer";
import { ANIMATE_IN, ANIMATE_IN_UP, REVEAL_DURATION_MS } from "@src/common";
import InitialPageViewContext from "@components/InitialPageViewContext";
import TagWrapper from "@utility/TagWrapper";

type AnimateType = "-fade" | "";

type Props = {
  as?: ElementType;
  children: React.ReactNode;
  delayChildren?: string;
  delaySelf?: string;
  animateType?: AnimateType;
  className?: string;
};

type AnimatedViewType = {
  delaySelf?: string;
  delayChildren?: string;
  animateType?: AnimateType;
};

const addTransitionDelay = (delaySelf, delayChildren) => {
  let styles = "";
  for (let i = 0; i < 100; i += 1) {
    styles += `
     .has-delay-container .has-delay:nth-child(${i}),
     .has-delay-container-richtext > *:nth-child(${i}) {
         transition-delay: ${parseFloat(delaySelf) +
           (i - 1) * parseFloat(delayChildren)}s, ${parseFloat(delaySelf) +
      (i - 1) * parseFloat(delayChildren)}s;
      }
     `;
  }
  return css`
    ${styles}
  `;
};

const AnimatedView = styled(TagWrapper)(
  ({ animateType, delaySelf, delayChildren }: AnimatedViewType) => [
    css`
      ${tw`opacity-0`}
      &.is-in-view {
        ${tw`opacity-100`}
        transform: translate3d(0, 0, 0);
        .has-delay,
        .has-delay-container-richtext > * {
          ${tw`opacity-100`}
          transform: translate3d(0, 0, 0);
        }
      }
      &.is-in-view-fade {
        ${tw`opacity-100`}
        .has-delay,
        .has-delay-container-richtext > * {
          ${tw`opacity-100`}
        }
      }
    `,
    animateType === "-fade"
      ? css`
          ${ANIMATE_IN}
        `
      : css`
          ${ANIMATE_IN_UP}
        `,

    addTransitionDelay(delaySelf, delayChildren),
    css`
      .has-delay,
      .has-delay-container-richtext > * {
        ${tw`opacity-0`}
        ${animateType === "-fade" ? ANIMATE_IN : ANIMATE_IN_UP}
      }
    `
  ]
);

const ScrollInView: React.FC<Props> = ({
  children,
  delayChildren = "0.35",
  delaySelf = "0",
  animateType = "",
  className = "",
  as = "div",
  ...rest
}) => {
  const { initialPageView, isFirstView } = useContext(InitialPageViewContext);
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "75px 0px 75px 0px"
  });
  const [delayOver, setDelayOver] = useState(false);
  useEffect(() => {
    // Delay scrollInView on the first element to
    // show in the viewport
    // to account for the page reveal animation
    if (initialPageView === false && inView) {
      const timer = setTimeout(() => {
        setDelayOver(true);
      }, REVEAL_DURATION_MS);
      isFirstView();
      return () => clearTimeout(timer);
    }
    if (inView) {
      setDelayOver(true);
    }
  }, [inView]);
  return (
    <AnimatedView
      ref={ref}
      as={as}
      className={`${
        inView && delayOver ? `is-in-view${animateType}` : ""
      } ${className}`}
      delayChildren={delayChildren}
      delaySelf={delaySelf}
      animateType={animateType}
      style={delaySelf && { transitionDelay: `${delaySelf}s,${delaySelf}s` }}
      {...rest}
    >
      {children}
    </AnimatedView>
  );
};

export default ScrollInView;
