import React from "react";
import SbEditable from "storyblok-react";
import tw, { css } from "twin.macro";
import { screen } from "@helpers/media";
import Label from "@utility/Label";
import Image from "@utility/Image";
import ScrollInView from "@utility/ScrollInView";
import {
  generateDynamicCol,
  generateDynamicColOffset
} from "@helpers/generateDynamicClasses";
import clsxm from "@helpers/clsxm";
import { Belief } from "./types";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1380%3A7
 */

type Props = {
  blok: Belief;
  agency_text: boolean;
};

const ImageText: React.FC<Props> = ({
  blok,
  agency_text = false,
  blok: {
    has_parallax = false,
    label,
    title,
    image,
    reverse_orientation = false,
    image_col_span,
    col_offset,
    description
  }
}) => {
  const imageRatiosWidth = {
    3: 340,
    4: 460,
    5: 580,
    6: 1200,
    7: 1100
  };
  const imageRatiosHeight = {
    3: 400,
    4: 400,
    5: 400,
    6: 1200,
    7: 1100
  };
  const imageSizes = {
    3: "(max-width: 991px) 41.67vw, (max-width: 1440px) 25vw, 340px",
    4: "(max-width: 991px) 41.67vw, (max-width: 1440px) 33.33vw, 460px",
    5: "(max-width: 1440px) 41.67vw, 580px",
    6: "(max-width: 1440px) 41.67vw, 580px",
    7: "(max-width: 1440px) 41.67vw, 580px"
  };

  return (
    <SbEditable content={blok}>
      <div
        css={[
          tw`grid-overflow__clear`,
          css`
            @media ${screen.mddown} {
              &:before {
                ${tw`h-px inline-block absolute left-0 right-0 bg-mono-300 opacity-25`}
                content: "";
              }
            }
          `
        ]}
      >
        <div className="md:pb-25 lg:pb-50">
          <div
            className={clsxm(
              "grid-row",
              reverse_orientation && "flex-row-reverse"
            )}
          >
            <div
              css={[
                tw`col-12`,
                reverse_orientation
                  ? tw`md:col-6 md:col-offset-1 lg:col-5 xl:col-4 lg:mr-auto`
                  : [
                      tw`md:col-6 lg:col-offset-1 lg:col-5 xl:col-4`,
                      generateDynamicColOffset("xl:col-offset-", col_offset)
                    ]
              ]}
            >
              <div className="flex flex-col justify-center pt-16 pb-12 md:py-0 md:h-full">
                <ScrollInView className="has-delay-container xl:flex">
                  <Label className="has-delay pb-6 md:pb-8 whitespace-nowrap xl:pt-2">
                    {label}
                  </Label>
                  <div className="w-full xl:ml-20">
                    <h2
                      className={clsxm(
                        "has-delay pb-6 md:pb-8",
                        agency_text ? "type-2830" : "type-2430 md:type-2430"
                      )}
                    >
                      {title}
                    </h2>
                    {!!description && (
                      <p
                        className={clsxm(
                          "has-delay",
                          agency_text ? "type-1830" : "type-2030"
                        )}
                      >
                        {description}
                      </p>
                    )}
                  </div>
                </ScrollInView>
              </div>
            </div>
            <div
              css={[
                tw`hidden col-12 md:block md:col-5`,
                generateDynamicCol("lg:col-", image_col_span),
                reverse_orientation
                  ? generateDynamicColOffset("lg:col-offset-", col_offset)
                  : tw`md:col-offset-1`
              ]}
            >
              <ScrollInView>
                <Image
                  image={image}
                  maxWidth={imageRatiosWidth[image_col_span]}
                  maxHeight={imageRatiosHeight[image_col_span]}
                  sizes={imageSizes[image_col_span]}
                  blok={image}
                  hasParallax={has_parallax}
                  css={`
                    max-height: 300px;
                    @media ${screen.md} {
                      max-height: 350px;
                    }
                    @media ${screen.xl} {
                      max-height: 540px;
                    }
                  `}
                />
              </ScrollInView>
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  );
};

export default ImageText;
