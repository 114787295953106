import React from "react";

type Props = {
  blok: {
    component: string;
  };
};

const ComponentNotFound: React.FC<Props> = ({ blok: { component } }) => (
  <div>
    Component{component}
    {' '}
    is not defined. Add it to components.js
</div>
);

export default ComponentNotFound;
