import React, { useContext } from "react";
import { acceptCookies, declineCookies } from "@utility/Cookie";
import CloseButton from "@utility/CloseButton";
import ScrollInView from "@utility/ScrollInView";
import clsxm from "@helpers/clsxm";
import CookieBannerOpenContext from "../CookieBannerOpenContext";

/*
 * Visual Reference
 * https://www.figma.com/file/zODzhJxGWO9syPmmnPEb7z/ED-Website-2022%2F23?node-id=3365%3A31745&t=rqyviShSFYA7E8VX-11
 */

type Props = {
  blok: {
    _uid: string;
    component: string;
  };
};

type BannerProps = {
  show: boolean;
  bg_dark: boolean;
  onAccept: () => void;
  onDecline: () => void;
};

export const CookieBanner: React.FC<BannerProps> = ({
  show,
  bg_dark,
  onAccept,
  onDecline
}) => {
  if (!show) {
    return <></>;
  }

  const footerClass = bg_dark ? "footer-dark is-dark-content" : "";

  return (
    <div
      className={clsxm(
        footerClass,
        "fixed bottom-0 left-0 right-0 z-50",
        !bg_dark && "bg-white"
      )}
    >
      <ScrollInView>
        <div className="grid-wrapper">
          <div className="grid-row">
            <div className="col-12 lg:col-10 lg:col-offset-1">
              <div className="flex flex-wrap items-center justify-between w-full py-5 lg:py-8">
                <div className="flex w-full items-center">
                  <div className="type-1230 lg:type-1630-loose">
                    <p className="inline mr-2">
                      We use cookies to provide an optimal experience. By
                      viewing this website without changing your settings, you
                      consent to receive cookies.
                    </p>
                    <button
                      type="button"
                      className="link underline-link inline"
                      style={{ width: "auto" }}
                      onClick={onDecline}
                    >
                      Decline cookies.
                    </button>
                  </div>
                  <CloseButton
                    onClick={onAccept}
                    className="ml-5 lg:ml-[4.5rem]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollInView>
    </div>
  );
};

const CookieFooter: React.FC<Props> = () => {
  const { cookieBannerOpen, closeBanner } = useContext(CookieBannerOpenContext);

  return (
    <CookieBanner
      show={cookieBannerOpen}
      bg_dark={false}
      onAccept={() => {
        acceptCookies();
        closeBanner();
      }}
      onDecline={() => {
        declineCookies();
        closeBanner();
      }}
    />
  );
};

export default CookieFooter;
