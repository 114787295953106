import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import PropTypes from "prop-types";
import HeaderType from "@utility/Header";
import AnimateKeyframes from "@utility/AnimateKeyframes";
import AnimateHeroText from "@utility/AnimateHeroText";
import HeroGlyphAnimation from "@utility/glyphs/HeroGlyphAnimation";
import TextArea from "@utility/TextArea";
import ThemeContext from "@components/ThemeContext";
import { NAV_HEIGHT_MOBILE, NAV_HEIGHT_DESKTOP } from "@src/common";
import { screen } from "@helpers/media";

const HeaderStyle = styled(HeaderType)`
  ${tw`pb-0 pt-1 w-full`}
  .is-dark & {
    ${tw`text-white`}
  }
`;

const RowContainer = styled.div`
  .is-dark & {
    ${tw`bg-neutral-black`}
  }
`;
const LottieGlyphCycleContainer = styled.div`
  ${tw`absolute block w-full h-full z-30`}
  top:-${NAV_HEIGHT_MOBILE};
  height:calc(100% + ${NAV_HEIGHT_MOBILE});
  @media ${screen.lg} {
    top:-${NAV_HEIGHT_DESKTOP};
    height:calc(100% + ${NAV_HEIGHT_DESKTOP});
  }
`;

const LottieAnimationPreview = ({ blok }) => {
  const { isDark } = useContext(ThemeContext);
  useEffect(() => {
    isDark(true);
    return () => {
      isDark(false);
    };
  }, [isDark]);
  return (
    <div>
      <AnimateKeyframes>
        <RowContainer className="relative">
          <div className="grid-wrapper">
            <div className="pt-12 pb-16 lg:pt-24 lg:pb-48">
              <div className="grid-row relative lg:pb-12 z-50">
                <div className="col-12 lg:-mt-4">
                  <AnimateHeroText type="large">
                    <HeaderStyle className="lg:type-serif-8860">
                      <TextArea
                        text={`We help our clients invent 
                          their future`}
                        mobile_text={`We help our clients 
                          invent their future`}
                        nestedAnimation
                      />
                    </HeaderStyle>
                  </AnimateHeroText>
                </div>
              </div>
            </div>
          </div>
          <LottieGlyphCycleContainer>
            <HeroGlyphAnimation blok={blok} />
          </LottieGlyphCycleContainer>
        </RowContainer>
      </AnimateKeyframes>
    </div>
  );
};

LottieAnimationPreview.propTypes = {
  blok: PropTypes.shape({
    hero_glyphs: PropTypes.array,
    component: PropTypes.string,
    body: PropTypes.array
  }).isRequired,
  pageContext: PropTypes.shape({
    story: PropTypes.object
  })
};

LottieAnimationPreview.defaultProps = {
  pageContext: {}
};

export default LottieAnimationPreview;
