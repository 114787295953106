import React from "react";

const Close = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="1.53906"
      width="2.1757"
      height="26.1084"
      rx="1.08785"
      transform="rotate(-45 0 1.53906)"
    />
    <rect
      x="18.4609"
      width="2.1757"
      height="26.1084"
      rx="1.08785"
      transform="rotate(45 18.4609 0)"
    />
  </svg>
);

export default Close;
