import React from "react";
import Image from "@utility/Image";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1456%3A8
 */

type Props = {
  blok: {
    image: string;
  };
  sizes: string;
};

// write a loop to query for blok with image?

// Flexible image that can be inserted into a Blocks
// section within storyblok
// Note: this is expecting the field name of "image"
const FlexibleImage: React.FC<Props> = ({ blok, sizes }) => {
  return (
    <div className="w-full mb-2 md-down:not-first:ml-2 md:mb-6">
      <Image image={blok.image} blok={blok} sizes={sizes} />
    </div>
  );
};

export default FlexibleImage;
