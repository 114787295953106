import React from "react";
import PropTypes from "prop-types";
import tw, { css } from "twin.macro";
import Link from "@utility/Link";
import Image from "@utility/Image";
import TextArea from "@utility/TextArea";
import ScrollInView from "@utility/ScrollInView";
import Label from "@utility/Label";
/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1423%3A1
 */

const StudioCard = ({
  image,
  image_mobile,
  rlayout,
  phone,
  city,
  address,
  contact_email,
  is_last
}) => {
  const hasMobileImage = !!(image_mobile && image_mobile !== "");
  return (
    <ScrollInView delaySelf="0.25">
      <div
        css={[
          tw`grid-row pt-8 md:pt-25`,
          rlayout ? tw`md:flex-row-reverse` : tw`md:flex-row`,
          is_last ? tw`pb-0` : tw`pb-8 lg:pb-20`
        ]}
      >
        <div
          css={[
            tw`col-12 md:col-offset-1`,
            rlayout ? tw`md:(col-7 mr-auto) lg:col-offset-0` : tw`md:col-6`,
            is_last && tw`lg:col-6`
          ]}
        >
          <Image
            image={image}
            blok={image}
            maxWidth={rlayout ? 1640 : 1400}
            maxHeight={1280}
            quality={80}
            sizes={
              rlayout
                ? "(max-width: 767px) 100vw, (max-width: 1440px) 58.33vw, 820px"
                : "(max-width: 767px) 100vw, (max-width: 1440px) 50vw, 700px"
            }
            mobileImage={hasMobileImage ? image_mobile : ""}
          />
        </div>
        <div
          css={[
            tw`col-12 md:col-4 lg:(col-offset-1 col-3)`,
            !rlayout && tw`md:(col-offset-1 mr-auto)`,
            is_last && tw`lg:col-offset-2`
          ]}
        >
          <div className="flex flex-col justify-center h-full mt-2 mb-8 md:my-0">
            <div className="grid-overflow__clear">
              <Label
                css={[
                  tw`type-2830 pb-6`,
                  css`
                    span {
                      ${tw`line-height[2.4rem]`}
                    }
                  `
                ]}
              >
                {city}
              </Label>
              <div className="type-1630-loose text-white">
                <p className="mb-2">
                  <Link
                    to={`mailto:${contact_email}`}
                    type="underline"
                    data-category="Contact Us"
                    data-action="Click"
                    data-label={contact_email}
                  >
                    General Inquiries
                  </Link>
                </p>
                {!!address && (
                  <div className="mb-2">
                    <TextArea text={address} />
                  </div>
                )}
                <p className="mb-2">
                  <Link
                    to={`tel:${phone}`}
                    data-category="Contact Us"
                    data-action="Click"
                    data-label={phone}
                  >
                    {phone}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScrollInView>
  );
};

StudioCard.defaultProps = {
  rlayout: false,
  is_last: false
};

StudioCard.propTypes = {
  image: PropTypes.string.isRequired,
  image_mobile: PropTypes.string.isRequired,
  is_last: PropTypes.bool,
  rlayout: PropTypes.bool,
  phone: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  contact_email: PropTypes.string.isRequired
};

export default StudioCard;
