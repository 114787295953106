import React from "react";
import PropTypes from "prop-types";
import { AnimatePresence } from "framer-motion";
import Navigation from "@components/Navigation";
import Footer from "@components/Footer";
import PageReveal from "@components/PageReveal";

function renderStaticNavigation(isEditor, isStaticPage, pageContext, location) {
  if (!isEditor && !isStaticPage) {
    return (
      <Navigation
        blok={JSON.parse(pageContext.globalNav.content)}
        componentName={pageContext.story.field_component}
      />
    );
  }
}

function renderStaticFooter(isEditor, isStaticPage, pageContext, location) {
  if (!isEditor && !isStaticPage) {
    return (
      <Footer
        blok={JSON.parse(pageContext.footerNav.content)}
        componentName={pageContext.story.field_component}
        location={location.pathname}
      />
    );
  }
}

const Layout = ({ uri, pageContext, children, location }) => {
  const isEditor = uri === "/editor";
  const isStaticPage = pageContext.globalNav === undefined;
  if (children !== undefined) {
    return (
      <AnimatePresence
        exitBeforeEnter
        onExitComplete={() => {
          if (typeof window !== "undefined") {
            window.scrollTo({ top: 0 });
          }
        }}
      >
        <div key={location?.key}>
          <PageReveal />
          <div className="overflow-x-hidden">
            {renderStaticNavigation(
              isEditor,
              isStaticPage,
              pageContext,
              location
            )}
            <div>
              {/* set min height of page - screen height minus approximate
              height of header and footer */}
              <div css="min-height: calc(100vh - 300px);">
                {React.cloneElement(children, {
                  pageContext
                })}
              </div>
              {renderStaticFooter(
                isEditor,
                isStaticPage,
                pageContext,
                location
              )}
            </div>
          </div>
        </div>
      </AnimatePresence>
    );
  }
  return <div>Define children</div>;
};

Layout.propTypes = {
  children: PropTypes.node,
  pageContext: PropTypes.shape({
    globalNav: PropTypes.shape({
      content: PropTypes.string
    }),
    footerNav: PropTypes.shape({
      content: PropTypes.string
    })
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    key: PropTypes.string
  }),
  uri: PropTypes.string
};

Layout.defaultProps = {
  pageContext: {
    globalNav: {},
    footerNav: {}
  },
  location: {
    pathname: ""
  },
  uri: ""
};
export default Layout;
