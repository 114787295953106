import React, { useRef } from "react";
import tw, { styled, css } from "twin.macro";
import { screen } from "@helpers/media";
import Link from "@utility/Link";
import Image from "@utility/Image";
import { CUSTOM_BEZIER, addSlash } from "@src/common";
import ScrollInView from "@utility/ScrollInView";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1386%3A7
 */

const CardContainer = styled(Link)`
  ${tw`flex flex-col w-full mb-0 lg:mb-6`}
  @media ${screen.sm} {
    ${tw`mx-3 mb-16`}
    width: calc(50% - 1.5rem);
    max-width: 360px;
  }
  @media ${screen.md} {
    ${tw`mx-5`}
    width: calc(33.3333% - 2.5rem);
  }
  @media ${screen.xl} {
    margin-left: 25px;
    margin-right: 25px;
    width: calc(33.3333% - 50px);
  }
  img {
    will-change: transform, opacity;
    transition: transform 1.5s ${CUSTOM_BEZIER},
      opacity 500ms ${CUSTOM_BEZIER} 0s !important;
  }
  &:hover,
  &:focus {
    img {
      transform: scale3d(1.08, 1.08, 1);
    }
    .hover-link {
      background-size: 100% 100%;
    }
  }
`;

type Props = {
  author: string;
  date: string;
  category: string;
  title: string;
  url: string;
  image: string;
  className: string;
  multipleRows?: boolean;
};

const CardPerspective: React.FC<Props> = ({
  author = "",
  date = "",
  category = "",
  title,
  url,
  image,
  className = "",
  multipleRows = false
}) => {
  const renderMetaData = () => {
    if (category && author && date) {
      return (
        <div className="mb-8 flex text-mono-500 md:mb-0 type-1430 lg:type-1630-loose">
          <div>
            <span className="md:hidden">
              {category}
              &nbsp;–&nbsp;
            </span>
            <span className="hidden md:inline-block">{author}</span>
            {/*
            // TODO: add date back when more recent content has been created
            <Date content={date} tw="inline-block" />
            */}
          </div>
        </div>
      );
    }
  };

  const { current: refCard } = useRef(
    <div>
      <div tw="sm:mx-0 sm:w-full grid-overflow">
        <div tw="overflow-hidden relative">
          <Image
            image={image}
            maxWidth={720}
            quality={80}
            blok={image}
            sizes="(max-width: 360px) 100vw, (max-width: 575px) 50vw, (max-width: 767px) 50vw, (max-width: 1440px) 33vw, 360px"
          />
        </div>
      </div>
      <div
        css={[
          css`
            ${tw`relative pt-10 flex flex-col-reverse pb-25 md:flex-col md:px-2 md:pt-8 lg:(px-4 pb-8)`}
            will-change: box-shadow;
            transition: box-shadow 0.3s ease-in-out;
          `
        ]}
      >
        <div>
          <p className="hidden md:block mb-3 text-mono-600 type-1630-loose">
            {category}
          </p>
          <h2 className="text-mono-700 mb-0 type-2430 lg:mb-6">
            <span className="hover-link">{title}</span>
          </h2>
        </div>
        {renderMetaData()}
      </div>
    </div>
  );

  return multipleRows ? (
    <CardContainer
      to={addSlash(url)}
      className={`card-perspective ${className}`}
    >
      <ScrollInView>{refCard}</ScrollInView>
    </CardContainer>
  ) : (
    <CardContainer
      to={addSlash(url)}
      className={`card-perspective ${className}`}
    >
      {refCard}
    </CardContainer>
  );
};

export default CardPerspective;
