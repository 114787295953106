import React from "react";
import { Link as GatsbyLink } from "gatsby";
import gtmPush from "@src/helpers/gtmPush";

type Props = {
  to: string;
  activeClassName?: string;
  className?: string;
  partiallyActive?: boolean;
  title?: string;
  type?:
    | "underline"
    | "hover"
    | "dark-hover"
    | "pill"
    | "dark-pill"
    | "default";
};

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link: React.FC<Props> = ({
  children,
  to,
  activeClassName,
  className = "",
  partiallyActive = false,
  type = "default",
  title,
  ...other
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  const clickHandler = ev => {
    if (Object.entries(ev.target.dataset).length != 0) {
      const { category, action, label } = ev.target.dataset;
      gtmPush(category, action, label);
    }
  };

  const linkStyleConfig = {
    underline: "underline-link",
    hover: "hover-link",
    "dark-hover": "hover-link hover-link--dark",
    pill: "pill-link pill-link--light",
    "dark-pill": "pill-link pill-link--dark",
    default: "underline-link-unstyled"
  };

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        className={`${className} ${
          linkStyleConfig[type]
            ? linkStyleConfig[type]
            : linkStyleConfig.default
        }`}
        title={title}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a
      href={to}
      className={`${className} ${
        linkStyleConfig[type] ? linkStyleConfig[type] : linkStyleConfig.default
      }`}
      target="_blank"
      onClick={clickHandler}
      rel="noopener noreferrer"
      title={title}
      {...other}
    >
      {children}
    </a>
  );
};

export default Link;
