import React, { useRef } from "react";
import SbEditable from "storyblok-react";
import tw, { styled, css } from "twin.macro";
import { screen } from "@helpers/media";
import Link from "@utility/Link";
import Date from "@utility/Date";
import Label from "@utility/Label";
import Video from "@utility/Video";
import Image from "@utility/Image";
import ScrollInView from "@utility/ScrollInView";
import ImageHover from "@utility/ImageHoverContainer";
import { addSlash } from "@src/common";
import { FeaturedWork } from "@src/types";
import {
  generateDynamicCol,
  generateDynamicColOffset
} from "@helpers/generateDynamicClasses";
import clsxm from "@helpers/clsxm";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1419%3A3
 */

type WorkType = "work" | "perspectives" | "list";

type Props = {
  blok: FeaturedWork;
  size: "default" | "large";
  type: WorkType;
};

const WorkDriverContainer = styled.div<{ type: WorkType }>(({ type }) =>
  type !== "perspectives"
    ? css`
        ${tw`pb-25 md:px-6 lg:pb-45 xl:px-0`}
        .is-dark .work-page & {
          ${tw`bg-neutral-black`}
        }
      `
    : tw`md:mb-32`
);

const ContentText = styled.div<{ type: WorkType; size: "default" | "large" }>(
  ({ type, size }) => `
  ${tw`w-full`}
  ${type === "list" && tw`ml-20`}
  ${
    size === "default"
      ? css`
          @media ${screen.xl} {
            max-width: 236px;
          }
        `
      : css`
          @media ${screen.xl} {
            max-width: 360px;
          }
        `
  }}
`
);

const WorkDriver: React.FC<Props> = ({
  blok,
  blok: {
    author = "",
    date = "",
    label = "",
    title = "",
    link,
    logo = "",
    asset_type = "image",
    image = "",
    image_mobile,
    video,
    video_aspect = "56.25",
    video_aspect_mobile = "56.25",
    reverse_orientation = false,
    videoClassName = "",
    image_col_span = "5",
    col_offset = "1",
    description = ""
  },
  type = "work",
  size = "default"
}) => {
  const renderMetaData = () => {
    if (author && date) {
      return (
        <ul className="type-1230 mt-4 flex sm:mt-8">
          <li className="text-mono-700 pr-2 border-r border-mono-700 mr-2">
            {author}
          </li>
          <li>
            <Date content={date} />
          </li>
        </ul>
      );
    }
  };

  const renderLogoContainer = () => {
    if (logo) {
      return (
        <div className="flex justify-between">
          <div className="has-delay pb-6 md:pb-8">
            <img
              src={logo}
              alt={`${label} logo`}
              className="card-scroll__logo"
            />
          </div>
        </div>
      );
    }
  };

  const imageRatiosWidth = {
    5: 1160,
    6: 1400,
    7: 1640
  };
  const imageRatiosHeight = {
    5: 1080,
    6: 1080,
    7: 1080
  };
  const imageSizes = {
    5: "(max-width: 767px) 100vw, (max-width: 1199px) 58.33vw, (max-width: 1440px) 41.66vw, 580px",
    6: "(max-width: 767px) 100vw, (max-width: 1199px) 58.33vw, (max-width: 1440px) 50vw, 700px",
    7: "(max-width: 767px) 100vw, (max-width: 1199px) 58.33vw, 820px"
  };

  const hasMobileImage = image_mobile !== "";

  const hasLink = !!link.url || !!link.cached_url;
  const cachedUrl = link.url !== "" ? link.cached_url : `/${link.cached_url}`;

  const { current: refDesktopImage } = useRef(
    <Image
      image={image}
      blok={image}
      keystring={image}
      maxWidth={imageRatiosWidth[image_col_span]}
      maxHeight={imageRatiosHeight[image_col_span]}
      sizes={imageSizes[image_col_span]}
      multipliers={[0.25, 0.4, 0.5, 0.6, 0.75, 1]}
      mobileImage={hasMobileImage ? image_mobile : ""}
    />
  );

  const { current: refMobileImage } = useRef(
    <Image
      image={image_mobile}
      blok={image_mobile}
      keystring={image_mobile}
      maxWidth={710}
      maxHeight={940}
      sizes="(max-width: 710px) 100vw, 710px"
      multipliers={[0.5, 0.6, 0.75, 0.9, 1]}
      tw="md:hidden"
    />
  );

  const { current: refVideo } = useRef(
    <Video
      videoClassName={videoClassName}
      autoPlay={video[0]?.autoplay}
      loop={video[0]?.loop}
      sd={video[0]?.video_link_sd}
      hdmd={video[0]?.video_link_hd_medium}
      hd={video[0]?.video_link_hd}
      video_aspect={video_aspect}
      video_aspect_mobile={video_aspect_mobile}
      css={[hasMobileImage && tw`hidden md:block`]}
    />
  );

  return (
    <SbEditable content={blok}>
      <WorkDriverContainer className="card-scroll__container" type={type}>
        <div className="grid-wrapper">
          <div
            className={clsxm(
              "card-scroll__container-inner grid-row",
              reverse_orientation
                ? "flex-col-reverse md:flex-row-reverse"
                : "flex-col-reverse md:flex-row"
            )}
          >
            <div
              className="card-scroll__content col-12 md:col-5 lg:col-4 xl:col-3"
              css={[
                reverse_orientation
                  ? tw`mr-auto`
                  : css`
                      ${tw`lg:col-offset-1`} ${generateDynamicColOffset(
                        "xl:col-offset-",
                        col_offset
                      )}
                    `
              ]}
            >
              <div className="card-scroll__content-container flex flex-col justify-center md:py-0 md:h-full">
                <ScrollInView
                  className={clsxm(
                    "has-delay-container pt-12 md:pt-0",
                    type === "list" && "flex",
                    reverse_orientation
                      ? "md:pl-12 lg:pl-16 xl:pl-20"
                      : "md:pr-10 lg:pr-12 xl:pr-16"
                  )}
                >
                  {renderLogoContainer()}
                  <Label className="has-delay pb-8 lg:pb-6">{label}</Label>
                  <ContentText type={type} size={size} className="has-delay">
                    <h2
                      className={clsxm(
                        "md:pb-8",
                        size === "default"
                          ? "type-1830 lg:type-2030"
                          : "type-2430 text-mono-700"
                      )}
                    >
                      {hasLink ? (
                        <Link
                          to={
                            type !== "perspectives"
                              ? addSlash(cachedUrl)
                              : `/${link}/`
                          }
                          type="hover"
                          className={clsxm(
                            size !== "default" && "type-2430 text-mono-700"
                          )}
                        >
                          {title}
                        </Link>
                      ) : (
                        <>{title}</>
                      )}
                    </h2>
                  </ContentText>
                  {!!description && (
                    <ContentText type={type} size={size} className="has-delay">
                      <p
                        className={clsxm(
                          "has-delay",
                          size === "default"
                            ? "type-2030"
                            : "type-1830 md-down:mt-5"
                        )}
                      >
                        {description}
                      </p>
                    </ContentText>
                  )}
                  {renderMetaData()}
                </ScrollInView>
              </div>
            </div>
            <ImageHover
              link={
                hasLink && (type !== "perspectives" ? cachedUrl : `/${link}`)
              }
              className={clsxm(
                "card-scroll__image col-12 md:col-7",
                generateDynamicCol("xl:col-", image_col_span),
                reverse_orientation
                  ? generateDynamicColOffset("xl:col-offset-", col_offset)
                  : "mr-auto"
              )}
              title={label}
            >
              <div className="grid-overflow">
                {asset_type === "image" ? (
                  <ScrollInView key="Image">{refDesktopImage}</ScrollInView>
                ) : (
                  [
                    video[0] && (
                      <ScrollInView key="Video">{refVideo}</ScrollInView>
                    ),
                    hasMobileImage && (
                      <ScrollInView key="MobileImage">
                        {refMobileImage}
                      </ScrollInView>
                    )
                  ]
                )}
              </div>
            </ImageHover>
          </div>
        </div>
      </WorkDriverContainer>
    </SbEditable>
  );
};

export default WorkDriver;
