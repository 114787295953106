import React, { useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";
import PropTypes from "prop-types";
import { screen } from "@helpers/media";
import HeaderType from "@utility/Header";
import AnimateKeyframes from "@utility/AnimateKeyframes";
import AnimateHeroText from "@utility/AnimateHeroText";
import LottieGlyphCycle from "@utility/glyphs/HomepageLottieGlyphAnimation";
import TextArea from "@utility/TextArea";
import Image from "@utility/Image";
import Components from "@builder/ComponentsHome";
import SearchEngines from "@utility/Seo";
import ThemeContext from "@components/ThemeContext";
import ScrollInView from "@utility/ScrollInView";
import HeroInnerContainer from "@utility/HeroInnerContainer";
import {
  BG_WASH_DESKTOP,
  BG_WASH_MOBILE_HERO_WHITE,
  NAV_HEIGHT_MOBILE,
  NAV_HEIGHT_DESKTOP
} from "@src/common";

const BackgroundWash = styled.div`
  ${BG_WASH_DESKTOP}
  ${BG_WASH_MOBILE_HERO_WHITE}
  &:after {
    ${tw`bg-transparent`}
    top: 90%;
  }
`;

const HeaderStyle = styled(HeaderType)`
  ${tw`pb-0 pt-1 w-full`}
  .is-dark & {
    ${tw`text-white`}
  }
`;

const RowContainer = styled.div`
  ${tw`relative z-30`}
  .is-dark & {
    ${tw`bg-neutral-black`}
  }
  min-height: calc(470px - ${NAV_HEIGHT_MOBILE});
  @media ${screen.lg} {
    ${props =>
      props.hero &&
      css`
        height: calc(100vh - ${NAV_HEIGHT_DESKTOP} - 50px);
        min-height: calc(600px - ${NAV_HEIGHT_DESKTOP});
        max-height: calc(840px - ${NAV_HEIGHT_DESKTOP});
      `}
  }
`;

const TitleContainer = styled.div`
  ${tw`py-16`}
  margin-top: -${NAV_HEIGHT_MOBILE};
  @media ${screen.md} {
    ${tw`py-20`}
  }
  @media ${screen.lg} {
    ${tw`pt-40`}
    margin-top: -${NAV_HEIGHT_DESKTOP};
  }
`;

const StyledHeroInnerContainer = styled(HeroInnerContainer)`
  ${tw`lg:-mb-24`}
  margin-bottom: -57px;
`;

const HeroOffset = styled.div`
  ${tw`lg:mt-24`}
  margin-top: 50px;
`;

const PrimaryPageTemplate = ({
  blok,
  blok: {
    seo,
    title,
    mobile_title,
    hero_image,
    hero_image_mobile,
    hero_glyphs,
    body
  },
  pageContext
}) => {
  const { isDark } = useContext(ThemeContext);
  useEffect(() => {
    isDark(true);
    return () => {
      isDark(false);
    };
  }, [isDark]);
  const hasMobileImage = !!(hero_image_mobile && hero_image_mobile !== "");
  return (
    <div>
      <SearchEngines
        title={seo.title || pageContext.story.name || ""}
        description={seo.description || title || ""}
        image={seo.og_image || hero_image || ""}
      />
      <AnimateKeyframes>
        <RowContainer className="relative flex items-center" hero>
          <div className="grid-wrapper w-full">
            <TitleContainer>
              <div className="grid-row relative z-50">
                <div className="col-12">
                  <AnimateHeroText type="large">
                    <HeaderStyle className="lg:type-serif-8860">
                      <TextArea
                        text={title}
                        mobile_text={mobile_title}
                        nestedAnimation
                      />
                    </HeaderStyle>
                  </AnimateHeroText>
                </div>
              </div>
            </TitleContainer>
          </div>
          {hero_glyphs && <LottieGlyphCycle />}
        </RowContainer>
        <RowContainer>
          <div className="grid-wrapper">
            <div className="grid-row">
              <div className="col-12">
                <BackgroundWash>
                  <div className="grid-overflow">
                    <ScrollInView>
                      <StyledHeroInnerContainer>
                        <Image
                          image={hero_image}
                          blok={blok}
                          maxWidth={2640}
                          maxHeight={1632}
                          quality={80}
                          sizes="(max-width: 1440px) 100vw, 1400px"
                          mobileImage={hasMobileImage ? hero_image_mobile : ""}
                          loading="eager"
                        />
                      </StyledHeroInnerContainer>
                    </ScrollInView>
                  </div>
                </BackgroundWash>
              </div>
            </div>
          </div>
        </RowContainer>
      </AnimateKeyframes>
      <HeroOffset>
        {body &&
          body.map(element =>
            React.createElement(Components(element.component), {
              key: element._uid,
              blok: element
            })
          )}
      </HeroOffset>
    </div>
  );
};

PrimaryPageTemplate.propTypes = {
  blok: PropTypes.shape({
    seo: PropTypes.object,
    title: PropTypes.string,
    mobile_title: PropTypes.string,
    hero_image: PropTypes.string,
    hero_image_mobile: PropTypes.string,
    hero_glyphs: PropTypes.array,
    component: PropTypes.string,
    body: PropTypes.array
  }).isRequired,
  seo: PropTypes.objectOf(PropTypes.string),
  pageContext: PropTypes.shape({
    story: PropTypes.object
  })
};

PrimaryPageTemplate.defaultProps = {
  seo: {},
  pageContext: {}
};

export default PrimaryPageTemplate;
