import React from "react";
import SbEditable from "storyblok-react";
import tw, { styled } from "twin.macro";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Link from "@utility/Link";
import Image from "@utility/Image";
import ScrollInView from "@utility/ScrollInView";
import RichText from "@utility/RichText";
import { addSlash } from "@src/common";
import { LinkType } from "@src/types";

/*
 * Visual Reference
 * https://www.figma.com/file/zODzhJxGWO9syPmmnPEb7z/ED-Website-2022%2F23?node-id=3365%3A30866&t=oZT6UCXlvgmhbL3c-4
 */

type Props = {
  blok: {
    title: string;
    cta_label: string;
    link?: LinkType;
    description: string;
    image: string;
    component: string;
    _uid: string;
  };
};

const Description = styled(RichText)`
  ${tw`pb-5 md:pb-0`}
  p {
    ${tw`mb-4`}
  }
`;

const ContentContainer = styled.div`
  ${tw`flex flex-col justify-center md:py-0 md:h-full`}

  h2 {
    ${tw`pb-0`}
  }
`;

const ImageTextDriver: React.FC<Props> = ({
  blok,
  blok: { title, description, cta_label, link, image }
}) => {
  const cachedUrl =
    link?.url !== "" ? link?.cached_url : `/${link?.cached_url}`;

  const renderMetaData = () => {
    if (cta_label && link) {
      return (
        <div className="mt-10">
          {link.id ? (
            <Link
              to={addSlash(cachedUrl)}
              type="pill"
              className="js-gtm"
              data-category="Landing Page"
              data-action="Image Text CTA click"
              data-label="Image Text CTA"
            >
              {cta_label}
            </Link>
          ) : (
            <AnchorLink
              className="pill-link pill-link--light pointer-events-auto js-gtm"
              offset="100"
              href="#contact"
              data-category="Header"
              data-action="Image Text CTA click"
              data-label="Contact Form"
            >
              {cta_label}
            </AnchorLink>
          )}
        </div>
      );
    }
  };

  return (
    <SbEditable content={blok}>
      <div className="bg-neutral-black text-white py-16 md:py-32">
        <div className="grid-wrapper">
          <div className="grid-row flex-col-reverse md:items-center md:flex-row">
            <div className="col-12 md:col-6 lg:col-4 lg:col-offset-1">
              <ContentContainer>
                <ScrollInView>
                  <div className="has-delay-container md:px-8 lg:pr-4 lg:pl-0 xl:pr-12">
                    <div className="w-full">
                      {title && (
                        <h2 className="has-delay type-2830 md:type-3630 mb-10">
                          {title}
                        </h2>
                      )}
                      {!!description && (
                        <Description
                          content={description}
                          className="has-delay type-2430"
                        />
                      )}
                    </div>
                    {renderMetaData()}
                  </div>
                </ScrollInView>
              </ContentContainer>
            </div>
            <div className="md-down:hidden relative w-full h-full block mb-10 col-12 md:col-6 md:mb-0 xl:mr-auto">
              <div className="grid-overflow">
                <Image
                  image={image}
                  maxWidth={1400}
                  maxHeight={1080}
                  quality={80}
                  blok={image}
                  sizes="(max-width: 1440px) 50vw, 700px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  );
};

export default ImageTextDriver;
