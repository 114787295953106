import React from "react";
import SbEditable from "storyblok-react";
import tw, { css, styled } from "twin.macro";
import QuickPoint from "@utility/QuickPoint";
import ScrollInView from "@utility/ScrollInView";
import TextArea from "@utility/TextArea";
import { BG_DARK } from "@src/common";
import { GuidePoint } from "@src/types";
import clsxm from "@helpers/clsxm";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1378%3A6176
 */

type Props = {
  blok: {
    bg_dark: boolean;
    component: string;
    description: string;
    title: string;
    mobile_title: string;
    guide_points: GuidePoint[];
    _uid: string;
  };
};

const BackgroundWash = styled.div`
  ${({ bg_dark }: BgWashProps) =>
    bg_dark &&
    css`
      ${BG_DARK}
    `}
`;

const HeaderStyle = styled.h2`
  ${tw`type-serif-3660 md:type-serif-6460 mb-10 md:mb-12`}
`;

const QuickGuide: React.FC<Props> = ({
  blok,
  blok: { bg_dark, guide_points, description, title, mobile_title }
}) => {
  return (
    <BackgroundWash
      className={clsxm(bg_dark && "is-dark-content", "quick-guide")}
      bg_dark={bg_dark}
    >
      <SbEditable content={blok}>
        <div className="pt-14 pb-14 lg:pt-20 lg:pb-24">
          <div className="grid-wrapper">
            <div className="grid-row">
              <div className="col-12 md:col-7 md:col-offset-1">
                <ScrollInView>
                  <div className="has-delay-container">
                    <HeaderStyle
                      className={clsxm(
                        bg_dark && "text-white",
                        "quick-guide--header"
                      )}
                    >
                      <TextArea
                        text={title}
                        mobile_text={mobile_title}
                        nestedAnimation
                      />
                    </HeaderStyle>
                    {!!description && (
                      <p className="has-delay mb-10 type-2030 lg:type-2240">
                        {description}
                      </p>
                    )}
                  </div>
                </ScrollInView>
              </div>
            </div>
          </div>
          <div className="grid-wrapper">
            <div className="grid-row">
              <div className="col-12 lg:col-offset-1 lg:col-10">
                <ScrollInView>
                  <div className="has-delay-container flex flex-wrap flex-col md:flex-row md:-mx-5 lg:-mx-10">
                    {guide_points &&
                      guide_points.map((guide_point, index) =>
                        React.createElement(QuickPoint, {
                          key: guide_point._uid,
                          blok: guide_point,
                          isLastBlock: index === guide_points.length - 1
                        })
                      )}
                  </div>
                </ScrollInView>
              </div>
            </div>
          </div>
        </div>
      </SbEditable>
    </BackgroundWash>
  );
};

export default QuickGuide;
