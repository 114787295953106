import React, { useRef } from "react";
import tw, { styled } from "twin.macro";
// import Recaptcha from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { navigate } from "gatsby";
import FormInput from "@utility/FormInput";
import FormTextArea from "@utility/FormTextArea";
import FormCheckbox from "@utility/FormCheckbox";
import Button from "@utility/Button";
import gtmPush from "@helpers/gtmPush";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1455%3A1
 */
/*
 * Form submission notifications are managed through Zapier
 * using a Netlify to Gmail Integration. Notifications are not directly sent
 * via Email from netlify because some recipient addresses have hard bounces
 * reference - https://answers.netlify.com/t/forms-notifications-are-not-sending-email-notifications/25729/18
 * Form submissions can also be seen through the Netlify Forms Dashboard
 */

type Props = {
  setIsSubmitted: (isSubmitted: boolean) => void;
};

type FormData = {
  Company: string;
  Email: string;
  JobTitle: string;
  Message: string;
  Name: string;
  status: string;
  Newsletter: boolean;
};

type EncodeData = {
  Company: string;
  Email: string;
  JobTitle: string;
  Message: string;
  Name: string;
  "form-name": string;
};

const FormContainer = styled.div`
  ${tw`relative`}
  z-index:61;
`;

const schema = yup.object().shape({
  Name: yup.string().required("Name is required."),
  Email: yup
    .string()
    .required("Email is required.")
    .email("Please input valid email."),
  Message: yup.string().required("Message is required.")
});

const ContactForm: React.FC<Props> = ({ setIsSubmitted }) => {
  // To renable the captcha functionality be sure to add the env vars back to
  // your .env file as well as in netlify's env SITE_RECAPTCHA_KEY SITE_RECAPTCHA_SECRET

  // const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;
  // if (typeof RECAPTCHA_KEY === "undefined") {
  //   throw new Error(`
  // Env var SITE_RECAPTCHA_KEY is undefined!
  // You probably forget to set it in your Netlify build environment variables.
  // Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
  // Note this demo is specifically for Recaptcha v2
  // `);
  // }
  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  function encode(data: EncodeData) {
    return Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join("&");
  }

  const [state, setState] = React.useState<FormData>();
  const formRef = useRef<HTMLFormElement>(null);
  // const recaptchaRef = useRef(null);

  const handleChange = (
    e: React.FormEvent & {
      target: HTMLFormElement;
    }
  ) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  // const onExpired = () => {
  //   recaptchaRef.current.reset();
  // };

  const onSubmit = async (_: FormData, e: React.FormEvent) => {
    e.preventDefault();

    gtmPush("Contact Us", "Form", "Submit");

    const form = e.target as HTMLFormElement;
    // const recaptchaValue = await recaptchaRef.current.executeAsync();
    fetch("/contact?no-cache=1", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        // "g-recaptcha-response": recaptchaValue,
        ...state
      })
    })
      .then(() => {
        if (state.Newsletter) {
          return fetch(
            "https://n8n.enginedigital.com/webhook/364e9e93-2cbb-41b7-99b0-6241519041c0",
            {
              method: "POST",
              body: new FormData(form)
            }
          );
        }
      })
      .then(() => setIsSubmitted(true))
      .catch(error => alert(error));
  };
  return (
    <FormContainer>
      <form
        ref={formRef}
        name="contact"
        method="POST"
        action="/thank-you"
        data-netlify="true"
        // data-netlify-recaptcha="true"
        netlify-honeypot="status"
        onSubmit={handleSubmit(async (data: FormData, e: React.FormEvent) =>
          onSubmit(data, e)
        )}
        tw="mt-6 mb-12 md:mb-32"
        noValidate
      >
        <input type="text" name="status" tw="invisible" ref={register} />
        <noscript>
          <p>This form won’t work with Javascript disabled</p>
        </noscript>
        <div tw="grid-row">
          <div tw="col-12 mb-3 md:col-6">
            <FormInput
              type="text"
              message="Name*"
              name="Name"
              onChange={handleChange}
              ref={register}
              style={{
                borderColor: errors.Name && "#ff0303"
              }}
              errorMessage={errors.Name?.message}
            />
          </div>
          <div tw="col-12 mb-3 md:col-6">
            <FormInput
              type="text"
              message="Organization"
              name="Company"
              onChange={handleChange}
              ref={register}
            />
          </div>
          <div tw="col-12 mb-3 md:col-6">
            <FormInput
              type="email"
              message="Email*"
              name="Email"
              onChange={handleChange}
              ref={register}
              style={{
                borderColor: errors.Email && "#ff0303"
              }}
              errorMessage={errors.Email?.message}
            />
          </div>
          <div tw="col-12 mb-3 md:col-6">
            <FormInput
              type="text"
              message="Title"
              name="JobTitle"
              onChange={handleChange}
              ref={register}
            />
          </div>
          <div tw="col-12 mb-3">
            <FormTextArea
              message="Message*"
              name="Message"
              onChange={handleChange}
              ref={register}
              cols={30}
              rows={5}
              style={{
                borderColor: errors.Message && "#ff0303"
              }}
              errorMessage={errors.Message?.message}
            />
          </div>
          <div tw="col-12 sm:(flex justify-between items-center my-4)">
            <div tw="sm:(flex justify-between items-center w-full)">
              <FormCheckbox
                name="Newsletter"
                message="Sign up for our e-newsletter?"
                value
                onChange={handleChange}
                tw="mt-4 sm:mt-0"
                ref={register}
              />
              <Button
                button
                label="Send"
                type="submit"
                twStyles={tw`mt-8 sm:(mt-0 ml-8)`}
              />

              {/* <Recaptcha
                ref={recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                size="invisible"
                onExpired={onExpired}
              /> */}
            </div>
          </div>
        </div>
      </form>
    </FormContainer>
  );
};

export default ContactForm;
