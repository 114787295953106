// Grid
export const MAX_WIDTH = "1432px";
// BG Colour
export const BG_WASH_MOBILE = `@media (max-width: 991px) {
  position: relative;
  background-color: #F2F2F2;
}`;
export const BG_WASH_MOBILE_HERO = `@media (max-width: 991px) {
  position: relative;
  &:after {
    background-color: #F2F2F2;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    width: 100vw;
    content: '';
    left: 0;
    z-index: -1;
  }
}`;
export const BG_WASH_MOBILE_HERO_WHITE = `@media (max-width: 991px) {
  position: relative;
  &:after {
    background-color: #FFF;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    width: 100vw;
    content: '';
    left: 0;
    z-index: -1;
    margin-left: calc((100% - 100vw)/2);
  }
}`;
export const BG_WASH_DESKTOP = `@media (min-width: 992px) {
  position: relative;
  &:after {
    background-color: #F2F2F2;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    width: calc(100vw - 20px);
    content: '';
    left: 10px;
    z-index: -1;
  }
}`;
export const BG_WASH_DESKTOP_WHITE = `@media (min-width: 992px) {
  position: relative;
  &:after {
    background-color: #FFF;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    width: 100vw;
    content: '';
    left: 0;
    z-index: -1;
    margin-left: calc((100% - 100vw)/2);
  }
}`;

export const BG_DARK = `
  position: relative;
  background-color: #222;
  @media (min-width: 768px) {
    background-color: transparent;
    &:after {
      background-color: #222;
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      width: 100vw;
      content: '';
      left: 0;
      z-index: -1;
    }
  }
`;
// Navigation
export const NAV_HEIGHT_MOBILE = "112px";
export const NAV_HEIGHT_DESKTOP = "112px";
export const SECONDARY_NAV_HEIGHT_MOBILE = "105px";
export const SECONDARY_NAV_HEIGHT_DESKTOP = "126px";
// Animation
export const ANIMATE_IN_UP = `will-change: transform, opacity;
transform: translate3d(0, 12px, 0); transition: transform 2.5s cubic-bezier(0.32, 0.24, 0.15, 1),
  opacity 1s cubic-bezier(0.32, 0.24, 0.15, 1); @media (min-width: 768px) {transform: translate3d(0, 32px, 0);}`;
export const ANIMATE_IN_UP_SLIGHT = `will-change: transform, opacity;
transform: translate3d(0, 12px, 0); transition: transform 2.5s cubic-bezier(0.32, 0.24, 0.15, 1),
  opacity 1s cubic-bezier(0.32, 0.24, 0.15, 1);`;
export const ANIMATE_IN = `will-change: opacity; transition: opacity 1s cubic-bezier(0.32, 0.24, 0.15, 1);`;
export const ANIMATE_IN_IMPORTANT = `will-change: opacity; transition: opacity 1s cubic-bezier(0.32, 0.24, 0.15, 1) !important;`;
export const ANIMATE_OUT = `will-change: opacity; transition: opacity 1s cubic-bezier(0.32, 0.24, 0.15, 1);`;
export const CUSTOM_BEZIER = `cubic-bezier(0.32, 0.24, 0.15, 1)`;

// Reveal timings
export const REVEAL_DURATION = 0.75;
export const REVEAL_DURATION_MS = 750;

// Helper to ensure links have trailing slashes to
// prevent redirects for SEO purposes
export const addSlash = url =>
  url.charAt(url.length - 1) == "/" || url.endsWith(".pdf") ? url : `${url}/`;

export const widowFixer = (text, chars = 6, words = 2) => {
  const n = text.trim().split(" ");
  // if heading is longer than X words
  if (n.length > words) {
    // find last word, if less than X chars, replace space with &nbsp; before it
    const lastWord = n.pop();
    if (lastWord.length < chars) {
      return `${n.join(" ")}\u00a0${lastWord}`;
    }
  }
  return text;
};
