import React, { useEffect, useRef, useState } from "react";
import SbEditable from "storyblok-react";
import tw, { styled, css } from "twin.macro";
import { animate, useViewportScroll } from "framer-motion";
import { screen } from "@helpers/media";
import AccordionButton from "@utility/AccordionButton";
import ExpandingContainer from "@utility/ExpandingContainer";
import HorizontalLine from "@utility/HorizontalLine";
import RichText from "@utility/RichText";
import clsxm from "@helpers/clsxm";
import { VerticalBlock } from "./types";

type Props = {
  blok: VerticalBlock;
  accordion?: boolean;
  isLastBlock?: boolean;
};

type HeaderProps = {
  accordion?: boolean;
  onClick: () => void;
};

const ContentContainer = styled.div`
  ${({ accordion }: { accordion?: boolean }) => [
    tw`py-10`,
    !accordion && tw`py-16 md:pt-12 md:pb-0 lg:pt-16 xl:pt-20`,
    css`
      li:before {
        content: "–";
        position: absolute;
        left: 0;
      }
      li {
        margin-left: 1rem;
      }
    `
  ]}
`;

const BlockContainer = styled.div`
  ${({ accordion }: { accordion?: boolean }) => [
    tw`w-full`,
    css`
      &:first-of-type {
        ${ContentContainer} {
          ${accordion ? tw`xl:pt-0` : tw`md:pt-0`}
        }
      }
    `,
    css`
      &:last-of-type {
        ${ContentContainer} {
          ${!accordion && tw`pb-0`}
        }
      }
    `
  ]}
`;

const TitleStyle = styled.h3`
  ${({ flip }: { flip?: boolean }) => [
    tw`type-2430 relative flex-shrink-0`,
    !flip && tw`md:pl-5`
  ]}
`;

const LineStyle = styled.span`
  ${({ flip }: { flip?: boolean }) => [
    tw`absolute overflow-hidden md:inline-block`,
    flip ? tw`-right-5` : tw`hidden left-0`,
    css`
      width: 14px;
      will-change: transform;
      transition: transform 0.5s cubic-bezier(0.42, 0, 0.29, 0.99),
        opacity 0.5s ease-out;
    `
  ]}
`;

const LineStyleInner = styled.span`
  ${tw`block`}
  transform: translateX(-15px);
  will-change: transform;
  transition: transform 0.75s cubic-bezier(0.42, 0, 0.29, 0.99) 0.5s;
  .is-in-view & {
    transform: translateX(0);
  }
`;

const AccordionButtonDesktopStyle = styled.div`
  ${tw`hidden`}
  @media ${screen.md} {
    display: block;
    > * {
      display: block;
    }
  }
`;

const HeaderWrapper: React.FC<HeaderProps> = ({
  accordion,
  onClick,
  children
}) => {
  if (accordion) {
    return (
      <button type="button" className="w-full text-left" onClick={onClick}>
        {children}
      </button>
    );
  }

  return <>{children}</>;
};

const Vertical: React.FC<Props> = ({
  blok,
  blok: { title, description, expanded_description },
  accordion,
  isLastBlock
}) => {
  const [showExpandedDescription, setShowExpandedDescription] = useState(false);
  const ref = useRef();
  const { scrollY } = useViewportScroll();

  const AccordionButtonIcon = (
    <AccordionButton
      as="span"
      className={showExpandedDescription ? "is-open" : "is-closed"}
      onClick={() => setShowExpandedDescription(!showExpandedDescription)}
    />
  );

  const Line = (
    <div className={clsxm("grid-overflow block", !accordion && "md:hidden")}>
      <div className="grid-overflow">
        <HorizontalLine />
      </div>
    </div>
  );

  useEffect(() => {
    if (showExpandedDescription && ref.current) {
      const timer = setTimeout(() => {
        const expandedDescriptionIsHidden =
          ref.current.getBoundingClientRect().bottom > window.innerHeight;
        if (expandedDescriptionIsHidden) {
          const pos =
            window.pageYOffset + ref.current.getBoundingClientRect().top;
          animate(scrollY.current, pos, {
            onUpdate: v => window.scroll(0, v)
          });
        }
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [scrollY, showExpandedDescription]);

  return (
    <BlockContainer ref={ref} accordion={accordion}>
      <SbEditable content={blok}>
        {!accordion && Line}
        <ContentContainer accordion={accordion}>
          <HeaderWrapper
            accordion={accordion}
            onClick={() => setShowExpandedDescription(!showExpandedDescription)}
          >
            <div className="grid-row">
              <div
                className={clsxm(
                  "has-delay col-12 md:col-4 lg:col-3 flex justify-between mb-6",
                  !accordion && "md:col-offset-1"
                )}
              >
                <TitleStyle flip={accordion} className="has-delay">
                  <LineStyle flip={accordion}>
                    <LineStyleInner>––</LineStyleInner>
                  </LineStyle>
                  {title}
                </TitleStyle>
                {accordion && (
                  <div className="-mt-1">{AccordionButtonIcon}</div>
                )}
              </div>
              <div
                className={clsxm(
                  "has-delay col-12 flex flex-nowrap",
                  accordion ? "md:col-8 lg:col-9" : "md:col-5"
                )}
              >
                <div
                  className={clsxm(accordion ? "w-full md:px-5" : "md:pl-5")}
                >
                  <RichText className="type-1830" content={description} />
                </div>
                {accordion && (
                  <AccordionButtonDesktopStyle>
                    {AccordionButtonIcon}
                  </AccordionButtonDesktopStyle>
                )}
              </div>
            </div>
          </HeaderWrapper>
          {accordion && (
            <div className="grid-row">
              <div className="col-12 flex flex-nowrap md:col-8 md:col-offset-4 lg:col-9 lg:col-offset-3">
                <ExpandingContainer isOpen={showExpandedDescription}>
                  <div className="w-full md:pl-5 md:pr-12">
                    <RichText
                      className="has-delay type-1830 pt-4"
                      content={expanded_description}
                    />
                  </div>
                </ExpandingContainer>
              </div>
            </div>
          )}
        </ContentContainer>
        {accordion && !isLastBlock && Line}
      </SbEditable>
    </BlockContainer>
  );
};

export default Vertical;
