import React, { useRef } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import PropTypes from "prop-types";

const ExpandingWrap = styled.div`
  ${tw`h-auto overflow-hidden`}
  transition: max-height 0.4s ease;
`;
const ExpandingContainer = ({ className, isOpen, children }) => {
  const content = useRef(null);
  const expandingWrapStyle = {
    maxHeight: isOpen ? `${content.current.scrollHeight}px` : "0px"
  };

  return (
    <ExpandingWrap
      ref={content}
      className={className}
      style={expandingWrapStyle}
    >
      {children}
    </ExpandingWrap>
  );
};

ExpandingContainer.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool
};

ExpandingContainer.defaultProps = {
  className: "",
  isOpen: false
};

export default ExpandingContainer;
