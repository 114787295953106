import React from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import tw from "twin.macro";
import styled from "styled-components";
import Label from "@utility/Label";
import Hero from "@components/Hero";
import HeroInnerContainer from "@utility/HeroInnerContainer.js";
import Link from "@utility/Link";
import ImageHover from "@utility/ImageHoverContainer";
import ScrollInView from "@utility/ScrollInView";
import AnimateKeyframes from "@utility/AnimateKeyframes";
import { addSlash } from "@src/common.js";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1421%3A6191
 */

const HeroDriverContainer = styled.div`
  ${tw`pb-10 md:pb-12 lg:pb-45`}

  &:first-of-type {
    ${tw`pt-16 lg:pt-55`}
  }

  .is-dark & {
    ${tw`bg-neutral-black`}
  }
`;

const StyledLabel = styled(Label)`
  ${tw`pb-6 leading-tight`}
`;

const LogoContainer = styled.div`
  ${tw`flex justify-between pb-6 md:pb-0`}
`;

const HeroDriver = ({
  blok,
  index,
  blok: { hero, link, logo, label, title }
}) => {
  const cached_url = link.url !== "" ? link.cached_url : `/${link.cached_url}`;

  const renderLogoContainer = () => {
    if (logo) {
      return (
        <LogoContainer>
          <img
            src={logo}
            alt={`${label} logo`}
            className="mr-8 has-delay featured-works__logo"
          />
        </LogoContainer>
      );
    }
  };
  return (
    <HeroDriverContainer>
      <div className="grid-wrapper">
        <AnimateKeyframes>
          <HeroInnerContainer>
            <SbEditable content={blok}>
              <ScrollInView>
                <ImageHover link={cached_url} title={label}>
                  <Hero blok={hero[0]} videoClassName="" />
                </ImageHover>
              </ScrollInView>
              <ScrollInView>
                <div className="pt-12 md:pt-16 has-delay-container featured-works__content">
                  <div className="grid-row">
                    <div className="col-12 md:col-offset-3 md:col-2">
                      {renderLogoContainer()}
                    </div>
                    <div className="col-12 md:col-4">
                      <StyledLabel className="has-delay">{label}</StyledLabel>
                      <h2 className="pb-16 md:pb-8 type-1830 lg:type-2030 has-delay">
                        <Link to={addSlash(cached_url)} type="hover">
                          {title}
                        </Link>
                      </h2>
                    </div>
                  </div>
                </div>
              </ScrollInView>
            </SbEditable>
          </HeroInnerContainer>
        </AnimateKeyframes>
      </div>
    </HeroDriverContainer>
  );
};

HeroDriver.propTypes = {
  blok: PropTypes.shape({
    hero: PropTypes.arrayOf(
      PropTypes.shape({
        hero_type: PropTypes.oneOf(["image", "video"]),
        hero_image: PropTypes.string,
        hero_video: PropTypes.array
      })
    ),
    link: PropTypes.shape({
      url: PropTypes.string,
      cached_url: PropTypes.string
    }).isRequired,
    logo: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string
  }),
  index: PropTypes.number
};

HeroDriver.defaultProps = {
  blok: {
    hero: [
      {
        hero_type: "image",
        hero_image: "",
        hero_video: []
      }
    ],
    logo: "",
    label: "",
    title: ""
  },
  index: 1
};

export default HeroDriver;
