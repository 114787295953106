import React, { useState } from "react";
import { getCookieConsent } from "@utility/Cookie";

type CookieBannerOpenContextType = {
  cookieBannerOpen: boolean;
  closeBanner: () => void;
};

const CookieBannerOpen = React.createContext<CookieBannerOpenContextType>({
  cookieBannerOpen: true,
  closeBanner: () => {}
});

export default CookieBannerOpen;

type CookieBannerOpenProviderType = {
  children: React.ReactNode;
};
export function CookieBannerOpenProvider({
  children
}: CookieBannerOpenProviderType) {
  const [cookieBannerOpen, setCookieBannerOpen] = useState<boolean>(
    !getCookieConsent()
  );

  const closeBanner = () => {
    setCookieBannerOpen(false);
  };

  return (
    <CookieBannerOpen.Provider value={{ cookieBannerOpen, closeBanner }}>
      {children}
    </CookieBannerOpen.Provider>
  );
}
