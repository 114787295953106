/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react";
import Layout from "./src/components/Layout.js";
import { ThemeProvider } from "./src/components/ThemeContext.tsx";
import { InitialPageViewProvider } from "./src/components/InitialPageViewContext";
import { CookieBannerOpenProvider } from "./src/components/CookieBannerOpenContext";
import { REVEAL_DURATION_MS } from "./src/common.js";

require("./src/styles/global.css");

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <ThemeProvider {...props}>
      <InitialPageViewProvider {...props}>
        <CookieBannerOpenProvider>
          <Layout {...props}>{element}</Layout>
        </CookieBannerOpenProvider>
      </InitialPageViewProvider>
    </ThemeProvider>
  );
};
// Used for the PageReveal duration to prevent gatsby
// router from scrolling to the top until the exit animation
// is complete, as well as lock scrolling while the page
// reveal is in affect
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => {
      window.scrollTo(0, 0);
    }, REVEAL_DURATION_MS);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(() => {
      window.scrollTo(...(savedPosition || [0, 0]));
    }, REVEAL_DURATION_MS);
  }
  return false;
};
