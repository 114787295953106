const gtmPush = function(category: string, action: string, label: string) {
  if (typeof dataLayer !== "undefined") {
    dataLayer.push({
      event: "GAEvent",
      eventCategory: category,
      eventAction: action,
      eventLabel: label
    });
  }
};

export default gtmPush;
