import PerspectivesListing from "@components/RecentPerspectivesListing";
import SubscribeForm from "@components/SubscribeForm";
import ComponentNotFound from "@components/ComponentNotFound";

const ComponentList = {
  perspectives_listing: PerspectivesListing,
  signup_cta: SubscribeForm
};

const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
