import React, { useState, useRef } from "react";
import tw, { css } from "twin.macro";
import { useViewportScroll, animate } from "framer-motion";
import PropTypes from "prop-types";
import { BG_DARK } from "@src/common";
import DynamicGlyphs from "@utility/glyphs/DynamicGlyphs";
import HeaderType from "@utility/Header";
import HeaderContainer from "@utility/HeaderContainer";
import AnimateKeyframes from "@utility/AnimateKeyframes";
import AnimateHeroText from "@utility/AnimateHeroText";
import StudioCard from "@utility/StudioCard";
import Components from "@builder/ComponentsPerspectiveLanding";
import ContactForm from "@components/ContactForm";
import TextArea from "@utility/TextArea";
import ExpandingWrapper from "@utility/ExpandingContainer";
import SearchEngines from "@utility/Seo";
import ScrollInView from "@utility/ScrollInView";

const ContactPageTemplate = ({
  blok: {
    seo,
    title,
    mobile_title,
    hero_glyphs,
    type,
    body,
    thankyou_title,
    thankyou_subtitle,
    email_signup_thankyou,
    studios,
    body_upper
  },
  pageContext
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const ref = useRef();
  const { scrollY } = useViewportScroll();
  const scrollToContactForm = () => {
    if (ref.current) {
      const pos =
        window.pageYOffset + ref.current.getBoundingClientRect().top - 200;
      animate(scrollY.current, pos, {
        ease: [0.5, 0.25, 0, 1],
        duration: 1.5,
        onUpdate: v => window.scroll(0, v)
      });
    }
  };

  const renderThankYouBlock = () => (
    <ScrollInView delaySelf="0.4" className="relative z-50">
      <div className="grid-wrapper">
        <div className="grid-row">
          <div className="col-12">
            <TextArea
              text={thankyou_subtitle}
              className="type-2430 px-5 text-center max-w-lg mx-auto md:type-2830 md:px-0"
            />
          </div>
        </div>
      </div>
      <div>
        {body_upper &&
          body_upper.map(element =>
            React.createElement(Components(element.component), {
              key: element._uid,
              blok: element
            })
          )}
      </div>
    </ScrollInView>
  );

  return (
    <div>
      <SearchEngines
        title={seo.title || pageContext.story.name || ""}
        description={seo.description || title || ""}
        image={seo.og_image || ""}
      />
      <HeaderContainer>
        <div className="grid-wrapper">
          <div className="grid-row relative z-50">
            <div className="col-12 md:col-8 md:col-offset-2">
              <AnimateHeroText type="large">
                <HeaderType
                  css={css`
                    ${tw`py-0 w-full`}
                    .is-dark & {
                      ${tw`text-white`}
                    }
                  `}
                >
                  <TextArea
                    text={title}
                    mobile_text={mobile_title}
                    nestedAnimation
                  />
                </HeaderType>
              </AnimateHeroText>
            </div>
          </div>
        </div>
        {hero_glyphs && <DynamicGlyphs isHero glyphs={hero_glyphs} />}
        {type === "form" && !isSubmitted && (
          <ScrollInView delaySelf="0.2" className="relative z-50">
            <div className="grid-wrapper">
              <div className="grid-row has-delay pt-10 md:pt-12">
                <div className="col-12 md:text-center">
                  <button
                    type="button"
                    className="!w-auto inline-block type-1830 link underline-link text-left"
                    onClick={() => {
                      setIsOpen(!isOpen);
                      if (!isOpen) {
                        scrollToContactForm();
                      }
                    }}
                  >
                    Tell us about your project
                  </button>
                </div>
              </div>
            </div>
          </ScrollInView>
        )}
      </HeaderContainer>
      {!isSubmitted ? (
        <div ref={ref}>
          {type === "form" ? (
            <div className="grid-wrapper">
              <div className="grid-row">
                <ExpandingWrapper
                  className="col-12 md:col-8 xl:col-6 md:mx-auto lg:-mt-24"
                  isOpen={isOpen}
                >
                  <ContactForm setIsSubmitted={setIsSubmitted} />
                </ExpandingWrapper>
              </div>
            </div>
          ) : (
            // Thank you text for thank you page
            renderThankYouBlock()
          )}
        </div>
      ) : (
        renderThankYouBlock()
      )}
      <AnimateKeyframes>
        <div
          css={BG_DARK}
          className="fade-in fade-in__1 is-dark-content grid-overflow py-25 lg:py-50"
        >
          <div className="grid-wrapper">
            <div className="grid-row">
              <div className="col-12 md:col-offset-5 md:col-7 lg:col-offset-4">
                <h3 className="pb-8 md:pb-0 type-1630-loose grid-overflow__clear">
                  Studios —
                </h3>
              </div>
            </div>
            {studios &&
              studios.map((studio, index) => (
                <StudioCard
                  image={studio.image}
                  image_mobile={studio.image_mobile}
                  rlayout={studio.image_side}
                  phone={studio.phone}
                  location={studio.location}
                  city={studio.city}
                  address={studio.address}
                  contact_email={studio.contact_email}
                  is_last={index === studios.length - 1}
                  key={`studio${index}`}
                />
              ))}
          </div>
        </div>
      </AnimateKeyframes>
      <div>
        {body &&
          body.map(element =>
            React.createElement(Components(element.component), {
              key: element._uid,
              blok: element,
              email_signup_thankyou
            })
          )}
      </div>
    </div>
  );
};

ContactPageTemplate.propTypes = {
  blok: PropTypes.shape({
    seo: PropTypes.object,
    title: PropTypes.string,
    mobile_title: PropTypes.string,
    type: PropTypes.string,
    hero_glyphs: PropTypes.array,
    component: PropTypes.string,
    thankyou_title: PropTypes.string,
    thankyou_subtitle: PropTypes.string,
    email_signup_thankyou: PropTypes.string,
    studios: PropTypes.array,
    body_upper: PropTypes.array,
    body: PropTypes.array
  }).isRequired,
  seo: PropTypes.objectOf(PropTypes.string),
  pageContext: PropTypes.shape({
    story: PropTypes.object
  })
};

ContactPageTemplate.defaultProps = {
  seo: {},
  pageContext: {}
};

export default ContactPageTemplate;
