import React from "react";
import SbEditable from "storyblok-react";
import tw, { styled } from "twin.macro";
import { screen } from "@helpers/media";
import Label, { LineStyle } from "@utility/Label";
import ScrollInView from "@utility/ScrollInView";
import DynamicGlyphs from "@utility/glyphs/DynamicGlyphs";
import { DynamicGlyphType } from "@src/types";
import clsxm from "@helpers/clsxm";
import { Belief } from "./types";
import ImageText from "./ImageText";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1380%3A5
 */

type Props = {
  blok: {
    beliefs: Belief[];
    bg_dark: boolean;
    bg_wash_desktop: boolean;
    bg_wash_mobile: boolean;
    component: string;
    description: string;
    title: string;
    _editable: string;
    _uid: string;
    glyphs: DynamicGlyphType[];
  };
};

const BeliefListingContainer = styled.div`
  ${tw`pt-25 md:pb-16 lg:pb-0`}

  ${LineStyle} {
    ${tw`xl:pt-2`}
  }

  @media ${screen.lg} {
    padding-top: 180px;
  }
`;

const LabelStyle = styled(Label)`
  ${tw`mb-6 md:mb-12 lg:mb-25`}

  ${LineStyle} {
    ${tw`xl:pt-0`}
  }
`;

const BeliefListing: React.FC<Props> = ({
  blok,
  blok: { beliefs, title = "Our Beliefs", description, bg_dark, glyphs }
}) => {
  return (
    <div
      className={clsxm(
        "grid-overflow relative",
        bg_dark && "is-dark-content bg_dark"
      )}
    >
      <SbEditable content={blok}>
        {glyphs && <DynamicGlyphs glyphs={glyphs} />}
        <BeliefListingContainer>
          <div className="grid-wrapper md:pb-2">
            <div className="grid-overflow__clear">
              <div className="grid-row">
                <div className="col-10 xl:col-4 md:col-offset-1">
                  <ScrollInView>
                    <div className="has-delay-container">
                      <LabelStyle className="has-delay">{title}</LabelStyle>
                      {!!description && (
                        <h3 className="has-delay mb-10 type-2430 md:mb-25 lg:type-2830 lg:mb-50">
                          {description}
                        </h3>
                      )}
                    </div>
                  </ScrollInView>
                </div>
              </div>
            </div>
          </div>
          <div className="grid-wrapper">
            {beliefs &&
              beliefs.map(belief => (
                <ImageText key={belief._uid} blok={belief} agency_text />
              ))}
          </div>
        </BeliefListingContainer>
      </SbEditable>
    </div>
  );
};

export default BeliefListing;
