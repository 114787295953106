import React, { ElementType } from "react";
import {
  render,
  MARK_LINK,
  NODE_IMAGE,
  NODE_CODEBLOCK
} from "storyblok-rich-text-react-renderer";
import Image from "@utility/Image";
import Link from "@utility/Link";
import { addSlash } from "@src/common";
import TagWrapper from "@utility/TagWrapper";
import { CSSProp } from "styled-components";

type Props = {
  as?: ElementType;
  content: any;
  className?: string;
  css?: CSSProp;
};

const isEmailLinkType = type => type === "email";
const isStoryLinkType = type => type === "story";
const isUrlLinkType = type => type === "url";

const RichText: React.FC<Props> = ({
  content,
  className = "",
  as = "div",
  css
}) => {
  if (content !== undefined) {
    return (
      <div>
        <TagWrapper
          as={as}
          className={`underline-links ${className}`}
          css={css}
        >
          {render(content, {
            markResolvers: {
              [MARK_LINK]: (children, props) => {
                const { href, target, linktype } = props;
                if (isStoryLinkType(linktype)) {
                  return (
                    <Link type="hover" to={`${addSlash(href)}`}>
                      {children}
                    </Link>
                  );
                }

                if (isEmailLinkType(linktype)) {
                  return <a href={`mailto:${href}`}>{children}</a>;
                }

                if (isUrlLinkType(linktype) && target === "_blank") {
                  return (
                    <a href={href} target={target} rel="noopener noreferrer">
                      {children}
                    </a>
                  );
                }
                return <a href={href}>{children}</a>;
              }
            },
            nodeResolvers: {
              [NODE_IMAGE]: (_, props) => {
                const { src } = props;
                return (
                  <Image
                    image={src.replace("https:", "")}
                    blok={src}
                    maxWidth={1880}
                    quality={80}
                    sizes="(max-width: 940px) 100vw, 940px"
                  />
                );
              },
              [NODE_CODEBLOCK]: children => {
                return <div dangerouslySetInnerHTML={{ __html: children }} />;
              }
            }
          })}
        </TagWrapper>
      </div>
    );
  }
  return <div />;
};

export default RichText;
