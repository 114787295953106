import React from "react";
import SbEditable from "storyblok-react";
import tw, { styled } from "twin.macro";
import Link from "@utility/Link";
import Image from "@utility/Image";
import ScrollInView from "@utility/ScrollInView";
import RichText from "@utility/RichText";
import { addSlash } from "@src/common";
import clsxm from "@helpers/clsxm";
import { FeatureDriverType } from "./types";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1380%3A23
 */

type Props = {
  blok: FeatureDriverType;
};

const Description = styled(RichText)`
  ${tw`pb-5 md:pb-0`}
  p {
    ${tw`mb-4`}
  }
`;

const ContentContainer = styled.div`
  ${tw`flex flex-col justify-center md:py-0 md:h-full`}

  h2 {
    ${tw`pb-0`}
  }
`;

const FeatureDriver: React.FC<Props> = ({
  blok,
  blok: {
    title,
    description,
    cta_label,
    link,
    image,
    image_mobile,
    reverse_orientation = false,
    has_parallax = false
  }
}) => {
  const cachedUrl = link.url !== "" ? link.cached_url : `/${link.cached_url}`;

  const renderMetaData = () => {
    if (cta_label && link) {
      return (
        <div className="md:mt-10">
          <Link to={addSlash(cachedUrl)} type="underline">
            {cta_label}
          </Link>
        </div>
      );
    }
  };

  const hasMobileImage = !!(image_mobile && image_mobile !== "");

  return (
    <SbEditable content={blok}>
      <div className="pb-10 pt-16 border-t border-mono-300 md:border-0 md:py-16 lg:py-25 first-of-type:pt-0 first-of-type:border-0 first-of-type:md:pt-16 first-of-type:lg:pt-25">
        <div className="grid-wrapper">
          <div
            className={clsxm(
              "grid-row flex-col-reverse md:items-center",
              reverse_orientation ? "md:flex-row-reverse" : "md:flex-row"
            )}
          >
            <div
              className={clsxm(
                "col-12 md:col-6 lg:col-4",
                reverse_orientation ? "md:mr-auto" : "lg:col-offset-1"
              )}
            >
              <ContentContainer>
                <ScrollInView>
                  <div
                    className={clsxm(
                      "has-delay-container md:px-8",
                      reverse_orientation
                        ? "lg:pl-6 lg:pr-0 xl:pl-16"
                        : "lg:pr-4 lg:pl-0 xl:pr-12"
                    )}
                  >
                    <div className="w-full">
                      {title && (
                        <h2 className="has-delay hidden md:type-2430 md:block md:mb-8 lg:type-2830">
                          {title}
                        </h2>
                      )}
                      {!!description && (
                        <Description
                          content={description}
                          className="type-1830 has-delay"
                        />
                      )}
                    </div>
                    {renderMetaData()}
                  </div>
                </ScrollInView>
              </ContentContainer>
            </div>
            <div
              className={clsxm(
                "relative w-full h-full block mb-10 col-12 md:col-6 md:mb-0",
                reverse_orientation ? "lg:col-offset-1" : "xl:mr-auto"
              )}
            >
              {title && (
                <h2 className="has-delay mb-10 type-2430 md:hidden">{title}</h2>
              )}
              <div className="grid-overflow">
                <Image
                  image={image}
                  maxWidth={1400}
                  maxHeight={1080}
                  quality={80}
                  blok={image}
                  hasParallax={has_parallax}
                  sizes="(max-width: 1440px) 50vw, 700px"
                  mobileImage={hasMobileImage ? image_mobile : ""}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  );
};

export default FeatureDriver;
