import React from "react";
import SbEditable from "storyblok-react";
import Link from "@utility/Link";
import { addSlash } from "@src/common";
import { NavItemType } from "./types";

type Props = {
  blok: NavItemType;
};

const NavItemSecondary: React.FC<Props> = ({
  blok,
  blok: { nav_link, nav_label }
}) => (
  <SbEditable content={blok}>
    <li className="nav-link--secondary mb-4 lg:mb-0 lg:pl-2 lg:ml-6 type-2430 list-none line-height[44px]!">
      <Link
        activeClassName="hover-link--active"
        type="hover"
        to={`/${
          nav_link.cached_url === "home" ? "" : addSlash(nav_link.cached_url)
        }`}
      >
        {nav_label}
      </Link>
    </li>
  </SbEditable>
);

export default NavItemSecondary;
