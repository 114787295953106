import React, { useState, useEffect } from "react";

type InitialPageViewContextType = {
  initialPageView: boolean;
  isFirstView: () => void;
};

const InitialPageView = React.createContext<InitialPageViewContextType>({
  initialPageView: false,
  isFirstView: () => {}
});

export default InitialPageView;

type InitialPageViewProviderType = {
  children: React.ReactNode;
  location: {
    pathname: string;
  };
};
export function InitialPageViewProvider({
  children,
  location
}: InitialPageViewProviderType) {
  const [initialPageView, setInitialPageView] = useState<boolean>(false);

  const isFirstView = () => {
    setInitialPageView(true);
  };

  useEffect(() => {
    setInitialPageView(false);
  }, [location]);

  return (
    <InitialPageView.Provider value={{ initialPageView, isFirstView }}>
      {children}
    </InitialPageView.Provider>
  );
}
