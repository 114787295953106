import React from "react";
import SbEditable from "storyblok-react";
import ScrollInView from "@utility/ScrollInView";
import { LandingFeature } from "@src/types";

/*
 * Visual Reference
 * https://www.figma.com/file/TWO2VuPSqYKYww3Ni83udq/Bike-Business-Landing-Page?node-id=440%3A413
 */

type Props = {
  blok: LandingFeature;
};

const LandingDriver: React.FC<Props> = ({
  blok,
  blok: { label = "", title = "", description = "" }
}) => {
  return (
    <SbEditable content={blok}>
      <div className="grid-wrapper pb-25 md:pb-50">
        <div className="grid-row">
          <div className="col-12 md:mr-auto md:col-8 lg:col-7 md:col-offset-1" />
        </div>
        <div className="grid-row pt-10">
          <div className="col-1 lg:col-5 lg:col-offset-1 md:col-offset-1">
            <ScrollInView>
              <p className="has-delay pt-1 text-mono-600 type-1830">
                {label} —
              </p>
            </ScrollInView>
          </div>
          <div className="col-10 lg:col-5 lg:col-offset-1 mr-auto">
            <ScrollInView>
              <div>
                <div>
                  {!!title && (
                    <h2 className="pb-6 md:pb-8 type-2430 md:type-3630 text-neutral-black">
                      {title}
                    </h2>
                  )}
                </div>
                <div>
                  {!!description && (
                    <p className="has-delay type-1830 md:type-2030 text-neutral-black">
                      {description}
                    </p>
                  )}
                </div>
              </div>
            </ScrollInView>
          </div>
        </div>
      </div>
    </SbEditable>
  );
};

export default LandingDriver;
