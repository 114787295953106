import React from "react";
import PropTypes from "prop-types";
import HeaderType from "@utility/Header";
import AnimateKeyframes from "@utility/AnimateKeyframes";
import AnimateHeroText from "@utility/AnimateHeroText";
import DynamicGlyphs from "@utility/glyphs/DynamicGlyphs";
import TextArea from "@utility/TextArea";
import Components from "@builder/ComponentsHeroless";
import SearchEngines from "@utility/Seo";
import HeaderContainer from "@utility/HeaderContainer";

const HerolessPageTemplate = ({
  blok,
  blok: { seo, title, mobile_title, body, hero_glyphs },
  pageContext
}) => (
  <div>
    <SearchEngines
      title={seo.title || pageContext.story.name || ""}
      description={seo.description || title || ""}
      image={seo.og_image || ""}
    />
    <AnimateKeyframes>
      <div className="relative lg:pt-20">
        <div className="grid-wrapper">
          <div className="grid-row relative z-50">
            <div className="col-12">
              <AnimateHeroText>
                <HeaderType>
                  <TextArea
                    text={title}
                    mobile_text={mobile_title}
                    nestedAnimation
                  />
                </HeaderType>
              </AnimateHeroText>
            </div>
          </div>
        </div>
        {hero_glyphs && <DynamicGlyphs isHero glyphs={hero_glyphs} />}
      </div>
    </AnimateKeyframes>
    <div>
      {body &&
        body.map(element =>
          React.createElement(Components(element.component), {
            key: element._uid,
            blok: element
          })
        )}
    </div>
  </div>
);

HerolessPageTemplate.propTypes = {
  blok: PropTypes.shape({
    seo: PropTypes.object,
    title: PropTypes.string,
    mobile_title: PropTypes.string,
    component: PropTypes.string,
    hero_glyphs: PropTypes.array,
    body: PropTypes.array
  }).isRequired,
  seo: PropTypes.objectOf(PropTypes.string),
  pageContext: PropTypes.shape({
    story: PropTypes.object
  })
};

HerolessPageTemplate.defaultProps = {
  seo: {},
  pageContext: {}
};

export default HerolessPageTemplate;
