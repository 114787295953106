/* eslint-disable no-nested-ternary */
import React from "react";
import tw, { styled } from "twin.macro";
import PropTypes from "prop-types";
import Image from "@utility/Image";
import Carousel from "@utility/Carousel";
import WorkSectionContainer from "@utility/WorkSectionContainer";
import clsxm from "@helpers/clsxm";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1463%3A6250
 */

// Flexible image that can be inserted into a Blocks
// section within storyblok
// Note: this is expecting the field name of "image"

// needs grid-wrapper conditionally if landing page
// add addWrapper boolean in twoup
// modify work section container to add grid wrapper if addWrapper checked

const Caption = styled.div`
  &:before {
    content: "— ";
  }
`;

const CaptionClasses = (slides, carousel_hide_controls) => {
  let classes = "pt-3 lg:pt-5 lg:pb-3";
  if (slides && slides.length > 2 && !carousel_hide_controls) {
    classes = "-mt-2 md:-mt-5 lg:-mt-6";
  }
  return classes;
};

const ImageSizes = alignment => {
  let sizes = "";
  if (alignment === "landscape-tablet") {
    sizes =
      "(max-width: 768px) 100vw, (max-width: 992px) 83vw, (max-width: 1600px) 66vw, 1024px"; // 768, 638-823, 655-1056, 1024
  }
  if (alignment === "full-width") {
    sizes = "(max-width: 992px) 100vw, (max-width: 1440px) 50vw, 40vw";
  }
  return sizes;
};

const imageContent = (imageSingle, alignment, blok) => {
  const maxWidth = alignment === "full-width" ? 1400 : 1120;
  if (imageSingle !== "") {
    return (
      <Image
        image={imageSingle.image}
        blok={blok}
        maxWidth={maxWidth}
        sizes={`${ImageSizes(alignment)}`}
        css={
          (alignment === "landscape-tablet" &&
            tw`rounded-2xl shadow-mobile xl:shadow-tablet`,
          tw`flex`)
        }
        className={alignment === "landscape-tablet" && "landscape-tablet"}
      />
    );
  }
  return <span className="no-image">&nbsp;</span>;
};

const TwoUp = ({
  blok,
  blok: {
    has_background,
    alignment,
    caption,
    slides,
    carousel_duration,
    carousel_hide_controls,
    viewport_display,
    not_work_page
  }
}) => {
  if (slides && slides.length >= 1) {
    return (
      <WorkSectionContainer blok={blok}>
        {slides.length > 2 ? (
          <div
            className={clsxm(
              alignment === "landscape-tablet"
                ? "col-10 col-offset-1"
                : "col-12",
              alignment !== "full-width" && "lg:col-10 lg:col-offset-1"
            )}
          >
            <div
              className={`${alignment === "landscape-tablet" &&
                "carousel-tablet-2up"}`}
            >
              <Carousel
                images={slides}
                duration={carousel_duration}
                carouselHideControls={carousel_hide_controls}
                maxSlides={2}
                alignment={alignment}
              >
                {slides &&
                  slides.map((slide, index) => (
                    <div className="flex" key={index}>
                      <div className="w-full">
                        {imageContent(slide, alignment, blok)}
                      </div>
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        ) : (
          <>
            <div
              className={clsxm(
                alignment === "landscape-tablet"
                  ? "col-10 col-offset-1 mr-auto"
                  : "col-12",
                alignment === "full-width"
                  ? "lg:col-6"
                  : "lg:col-5 lg:col-offset-1 lg:mr-0"
              )}
            >
              <div
                className={clsxm(
                  "lg-down:py-3",
                  alignment === "landscape-tablet" && "px-2",
                  alignment !== "full-width" && "lg:pr-0 lg:pl-0"
                  // default narrow
                )}
              >
                {imageContent(slides[0], alignment, blok)}
              </div>
            </div>
            <div
              className={clsxm(
                alignment === "landscape-tablet"
                  ? "col-10 col-offset-1 mr-auto"
                  : "col-12",

                alignment === "full-width"
                  ? "lg:col-6"
                  : "lg:col-5 lg:col-offset-0 lg:mr-auto"
              )}
            >
              <div
                className={clsxm(
                  "lg-down:py-3",
                  alignment === "landscape-tablet" && "px-2",
                  alignment !== "full-width" && "lg:pl-0 lg:pr-0"
                )}
              >
                {imageContent(slides[1], alignment, blok)}
              </div>
            </div>
          </>
        )}
        {caption !== "" && (
          <div
            className={clsxm(
              alignment === "landscape-tablet"
                ? "col-10 col-offset-1 mr-auto"
                : "col-12",
              alignment !== "full-width" && "lg:col-offset-1"
            )}
          >
            <Caption
              className={clsxm(
                "type-1430 lg:type-1830",
                alignment === "landscape-tablet" && "px-2 lg:px-0",
                CaptionClasses(slides, carousel_hide_controls)
              )}
            >
              {caption}
            </Caption>
          </div>
        )}
      </WorkSectionContainer>
    );
  }
  return (
    <>
      <p>Please update two up images component</p>
    </>
  );
};

TwoUp.propTypes = {
  blok: PropTypes.shape({
    has_background: PropTypes.bool,
    alignment: PropTypes.oneOf(["default", "landscape-tablet", "full-width"]),
    caption: PropTypes.string,
    carousel_duration: PropTypes.string,
    carousel_hide_controls: PropTypes.bool,
    slides: PropTypes.array,
    viewport_display: PropTypes.string,
    not_work_page: PropTypes.bool
  })
};

TwoUp.defaultProps = {
  blok: {
    has_background: false,
    alignment: "default",
    carousel_duration: "3000",
    viewport_display: "block",
    not_work_page: false
  }
};

export default TwoUp;
