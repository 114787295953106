import React from "react";
import SbEditable from "storyblok-react";
import tw, { styled } from "twin.macro";
import { screen } from "@helpers/media";
import { GuidePoint } from "@src/types";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1378%3A6178
 */

type Props = {
  blok: GuidePoint;
  isLastBlock: boolean;
};

const BlockContainer = styled.div`
  ${tw`w-full md:mx-5 lg:mx-10`}
  @media ${screen.md} {
    width: calc(33.3333% - 2.5rem);
  }
  @media ${screen.lg} {
    width: calc(33.3333% - 5rem);
  }
`;

const QuickPoint: React.FC<Props> = ({
  blok,
  largeText = false,
  blok: { label, body },
  isLastBlock = false
}) => {
  return (
    <BlockContainer className="has-delay">
      <SbEditable content={blok}>
        <div className="py-10">
          <div className="has-delay">
            <h3 className="mb-4 type-1630-loose md:type-1830">
{label} —</h3>
            <p className="type-2030 md:type-2430">{body}</p>
          </div>
        </div>
      </SbEditable>
    </BlockContainer>
  );
};

export default QuickPoint;
