import React, { useRef } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import Video from "@utility/Video";
import Image from "@utility/Image";
import ScrollInView from "@utility/ScrollInView";
import {
  BG_WASH_DESKTOP,
  BG_WASH_DESKTOP_WHITE,
  BG_WASH_MOBILE_HERO,
  BG_WASH_MOBILE_HERO_WHITE
} from "@src/common";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1457%3A12
 */

const bgWash = bgWashType => {
  let styles = "";
  switch (bgWashType) {
    case "grey":
      styles = BG_WASH_DESKTOP + BG_WASH_MOBILE_HERO;
      break;
    case "white":
      styles = BG_WASH_DESKTOP_WHITE + BG_WASH_MOBILE_HERO_WHITE;
      break;
    default:
      styles = "";
  }

  return css`
    ${styles}
  `;
};

const BackgroundWash = styled.div`
  ${props => bgWash(props.bgWashType)}
  &:after {
    top: 90%;
  }
`;

const VideoWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

const Hero = ({
  blok,
  blok: {
    hero_type,
    hero_image,
    hero_image_mobile,
    hero_video,
    video_aspect,
    video_aspect_mobile
  },
  className,
  imgClassName,
  videoClassName,
  bgWashType
}) => {
  const hasMobileImage = !!(hero_image_mobile && hero_image_mobile !== "");

  // Add refs for images so they do not rerender on state change
  // when clicked as links
  const { current: refDesktopImage } = useRef(
    <Image
      image={hero_image}
      blok={hero_image}
      keystring={hero_image}
      maxWidth={2640}
      quality={80}
      maxHeight={1632}
      sizes="(max-width: 1440px) 100vw, 1400px"
      loading="eager"
      mobileImage={hasMobileImage ? hero_image_mobile : ""}
      className={imgClassName}
    />
  );

  const { current: refMobileImage } = useRef(
    <Image
      image={hero_image_mobile}
      blok={hero_image_mobile}
      keystring={hero_image_mobile}
      maxWidth={710}
      maxHeight={940}
      loading="eager"
      sizes="(max-width: 710px) 100vw, 710px"
      className={`${imgClassName} md:hidden`}
    />
  );

  return (
    <SbEditable content={blok}>
      <div className="grid-row">
        <div className="col-12">
          <BackgroundWash
            className="fade-in fade-in__1"
            bgWashType={bgWashType}
          >
            <ScrollInView>
              <div className={`grid-overflow ${className}`}>
                {hero_type === "image" ? (
                  <div>{refDesktopImage}</div>
                ) : (
                  [
                    hero_video[0] && (
                      <VideoWrapper>
                        <Video
                          videoClassName={videoClassName}
                          autoPlay={hero_video[0].autoplay}
                          loop={hero_video[0].loop}
                          sd={hero_video[0].video_link_sd}
                          hdmd={hero_video[0].video_link_hd_medium}
                          hd={hero_video[0].video_link_hd}
                          video_aspect={video_aspect}
                          video_aspect_mobile={video_aspect_mobile}
                          mobileRatio
                          posterImage={hero_video[0].poster_image}
                          className={`${
                            hasMobileImage ? "hidden md:block" : ""
                          } ${imgClassName}`}
                        />
                      </VideoWrapper>
                    ),
                    hasMobileImage && <div>{refMobileImage}</div>
                  ]
                )}
              </div>
            </ScrollInView>
          </BackgroundWash>
        </div>
      </div>
    </SbEditable>
  );
};

Hero.propTypes = {
  blok: PropTypes.shape({
    hero_type: PropTypes.oneOf(["image", "video"]),
    hero_image: PropTypes.string,
    hero_image_mobile: PropTypes.string,
    hero_video: PropTypes.arrayOf(
      PropTypes.shape({
        autoplay: PropTypes.bool,
        loop: PropTypes.bool,
        video_link_sd: PropTypes.string,
        video_link_hd_medium: PropTypes.string,
        video_link_hd: PropTypes.string,
        poster_image: PropTypes.string
      })
    ),
    video_aspect: PropTypes.string,
    video_aspect_mobile: PropTypes.string
  }),
  className: PropTypes.string,
  imgClassName: PropTypes.string,
  videoClassName: PropTypes.string,
  bgWashType: PropTypes.string
};

Hero.defaultProps = {
  blok: {
    hero_type: "image",
    hero_image: "",
    hero_video: [
      {
        autoplay: false,
        loop: false,
        video_link_sd: "",
        video_link_hd_medium: "",
        video_link_hd: "",
        poster_image: ""
      }
    ],
    video_aspect: "75",
    video_aspect_mobile: "75"
  },
  className: "",
  imgClassName: "",
  videoClassName: "",
  bgWashType: ""
};

export default Hero;
