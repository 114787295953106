import { Col, ColOffset, Viewport } from "@src/types";
import tw, { TwStyle } from "twin.macro";

export const generateDynamicCol = (
  prefix: "col-" | "md:col-" | "lg:col-" | "xl:col-",
  colNum: Col
): TwStyle => {
  switch (prefix) {
    case "col-":
      switch (colNum) {
        case "2":
          return tw`col-2`;
        case "3":
          return tw`col-3`;
        case "4":
          return tw`col-4`;
        case "5":
          return tw`col-5`;
        case "6":
          return tw`col-6`;
        case "7":
          return tw`col-7`;
        case "8":
          return tw`col-8`;
        case "9":
          return tw`col-9`;
        case "10":
          return tw`col-10`;
        case "11":
          return tw`col-11`;
        case "12":
          return tw`col-12`;
      }
    case "md:col-":
      switch (colNum) {
        case "2":
          return tw`md:col-2`;
        case "3":
          return tw`md:col-3`;
        case "4":
          return tw`md:col-4`;
        case "5":
          return tw`md:col-5`;
        case "6":
          return tw`md:col-6`;
        case "7":
          return tw`md:col-7`;
        case "8":
          return tw`md:col-8`;
        case "9":
          return tw`md:col-9`;
        case "10":
          return tw`md:col-10`;
        case "11":
          return tw`md:col-11`;
        case "12":
          return tw`md:col-12`;
      }
    case "lg:col-":
      switch (colNum) {
        case "2":
          return tw`lg:col-2`;
        case "3":
          return tw`lg:col-3`;
        case "4":
          return tw`lg:col-4`;
        case "5":
          return tw`lg:col-5`;
        case "6":
          return tw`lg:col-6`;
        case "7":
          return tw`lg:col-7`;
        case "8":
          return tw`lg:col-8`;
        case "9":
          return tw`lg:col-9`;
        case "10":
          return tw`lg:col-10`;
        case "11":
          return tw`lg:col-11`;
        case "12":
          return tw`lg:col-12`;
      }
    case "xl:col-":
      switch (colNum) {
        case "2":
          return tw`xl:col-2`;
        case "3":
          return tw`xl:col-3`;
        case "4":
          return tw`xl:col-4`;
        case "5":
          return tw`xl:col-5`;
        case "6":
          return tw`xl:col-6`;
        case "7":
          return tw`xl:col-7`;
        case "8":
          return tw`xl:col-8`;
        case "9":
          return tw`xl:col-9`;
        case "10":
          return tw`xl:col-10`;
        case "11":
          return tw`xl:col-11`;
        case "12":
          return tw`xl:col-12`;
      }
  }
};

export const generateDynamicColOffset = (
  prefix:
    | "col-offset-"
    | "md:col-offset-"
    | "lg:col-offset-"
    | "xl:col-offset-",
  colNum: ColOffset
): TwStyle => {
  switch (prefix) {
    case "col-offset-":
      switch (colNum) {
        case "0":
          return tw`col-offset-0`;
        case "1":
          return tw`col-offset-1`;
        case "2":
          return tw`col-offset-2`;
        case "3":
          return tw`col-offset-3`;
        case "4":
          return tw`col-offset-4`;
        case "5":
          return tw`col-offset-5`;
        case "6":
          return tw`col-offset-6`;
      }
    case "md:col-offset-":
      switch (colNum) {
        case "0":
          return tw`md:col-offset-0`;
        case "1":
          return tw`md:col-offset-1`;
        case "2":
          return tw`md:col-offset-2`;
        case "3":
          return tw`md:col-offset-3`;
        case "4":
          return tw`md:col-offset-4`;
        case "5":
          return tw`md:col-offset-5`;
        case "6":
          return tw`md:col-offset-6`;
      }
    case "lg:col-offset-":
      switch (colNum) {
        case "0":
          return tw`lg:col-offset-0`;
        case "1":
          return tw`lg:col-offset-1`;
        case "2":
          return tw`lg:col-offset-2`;
        case "3":
          return tw`lg:col-offset-3`;
        case "4":
          return tw`lg:col-offset-4`;
        case "5":
          return tw`lg:col-offset-5`;
        case "6":
          return tw`lg:col-offset-6`;
      }
    case "xl:col-offset-":
      switch (colNum) {
        case "0":
          return tw`xl:col-offset-0`;
        case "1":
          return tw`xl:col-offset-1`;
        case "2":
          return tw`xl:col-offset-2`;
        case "3":
          return tw`xl:col-offset-3`;
        case "4":
          return tw`xl:col-offset-4`;
        case "5":
          return tw`xl:col-offset-5`;
        case "6":
          return tw`xl:col-offset-6`;
      }
  }
};

export const generateDynamicViewport = (className: Viewport) => {
  switch (className) {
    case "block":
      return tw`block`;
    case "hidden lg:block":
      return tw`hidden lg:block`;
    case "block lg:hidden":
      return tw`block lg:hidden`;
    case "block md:hidden":
      return tw`block md:hidden`;
    case "hidden md:block lg:hidden":
      return tw`hidden md:block lg:hidden`;
    case "hidden md:block":
      return tw`hidden md:block`;
  }
};
