import Intro from "@components/Intro";
import LogoListing from "@components/LogoListing";
import FeatureWorkDriverListing from "@components/FeatureWorkDriverListing";
import NewsListing from "@components/NewsListing";
import PerspectivesListing from "@components/RecentPerspectivesListing";
import SubscribeForm from "@components/SubscribeForm";
import ComponentNotFound from "@components/ComponentNotFound";

const ComponentList = {
  intro: Intro,
  featured_works: FeatureWorkDriverListing,
  news_listing: NewsListing,
  perspectives_listing: PerspectivesListing,
  logo_listing: LogoListing,
  signup_cta: SubscribeForm
};

const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
