import React from "react";
import SbEditable from "storyblok-react";
import tw from "twin.macro";
import Label from "@utility/Label";
import ScrollInView from "@utility/ScrollInView";
import DynamicGlyphs from "@utility/glyphs/DynamicGlyphs";
import { BG_DARK } from "@src/common";
import { DynamicGlyphType } from "@src/types";
import { VerticalBlock } from "./types";
import Vertical from "./Vertical";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1386%3A11
 */

type Props = {
  blok: {
    label?: string;
    description: any;
    vertical_blocks: VerticalBlock[];
    component: string;
    _uid: string;
    glyphs: DynamicGlyphType[];
  };
};

const VerticalListing: React.FC<Props> = ({
  blok,
  blok: { vertical_blocks, label = "Vertical experience", description, glyphs }
}) => {
  return (
    <div className="is-dark-content" css={[BG_DARK, tw`relative`]}>
      <SbEditable content={blok}>
        {glyphs && <DynamicGlyphs glyphs={glyphs} />}
        <div className="py-25 lg:py-50">
          <div className="grid-wrapper">
            <div className="grid-row">
              <div className="col-12 md:col-6 md:col-offset-1">
                <ScrollInView>
                  <div className="has-delay-container">
                    <Label className="has-delay mb-10 lg:mb-25">{label}</Label>
                    {!!description && (
                      <p className="has-delay mb-12 type-2430 lg:type-2830 lg:mb-25">
                        {description}
                      </p>
                    )}
                  </div>
                </ScrollInView>
              </div>
            </div>
            <ScrollInView>
              <div className="has-delay-container">
                {vertical_blocks &&
                  vertical_blocks.map(vertical_block => (
                    <Vertical key={vertical_block._uid} blok={vertical_block} />
                  ))}
              </div>
            </ScrollInView>
          </div>
        </div>
      </SbEditable>
    </div>
  );
};

export default VerticalListing;
