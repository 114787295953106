import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import tw, { styled, css } from "twin.macro";
import PropTypes from "prop-types";
import ScrollInView from "@utility/ScrollInView";
import Date from "@utility/Date";
import Link from "@utility/Link";
import SearchEngines from "@utility/Seo";
import RichText from "@utility/RichText";
import Label from "@utility/Label";

const NewsListItem = styled.li`
  ${tw`pb-16 border-b border-mono-300 px-5 mb-16 w-auto md:w-full md:border-0 md:w-full md:mx-0 md:p-0`}
  margin-left: -1.25rem;
  margin-right: -1.25rem;

  ${({ isLast }) =>
    isLast &&
    css`
      ${tw`border-0 mb-0`}
    `}

  .underline-links a {
    ${tw`text-mono-600`}
  }
`;

const LabelStyle = styled(Label)`
  ${tw`type-1630-loose text-mono-600`}

  margin-top: 10px;
`;

const renderNewsHeadline = (news_type, simple_headline, news_title) => {
  if (simple_headline !== "" && news_type === "simple_head") {
    return (
      <RichText
        as="h2"
        className="type-2030 md:type-2030 text-mono-600 inline"
        content={simple_headline}
      />
    );
  }
  return (
    <h2 className="type-2030 md:type-2030 text-mono-600 inline">
      {news_title}
    </h2>
  );
};

const renderNewsDate = date => date !== "" && <Date content={date} />;

const renderNewsLink = (news_type, slug) => {
  if (news_type !== "" && news_type !== "simple_head") {
    return (
      <span>
        &nbsp;
        <Link
          type="underline"
          className="type-2030 md:type-2030 text-mono-600 ml-1"
          to={`/${slug}/`}
        >
          Read More
        </Link>
      </span>
    );
  }
  return null;
};

function NewsLandingTemplate({
  blok: { seo, title, hero_image },
  pageContext
}) {
  // Use useStaticQuery is required here because this is technically a component within
  // the page storyblokEntry
  const data = useStaticQuery(
    graphql`
      query {
        allStoryblokEntry(
          sort: { field_date_string: DESC }
          filter: {
            field_component: { eq: "news_post" }
            published_at: { ne: null }
          }
        ) {
          edges {
            node {
              full_slug
              content
            }
          }
        }
      }
    `
  );

  return (
    <div>
      <SearchEngines
        title={seo.title || pageContext.story.name || ""}
        description={seo.description || title || ""}
        image={seo.og_image || hero_image || ""}
      />
      <h1 className="sr-only">{title}</h1>
      {/* Initial delay on container to prevent list items from fading in immediately after the page reveal */}
      <ScrollInView className="grid-wrapper" delaySelf="1">
        <ul className="w-full pt-24 md:pt-32 lg:pt-40 md:mb-24 lg:mb-52">
          {data.allStoryblokEntry.edges.map(
            ({ node, node: { content } }, index) => {
              const contentObj = JSON.parse(content);
              return (
                <NewsListItem
                  key={`storyblok-link-${contentObj._uid}`}
                  isLast={index + 1 === data.allStoryblokEntry.edges.length}
                >
                  <ScrollInView className="grid-row">
                    <div className="col-12 lg:col-3 lg:col-offset-1 mb-2 lg:mb-0">
                      <LabelStyle>{renderNewsDate(contentObj.date)}</LabelStyle>
                    </div>
                    <div className="col-12 lg:col-5">
                      {renderNewsHeadline(
                        contentObj.news_type,
                        contentObj.simple_headline,
                        contentObj.title
                      )}
                      {renderNewsLink(contentObj.news_type, node.full_slug)}
                    </div>
                  </ScrollInView>
                </NewsListItem>
              );
            }
          )}
        </ul>
      </ScrollInView>
    </div>
  );
}

NewsLandingTemplate.propTypes = {
  blok: PropTypes.shape({
    seo: PropTypes.object,
    title: PropTypes.string,
    hero_image: PropTypes.string
  }).isRequired,
  seo: PropTypes.objectOf(PropTypes.string),
  pageContext: PropTypes.shape({
    story: PropTypes.object
  })
};

NewsLandingTemplate.defaultProps = {
  seo: {},
  pageContext: {}
};

export default NewsLandingTemplate;
