import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import PropTypes from "prop-types";

// TODO: apply hero animation text transition: .hero-animation-text-1
const HeaderStyle = styled.h1`
  ${tw`md:text-center`}
`;

const Header = ({ children, className }) => (
  <HeaderStyle className={`type-serif-4260 lg:type-serif-6460 ${className}`}>
    {children}
  </HeaderStyle>
);

Header.defaultProps = {
  className: ""
};

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Header;
