import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import Image from "@utility/Image";

type Props = {
  uid: string;
};

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1421%3A6201
 */

const CardBody = styled.div`
  ${tw`flex items-center`}
`;

const ImageBody = styled.div`
  ${tw`mr-8 flex flex-col justify-center`}
  width: 140px;
  img {
    ${tw`my-0`}
  }
`;

const AuthorInfo = styled.div`
  ${tw`w-1/2 flex flex-col justify-center`};
`;
const AuthorName = styled.span`
  ${tw`font-normal m-0`};
`;
const AuthorJob = styled.span`
  ${tw`m-0`};
`;

const CardAuthor: React.FC<Props> = ({ uid = "" }) => {
  // Use useStaticQuery is required here because this is technically a component within
  // the page storyblokEntry
  if (uid) {
    const data = useStaticQuery(
      graphql`
        query {
          allStoryblokEntry(filter: { field_component: { eq: "authors" } }) {
            edges {
              node {
                uuid
                field_job_title_string
                field_title_string
                field_image_string
              }
            }
          }
        }
      `
    );
    const author = data.allStoryblokEntry.edges.filter(
      ({ node }) => node.uuid === uid
    );
    return (
      <CardBody>
        <ImageBody>
          <Image
            image={author[0].node.field_image_string}
            blok={author}
            maxWidth={280}
            maxHeight={280}
            quality={80}
          />
        </ImageBody>

        <AuthorInfo className="type-2030">
          <AuthorName className="type-2230">
            {author[0].node.field_title_string}
          </AuthorName>
          <AuthorJob className="type-1830">
            {author[0].node.field_job_title_string}
          </AuthorJob>
        </AuthorInfo>
      </CardBody>
    );
  }
  return <p>Define author</p>;
};

export default CardAuthor;
