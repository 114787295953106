import React from "react";
import SbEditable from "storyblok-react";
import Navigation from "@components/Navigation";
import Footer from "@components/Footer";
import templating from "@templates/Templating.js";
import config from "../../gatsby-config";

const sbConfigs = config.plugins.filter(item => {
  return item.resolve === "gatsby-source-storyblok";
});
const sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {};

const loadStoryblokBridge = function(cb) {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${sbConfig.options.accessToken}`;
  script.onload = cb;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const getParam = function(val) {
  let result = "";
  let tmp = [];

  window.location.search
    .substr(1)
    .split("&")
    .forEach(function(item) {
      tmp = item.split("=");
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1]);
      }
    });

  return result;
};

const renderStaticNavigation = (
  globalNav,
  footerNav,
  location,
  contentType
) => {
  if (contentType !== "work_post") {
    return (
      <Navigation blok={globalNav} footer={footerNav} location={location} />
    );
  }
};

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      story: null,
      globalNav: { content: {} },
      footerNav: { content: {} }
    };
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents();
    });
  }

  loadStory(payload) {
    window.storyblok.get(
      {
        slug: getParam("path"),
        version: "draft",
        resolve_relations: sbConfig.options.resolveRelations || [] // https://github.com/storyblok/gatsby-storyblok-boilerplate/commit/8b1ebef4033a0ddd27870b32f8e6f626c9edf071,
      },
      data => {
        this.setState({ story: data.story });
        if (data.story.content.footer) {
          this.setState({ footerNav: data.story.content.footer });
        } else {
          this.loadGlobalFooter(data.story.lang, data.story.content.footer);
        }

        if (data.story.content.navigation) {
          this.setState({ globalNav: data.story.content.navigation });
        } else {
          this.loadGlobalNav(data.story.lang);
        }
      }
    );
  }

  loadGlobalNav(lang) {
    const language = lang === "default" ? "" : `${lang}/`;
    window.storyblok.get(
      {
        slug: `${language}global-nav`,
        version: "draft"
      },
      data => {
        this.setState({ globalNav: data.story });
      }
    );
  }

  loadGlobalFooter(lang) {
    const language = lang === "default" ? "" : `${lang}/`;
    window.storyblok.get(
      {
        slug: `${language}footer`,
        version: "draft"
      },
      data => {
        this.setState({ footerNav: data.story });
      }
    );
  }

  initStoryblokEvents() {
    this.loadStory({ storyId: getParam("path") });

    const sb = window.storyblok;

    sb.on(["change", "published"], payload => {
      this.loadStory(payload);
    });

    sb.on("input", payload => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        sb.addComments(payload.story.content, payload.story.id);
        // https://www.storyblok.com/cl/resolve-relations-with-storyblok-bridge
        sb.resolveRelations(
          payload.story,
          sbConfig.options.resolveRelations,
          () => {
            // data.story.content has now the resolved relations
            // this.content = data.story.content
            this.setState({ story: payload.story });
          }
        );
      }
    });

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode();
      }
    });
  }

  render() {
    if (this.state.story == null) {
      return <div />;
    }

    const { content } = this.state.story;
    const contentType = this.state.story.content.component;
    const globalNav = this.state.globalNav.content;
    const footerNav = this.state.footerNav.content;
    const location = getParam("path");
    const locationObj = {
      pathname: location
    };
    let backgroundColor = "mono-000";
    if (
      location.indexOf("/work/") !== -1 ||
      location === "/home" ||
      location === "/"
    ) {
      backgroundColor = "neutral-black";
    }

    return (
      <SbEditable content={content}>
        {renderStaticNavigation(globalNav, footerNav, location, contentType)}
        <div>
          {templating(contentType, content, {})}
          <Footer
            blok={footerNav}
            componentName={contentType}
            location={location}
          />
        </div>
      </SbEditable>
    );
  }
}

export default StoryblokEntry;
