import React, { useContext, useState, useEffect } from "react";
import Lottie from "react-lottie-player";
import tw, { styled } from "twin.macro";
import Image from "@utility/Image";
import HorizontalLine from "@utility/HorizontalLine";
import clsxm from "@helpers/clsxm";
import FooterItem from "./FooterItem";
import { FooterItemType } from "./types";
import CookieBannerOpenContext from "../CookieBannerOpenContext";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1461%3A10
 */

type Props = {
  blok: {
    footer_items: FooterItemType[];
    footer_subscribe: string;
    lottie_width: string;
    lottie_height: string;
    lottie_glyph: any;
    lottie_glyph_dark: any;
    image: string;
    image_dark: string;
    text?: string;
  };
  componentName: string;
  location: string;
};

const FooterGlyphContainer = styled.div`
  ${tw`w-1/2 items-center self-end md:w-1/3`};
  max-width: 250px;
`;

const AgencyLocations = styled.p`
  ${tw`pb-3 type-1630-loose`}
  .is-dark & {
    ${tw`opacity-75`}
  }
`;

const FooterLinks = styled.ul`
  ${tw`flex flex-col md:flex-row`};
  :not(:last-child) {
    margin-right: 1.5rem;
  }
  .is-dark & {
    ${tw`opacity-75`}
  }
`;

const Footer: React.FC<Props> = ({
  blok,
  blok: {
    footer_items,
    image,
    image_dark,
    lottie_glyph,
    lottie_glyph_dark,
    lottie_width,
    lottie_height,
    text
  },
  componentName,
  location
}) => {
  const { cookieBannerOpen } = useContext(CookieBannerOpenContext);
  const isLandingPage = componentName === "landing";
  const footerClass =
    location === "/work/" || location?.indexOf("/agency") > -1
      ? `footer-dark is-dark-content`
      : "";
  const isDarkPage = location === "/work/" || location.indexOf("/agency") > -1;
  const [jsonData, updateJsonData] = useState();
  const targetfile = isDarkPage ? lottie_glyph_dark : lottie_glyph;
  const targetFileExists = targetfile && targetfile.filename;
  const LottieContent = () => {
    if (!targetFileExists) {
      return <p>test</p>;
    }
    const isJSON = targetfile.filename.indexOf(".json") > -1;
    if (!isJSON) {
      return <p>test</p>;
    }
    return (
      <Lottie
        play
        animationData={jsonData}
        style={{ width: lottie_width, height: lottie_height }}
      />
    );
  };

  useEffect(() => {
    let isSubscribed = true;

    const getLottieData = async () => {
      if (!targetFileExists) {
        return;
      }
      const isJSON = targetfile.filename.indexOf(".json") > -1;
      if (!isJSON) {
        return;
      }
      try {
        const resp = await fetch(targetfile.filename);
        const json = await resp.json();

        if (isSubscribed) {
          updateJsonData(json);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getLottieData();

    // cancel any future `updateJsonData`
    return () => {
      isSubscribed = false;
    };
  }, [targetfile, targetFileExists]);

  const imageGlyph = isDarkPage ? image_dark : image;

  return (
    <div className={footerClass}>
      <HorizontalLine />
      <div className="grid-wrapper">
        <div className="grid-row">
          <div className="col-12 lg:col-10 lg:col-offset-1">
            <div
              className={clsxm(
                "flex flex-wrap items-center justify-between w-full",
                isLandingPage && cookieBannerOpen
                  ? "pt-12 pb-36 md:pb-54"
                  : "py-12 md:pb-25"
              )}
            >
              <div className="flex flex-col justify-between w-full items-center md:flex-row">
                <div
                  className={clsxm(
                    "w-full mb-12 md:w-2/3 md:mb-0 md:block",
                    !text && "hidden"
                  )}
                >
                  <AgencyLocations>
                    New York City – Vancouver – Toronto
                  </AgencyLocations>
                  {!!text && (
                    <p className="text-neutral-black type-2030 md:type-2830">
                      {text}
                    </p>
                  )}
                  {!!footer_items?.length && (
                    <FooterLinks>
                      {footer_items &&
                        footer_items.map(footer_item =>
                          footer_item.component === "footer_item" ? (
                            <FooterItem
                              key={footer_item._uid}
                              blok={footer_item}
                            />
                          ) : (
                            ""
                          )
                        )}
                    </FooterLinks>
                  )}
                </div>
                <FooterGlyphContainer>
                  {targetFileExists && jsonData ? (
                    <LottieContent />
                  ) : (
                    <Image image={imageGlyph} blok={blok} />
                  )}
                </FooterGlyphContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
