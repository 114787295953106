import React, { useState } from "react";
import tw, { styled } from "twin.macro";

type Props = {
  name: string;
  message?: string;
  errorMessage?: string;
  onChange?: (
    e: React.FormEvent & {
      target: HTMLTextAreaElement | HTMLFormElement;
    }
  ) => void;
  cols: number;
  rows: number;
  required?: boolean;
  style?: { borderColor: string };
};

const InputContainer = styled.div`
  ${tw`flex flex-col relative`}
  &:focus-within label {
    transform: translate(0.25rem, 5px) scale(0.75);
  }
`;

const Input = styled.textarea`
  ${tw`p-5 border border-mono-300 mb-2 w-full text-mono-600 appearance-none outline-none`}
  border-radius: 3px;
  transition: border-color 0.5s ease-in-out;
  &:focus {
    ${tw`border-mono-500`}
  }
`;

const InputLabel = styled.label`
  ${tw`pointer-events-none px-5 text-mono-600 font-extralight absolute type-1630-loose`}
  transform: translate(0, 1.25rem) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  &.is-active {
    transform: translate(0.25rem, 5px) scale(0.75);
  }
`;

const FormTextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      name,
      message = "",
      errorMessage = "",
      onChange,
      cols,
      rows,
      required = false,
      style
    },
    ref
  ) => {
    const [isActive, setIsActive] = useState(false);

    const handleTextChange = text => {
      if (text !== "") {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };
    return (
      <InputContainer>
        <Input
          className="type-1630-loose"
          name={name}
          id={name}
          required={required}
          onChange={onChange}
          onBlur={e => handleTextChange(e.target.value)}
          cols={cols}
          rows={rows}
          ref={ref}
          style={style}
        />
        <InputLabel htmlFor={name} className={isActive ? "is-active" : ""}>
          {message}
        </InputLabel>
        {!!errorMessage && (
          <span className="text-error type-1230 -mt-2">{errorMessage}</span>
        )}
      </InputContainer>
    );
  }
);

// https://github.com/yannickcr/eslint-plugin-react/issues/2269
FormTextArea.displayName = "FormTextArea";

export default FormTextArea;
