import React, { ComponentType, ElementType } from "react";

type TagWrapperProps<P = {}> = {
  as?: ComponentType<P> | ElementType;
} & P;

function TagWrapper<P = {}>({
  as: Component = "div",
  ...props
}: TagWrapperProps<P>) {
  return <Component {...props} />;
}

export default TagWrapper;
