import React from "react";
import SbEditable from "storyblok-react";
import { LandingFeature } from "@src/types";
import ScrollInView from "@utility/ScrollInView";
import LandingDriver from "@components/LandingDriver";
import clsxm from "@helpers/clsxm";

/*
 * Visual Reference
 * https://www.figma.com/file/TWO2VuPSqYKYww3Ni83udq/Bike-Business-Landing-Page?node-id=440%3A413
 */

type Props = {
  blok: {
    title: string;
    body: LandingFeature[];
    bg_wash_desktop: boolean;
    bg_wash_mobile: boolean;
  };
};

const ListLandingFeatures: React.FC<Props> = ({
  blok,
  blok: { title, body, bg_wash_desktop, bg_wash_mobile }
}) => {
  return (
    <div
      className={clsxm(
        "w-full",
        bg_wash_mobile && "bg_wash-mobile",
        bg_wash_desktop && "bg_wash-desktop"
      )}
    >
      <SbEditable content={blok}>
        <div className="pt-25 md:pt-50">
          {!!title && (
            <ScrollInView>
              <div className="has-delay-container">
                <p className="pt-6 md:pt-0 type-1830">{title}</p>
              </div>
            </ScrollInView>
          )}
          <ScrollInView>
            <div className="has-delay-container landing-driver__listing">
              {body &&
                body.map(driver => (
                  <LandingDriver key={driver._uid} blok={driver} />
                ))}
            </div>
          </ScrollInView>
        </div>
      </SbEditable>
    </div>
  );
};

export default ListLandingFeatures;
