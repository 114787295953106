import React, { ReactNode } from "react";
import tw, { styled } from "twin.macro";
import { screen } from "@helpers/media";

type Props = {
  className?: string;
  children: ReactNode;
};

const AccordionTitleStyle = styled.div`
  ${tw`relative flex items-center justify-between`}
  min-height: 40px;
  @media ${screen.md} {
    min-height: none;
  }
`;

const AccordionTitle: React.FC<Props> = ({ className = "", children }) => {
  return (
    <AccordionTitleStyle className={className}>{children}</AccordionTitleStyle>
  );
};

export default AccordionTitle;
