import React from "react";
import SbEditable from "storyblok-react";
import Link from "@utility/Link";
import { addSlash } from "@src/common";
import { NavItemType } from "./types";

type Props = {
  blok: NavItemType;
};

const NavItem: React.FC<Props> = ({ blok, blok: { nav_link, nav_label } }) => (
  <SbEditable content={blok}>
    <li className="nav-link mb-4 lg:mb-1 relative list-none">
      <Link
        activeClassName="hover-link--active"
        type="hover"
        className="type-2830 lg:type-7220"
        to={`/${
          nav_link.cached_url === "home" ? "" : addSlash(nav_link.cached_url)
        }`}
      >
        {nav_label}
      </Link>
    </li>
  </SbEditable>
);

export default NavItem;
