import React from "react";
import SbEditable from "storyblok-react";
import tw, { styled } from "twin.macro";
import HelpPoint from "@utility/HelpPoint";
import Glyph3Up from "@utility/glyphs/GlyphThreeUp";
import ScrollInView from "@utility/ScrollInView";
import { screen } from "@helpers/media";
import { EngagementBlock } from "@src/types";

/*
 * Visual Reference
 * https://www.figma.com/file/TWO2VuPSqYKYww3Ni83udq/Bike-Business-Landing-Page?node-id=440%3A413
 */

type Props = {
  blok: {
    component: string;
    title: string;
    description: string;
    engagement_blocks: EngagementBlock[];
    label: string;
    show_glyph: boolean;
    _uid: string;
  };
};

const GlyphContainerStyle = styled.div`
  ${tw`absolute text-mono-100`}
  top: 1vw;
  right: 0;
  width: 36vw;
  z-index: 1;
  @media ${screen.lg} {
    width: 360px;
    top: -90px;
  }
  @media ${screen.xl} {
    width: 400px;
    top: 2vw;
  }
  .js-svg {
    fill: #444444;
  }
`;

const renderGlyph = bool => {
  if (bool) {
    return (
      <GlyphContainerStyle>
        <Glyph3Up />
      </GlyphContainerStyle>
    );
  }
};

const HowWeCanHelp: React.FC<Props> = ({
  blok,
  blok: { engagement_blocks, title = "Benefits", description, show_glyph }
}) => {
  return (
    <div className="w-full">
      <SbEditable content={blok}>
        <div className="py-25 md:py-50">
          <div className="grid-wrapper">
            <div className="grid-row">
              <div className="col-12 md:col-8 md:col-offset-1 lg:col-6">
                <ScrollInView>
                  <div className="has-delay-container">
                    {!!title && (
                      <h3 className="has-delay mb-8 type-serif-4260 lg:type-serif-6460 text-neutral-black">
                        {title}
                      </h3>
                    )}
                    {!!description && (
                      <p className="mb-0 type-2030 md:type-2240 text-neutral-black has-delay">
                        {description}
                      </p>
                    )}
                  </div>
                </ScrollInView>
              </div>
            </div>
          </div>
          <div className="grid-wrapper">
            <div className="grid-row">
              <div className="col-12 md:col-offset-1 md:col-10">
                <ScrollInView>
                  <div className="has-delay-container">
                    <div className="flex flex-wrap flex-col md:flex-row md:-mx-5 lg:-mx-10 text-neutral-black">
                      {engagement_blocks &&
                        engagement_blocks.map((engagement_block, index) =>
                          React.createElement(HelpPoint, {
                            key: engagement_block._uid,
                            blok: engagement_block,
                            largeText: true,
                            isLastBlock: index === engagement_blocks.length - 1
                          })
                        )}
                    </div>
                  </div>
                </ScrollInView>
              </div>
            </div>
          </div>
          {renderGlyph(show_glyph)}
        </div>
      </SbEditable>
    </div>
  );
};

export default HowWeCanHelp;
