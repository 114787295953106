import React, { useState, useEffect } from "react";
import Lottie from "react-lottie-player";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import HeroInnerContainer from "@utility/HeroInnerContainer";
import tw, { styled, css } from "twin.macro";
import useWindowDimensions from "@utility/useWindowDimensions";

const GlyphGridPosition = styled.div`
  ${tw`absolute w-full`}
  ${props => props.yPos}
  &.fade-enter-active,
  &.fade-appear-active {
    ${tw`opacity-100 duration-1000 transition-opacity`}
  }
  &.fade-appear-done,
  &.fade-enter-done,
  &.fade-exit {
    ${tw`opacity-100`}
  }
  &.fade-exit-active {
    ${tw`opacity-0 transition-opacity duration-1000`}
  }
`;

const GlyphPositionX = styled.div`
  ${props => props.xPos}
  ${tw`top-0 absolute`}
  ${props => props.yTransform}
`;

const StyledLottie = styled(Lottie)`
  svg {
    ${tw`inline align-baseline`}
  }
`;

const GlyphPosition = styled.div`
  ${tw`hidden absolute lg:block`}

  &:first-of-type {
    ${tw`block`}
  }

  &.fade-enter-active,
  &.fade-appear-active{
    ${tw`opacity-100 duration-1000 transition-opacity`}
  }
  &.fade-appear-done,
  &.fade-enter-done,
  &.fade-exit {
    ${tw`opacity-100`}
  }
  &.fade-exit-active {
    ${tw`opacity-0 transition-opacity duration-1000`}
  }
  ${props => props.yPos}
  ${props => props.xPos}
  ${props => props.xyTransform}
  ${props =>
    props.isHomepage
      ? css`
          display: block;
        `
      : ""}
`;

const ImageContainer = styled.div(({ width, opacity }) => [
  css`
  ${tw`overflow-hidden`}
  width: ${width}px;
  opacity: ${(parseInt(opacity) / 100).toFixed(2)};
`
]);

const GlyphLottie = ({
  blok,
  blok: {
    y_position,
    x_position,
    fade_in_delay,
    lottie_file,
    loop,
    width,
    height,
    y_offset,
    x_offset,
    viewport_display,
    pixel_offsets,
    opacity
  },
  isHomepage
}) => {
  let xyTransform = `transform: translate(${
    x_position && x_position === "2" ? "-50%" : "0"
  },${y_position && y_position === "2" ? "-50%" : "0"});`;
  if ((y_offset && y_offset !== "0") || (x_offset && x_offset !== "0")) {
    xyTransform = `transform: translate(${x_offset || "0"}${
      pixel_offsets ? "px" : "%"
    },${y_offset || "0"}${pixel_offsets ? "px" : "%"});`;
  }

  const ySwitch = {
    "1": "top: 0;",
    "2": "top: 50%;",
    "3": "bottom: 0;"
  };
  const xSwitch = {
    "1": "left: 0;",
    "2": "left: 50%;",
    "3": "right: 0;",
    "4": "left: 0",
    "5": "right: 0;"
  };
  const ySwitchTransform = (y_offset = 0, pixel_offsets) => {
    const unit = pixel_offsets ? "px" : "%";
    return {
      "1": `transform: translateY(calc(0 + ${y_offset}${unit}));`,
      "2": `transform: translateY(calc(-50% + ${y_offset}${unit}));`,
      "3": `transform: translateY(calc(-100% + ${y_offset}${unit}));`
    };
  };
  const yPos = ySwitch[y_position || "2"];
  const xPos = xSwitch[x_position || "1"];
  const yTransform = ySwitchTransform(y_offset, pixel_offsets)[
    y_position || "1"
  ];

  const [jsonData, updateJsonData] = useState();
  useEffect(() => {
    let isSubscribed = true;
    const getLottieData = async () => {
      const isJSON = lottie_file.filename.indexOf(".json") > -1;
      if (!isJSON) {
        return;
      }
      const resp = await fetch(lottie_file.filename).catch(() => {});
      if (!resp) {
        return;
      }
      const json = await resp.json();

      if (isSubscribed) {
        updateJsonData(json);
      }
    };
    getLottieData();

    // cancel any future `updateJsonData`
    return () => {
      isSubscribed = false;
    };
  }, []);

  const [play, updatePlay] = useState(false);
  const { windowWidth } = useWindowDimensions();
  useEffect(() => {
    if (windowWidth) {
      if (windowWidth >= 768) {
        const timer = setTimeout(() => {
          updatePlay(true);
        }, fade_in_delay);
        return () => clearTimeout(timer);
      }
      updatePlay(true);
    }
  }, [windowWidth]);
  if (x_position <= 3) {
    return (
      <SbEditable content={blok}>
        <GlyphPosition
          yPos={yPos}
          xPos={xPos}
          xyTransform={xyTransform}
          isHomepage={isHomepage}
        >
          <ImageContainer
            className={viewport_display}
            width={width}
            opacity={opacity}
          >
            {jsonData ? (
              <StyledLottie
                loop={loop}
                play={play}
                animationData={jsonData}
                style={{ width, height }}
              />
            ) : (
              <p>&nbsp;</p>
            )}
          </ImageContainer>
        </GlyphPosition>
      </SbEditable>
    );
  }
  return (
    <GlyphGridPosition yPos={yPos} isHomepage={isHomepage}>
      <div className={`grid-wrapper ${viewport_display}`}>
        <div className="grid-row">
          <div className="col-12">
            <HeroInnerContainer>
              <div tw="relative">
                <SbEditable content={blok}>
                  <GlyphPositionX
                    yPos={yPos}
                    xPos={xPos}
                    yTransform={yTransform}
                  >
                    <ImageContainer width={width} opacity={opacity}>
                      {jsonData ? (
                        <StyledLottie
                          loop={loop}
                          play={play}
                          animationData={jsonData}
                          style={{ width, height }}
                        />
                      ) : (
                        <p>&nbsp;</p>
                      )}
                    </ImageContainer>
                  </GlyphPositionX>
                </SbEditable>
              </div>
            </HeroInnerContainer>
          </div>
        </div>
      </div>
    </GlyphGridPosition>
  );
};

GlyphLottie.propTypes = {
  blok: PropTypes.shape({
    _uid: PropTypes.string,
    y_position: PropTypes.string,
    x_position: PropTypes.string,
    fade_in_delay: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    y_offset: PropTypes.string,
    x_offset: PropTypes.string,
    lottie_file: PropTypes.any,
    loop: PropTypes.bool,
    viewport_display: PropTypes.string,
    pixel_offsets: PropTypes.bool,
    opacity: PropTypes.string
  }),
  isHomepage: PropTypes.bool
};

GlyphLottie.defaultProps = {
  blok: {
    y_offset: "0",
    x_offset: "0",
    opacity: "1.0",
    width: "200",
    height: "200",
    viewport_display: "block",
    pixel_offsets: false,
    loop: false,
    fade_in_delay: 0
  },
  isHomepage: false
};

export default GlyphLottie;
