import React from "react";
import { generateDynamicViewport } from "@helpers/generateDynamicClasses";
import SbEditable from "storyblok-react";
import { WorkConfigType } from "@src/types";
import tw, { css } from "twin.macro";
import clsxm from "@helpers/clsxm";
import { screen } from "@helpers/media";

// The fields for work_config are coming from a custom storyblok plugin
// that can be managed here: https://app.storyblok.com/#!/partner/fields
// Every flexible page work component should be wrapped in this container
// and use the work_config obj to add customizable spacing between components

type Props = {
  children: React.ReactNode;
  blok: {
    work_config: WorkConfigType;
    _uid: string;
    component: string;
  };
  inline?: boolean;
};

const LayoutSection: React.FC<Props> = ({
  children,
  blok,
  blok: {
    not_work_page,
    work_config: {
      bg_wash_mobile,
      bg_wash_desktop,
      viewport_display,
      padding_top_mobile,
      padding_top_desktop,
      padding_bottom_mobile,
      padding_bottom_desktop
    }
  },
  inline = true
}) => {
  return (
    <SbEditable content={blok}>
      <div
        className={clsxm(
          not_work_page && "grid-wrapper",
          !inline && !bg_wash_mobile && "-mx-5 lg:mx-0"
        )}
      >
        <section
          css={[
            tw`grid-overflow`,
            bg_wash_desktop && tw`lg:bg-mono-100`,
            bg_wash_mobile && tw`lg-down:bg-mono-100`,
            generateDynamicViewport(viewport_display),
            css`
              padding-top: ${padding_top_mobile}px;
              padding-bottom: ${padding_bottom_mobile}px;
              @media ${screen.lg} {
                padding-top: ${padding_top_desktop}px;
                padding-bottom: ${padding_bottom_desktop}px;
              }
            `
          ]}
        >
          <div className={clsxm(inline && "grid-overflow__clear")}>
            <div className="grid-row">{children}</div>
          </div>
        </section>
      </div>
    </SbEditable>
  );
};

export default LayoutSection;
