import Cookie from "js-cookie";

export const acceptCookies = (): void => {
  Cookie.set("opt-in", "true");
};

export const declineCookies = (): void => {
  Cookie.set("opt-in", "false");
};

export const getCookieConsent = (): string | undefined => {
  return Cookie.get("opt-in");
};

export const getCookie = (cookieName: string): string => {
  return Cookie.get(cookieName);
};

export const setCookie = (cookieName: string, cookieValue: string): void => {
  if (getCookieConsent() === "true") {
    Cookie.set(cookieName, cookieValue);
  }
};
