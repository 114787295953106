import React from "react";
import { DynamicGlyphType } from "@src/types";
import VerticalListing from "./VerticalListing";
import VerticalListingAccordion from "./VerticalListingAccordion";
import { VerticalBlock } from "./types";

type Props = {
  blok: {
    label?: string;
    description: any;
    vertical_blocks: VerticalBlock[];
    component: string;
    _uid: string;
    glyphs: DynamicGlyphType[];
    list_type: "default" | "accordion";
  };
};

const Listing: React.FC<Props> = ({ blok, blok: { list_type } }) => {
  if (list_type === "accordion") {
    return <VerticalListingAccordion blok={blok} />;
  }

  return <VerticalListing blok={blok} />;
};

export default Listing;
