import React from "react";
import SbEditable from "storyblok-react";
import ScrollInView from "@utility/ScrollInView";
import Link from "@utility/Link";
import AnimateHeroText from "@utility/AnimateHeroText";
import TextArea from "@utility/TextArea";
import { LinkType } from "@src/types";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { addSlash } from "@src/common";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "@helpers/getGatsbyImageData";

/*
 * Visual Reference
 * https://www.figma.com/file/zODzhJxGWO9syPmmnPEb7z/ED-Website-2022%2F23?node-id=3365%3A30741&t=W5EE54llpd0XpHho-0
 */

type Props = {
  blok: {
    image: string;
    title: string;
    cta_label: string;
    link: LinkType;
    component: string;
    _editable: string;
    _uid: string;
  };
};

const LandingHero: React.FC<Props> = ({
  blok,
  blok: { image, title, cta_label, link }
}) => {
  const imageRatiosWidth = 580;
  const imageRatiosHeight = 705;
  const imageSizes = "(max-width: 1440px) calc(100vw)";
  const imageData = getGatsbyImageData({
    image,
    maxWidth: imageRatiosWidth,
    maxHeight: imageRatiosHeight,
    options: { quality: 80 }
  });

  const cachedUrl =
    link?.url !== "" ? link?.cached_url : `/${link?.cached_url}`;

  return (
    <SbEditable content={blok}>
      <div className="bg-white h-full w-full pb-24">
        <div className="bg-neutral-black h-full w-full">
          <div className="grid-wrapper px-0 lg:px-2.5">
            <div className="px-4 grid grid-cols-6 gap-x-2.5 gap-y-12 pt-24 md:grid-cols-12 md:gap-x-5">
              <div className="col-span-full md:col-span-6 md:self-end md:pb-12 lg:col-span-7 xl:col-span-5 xl:col-start-2">
                <AnimateHeroText>
                  <h1 className="text-white type-serif-4260 mb-12 lg:type-serif-5660 lg:mb-10">
                    <TextArea text={title} nestedAnimation />
                  </h1>
                </AnimateHeroText>
                <ScrollInView delaySelf="0.2" className="relative z-40">
                  <div className="has-delay">
                    {link.id ? (
                      <Link
                        to={addSlash(cachedUrl)}
                        type="pill"
                        className="pointer-events-auto js-gtm"
                        data-category="Header"
                        data-action="Hero CTA click"
                        data-label="Hero CTA"
                      >
                        {cta_label}
                      </Link>
                    ) : (
                      <AnchorLink
                        className="pill-link pill-link--light pointer-events-auto js-gtm"
                        offset="100"
                        href="#contact"
                        data-category="Header"
                        data-action="Hero CTA click"
                        data-label="Contact Form"
                      >
                        {cta_label}
                      </AnchorLink>
                    )}
                  </div>
                </ScrollInView>
              </div>
              <div className="col-span-full sm:mx-auto md:col-span-5 md:col-start-8 xl:col-start-7 -mb-24 aspect-[580/705] w-full md-down:max-h-[705px]">
                <ScrollInView key={image} className="h-full w-full">
                  <SbEditable content={blok}>
                    <GatsbyImage
                      image={imageData}
                      loading="eager"
                      sizes={imageSizes}
                      alt=""
                      role="presentation"
                      className="h-full w-full"
                    />
                  </SbEditable>
                </ScrollInView>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  );
};

export default LandingHero;
