/**
 * Lock the body using fixed positioning
 * This is needed for IOS <= 13.0 which
 * the body-scroll-lock package does not account for
 *
 */
let scrollPosition = 0;
const bodyScroll = {
  lock() {
    if (typeof window !== "undefined" || !window.document) {
      scrollPosition = window.pageYOffset;
      document.body.classList.add("is-fixed-body");
      document.body.style.top = `-${scrollPosition}px`;
    }
  },
  unlock() {
    if (typeof window !== "undefined" || !window.document) {
      document.body.classList.remove("is-fixed-body");
      document.body.style.top = "";
      window.scrollTo(0, scrollPosition);
      scrollPosition = 0;
    }
  }
};
export default bodyScroll;
