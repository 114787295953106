import React from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";
import PropTypes from "prop-types";
import { screen } from "@helpers/media";
import { NAV_HEIGHT_MOBILE, NAV_HEIGHT_DESKTOP } from "@src/common";

// TODO: apply hero animation text transition: .hero-animation-text-1
const HeaderContainerStyle = styled.div`
  ${tw`relative flex items-center`}
	${props =>
    props.componentName !== "work_post" &&
    css`
      min-height: calc(470px - ${NAV_HEIGHT_MOBILE});
    `}
	@media ${screen.lg} {
		min-height:calc(600px - ${NAV_HEIGHT_DESKTOP});
    ${props =>
      props.tall &&
      css`
        height: calc(100vh - ${NAV_HEIGHT_DESKTOP} - 50px);
        max-height: calc(840px - ${NAV_HEIGHT_DESKTOP});
      `}
	}
  .is-dark & {
    ${tw`bg-neutral-black`}
  }
`;

const HeaderPaddingContainer = styled.div`
  ${tw`w-full`}
  ${props =>
    props.componentName === "work_post"
      ? tw`pt-10 pb-16`
      : `${tw`py-16`}
          ${css`
            margin-top: calc(-${NAV_HEIGHT_MOBILE} / 2);
          `}
  `}
  @media ${screen.md} {
    ${props =>
      props.componentName === "work_post" ? tw`pt-20 pb-32` : tw`py-20`}
  }
  @media ${screen.lg} {
    ${props =>
      props.componentName === "work_post"
        ? tw`pt-40 pb-50`
        : `${tw`py-40`} ${css`
            margin-top: calc(-${NAV_HEIGHT_DESKTOP} / 2);
          `}
    `}

  }
`;

const HeaderContainer = ({ children, className, tall, componentName }) => (
  <HeaderContainerStyle
    className={`header-container ${className}`}
    tall={tall}
    componentName={componentName}
  >
    <HeaderPaddingContainer componentName={componentName}>
      {children}
    </HeaderPaddingContainer>
  </HeaderContainerStyle>
);

HeaderContainer.defaultProps = {
  className: "",
  tall: false
};

HeaderContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  tall: PropTypes.bool
};

export default HeaderContainer;
