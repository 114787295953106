import React from "react";
import SbEditable from "storyblok-react";
import tw, { styled, css } from "twin.macro";
import WorkDriver from "@components/WorkDriver";
import Label from "@utility/Label";
import ScrollInView from "@utility/ScrollInView";
import { screen } from "@helpers/media";
import { FeaturedWork } from "@src/types";
import clsxm from "@helpers/clsxm";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1421%3A6183
 */

type Props = {
  blok: {
    title?: string;
    description?: string;
    type: "work" | "list" | "perspectives";
    size: "default" | "large";
    works: FeaturedWork[];
    bg_wash_desktop: boolean;
    bg_wash_mobile: boolean;
    component: string;
    _uid: string;
  };
};

const BackgroundWash = styled.div`
  .card-scroll {
    & > *:first-child {
      ${tw`pt-0`}
    }
    & > *:last-child {
      @media ${screen.lg} {
        ${tw`pb-24`}
      }
      @media ${screen.lg} {
        ${tw`pb-50`}
      }
    }
  }
  @media (max-width: 767px) {
    .card-scroll {
      ${tw`flex flex-col pt-10`}
      &__container-inner {
        ${tw`flex-col-reverse`}
      }
    }
  }
`;

const FeatureWorkDriverListing: React.FC<Props> = ({
  blok,
  blok: {
    works,
    title = "Our Work",
    description,
    size,
    type,
    bg_wash_desktop,
    bg_wash_mobile
  }
}) => {
  const firstIsReverse = () => {
    if (works && works[0] && works[0].reverse_orientation) {
      return works[0].reverse_orientation;
    }
    return false;
  };
  return (
    <BackgroundWash
      className={clsxm(
        "w-full",
        bg_wash_mobile ? "bg_wash-mobile md-down:py-16" : "md-down:my-16",
        bg_wash_desktop ? "bg_wash-desktop md:py-32" : "md:my-32"
      )}
    >
      <SbEditable content={blok}>
        <div>
          <div className="grid-wrapper">
            <div className="grid-row">
              <div
                className={clsxm(
                  "col-10 md:col-5 md:mr-auto",
                  firstIsReverse
                    ? "md:col-offset-1"
                    : "md:col-offset-5 xl:col-offset-4"
                )}
              >
                <ScrollInView>
                  <div className="has-delay-container">
                    {!!title && (
                      <Label
                        className={clsxm(
                          "has-delay md:mb-8 lg:mb-20",
                          !!description && "md:mb-20"
                        )}
                      >
                        {title}
                      </Label>
                    )}
                    {!!description && (
                      <h3 className="type-2030 mb-12 md:mb-40 md:type-2230 lg:type-2430 has-delay">
                        {description}
                      </h3>
                    )}
                  </div>
                </ScrollInView>
              </div>
            </div>
          </div>
          <div
            css={css`
              ${tw`md-down:(flex flex-col)`}
              & > *:first-child {
                ${tw`pt-0`}
              }
              & > *:last-child {
                ${tw`lg:pb-0`}
              }
              .card-scroll__container-inner {
                ${tw`md-down:flex-col-reverse`}
              }
            `}
          >
            {works &&
              works.map(work => (
                <WorkDriver
                  key={work._uid}
                  blok={work}
                  type={type}
                  size={size}
                />
              ))}
          </div>
        </div>
      </SbEditable>
    </BackgroundWash>
  );
};

export default FeatureWorkDriverListing;
