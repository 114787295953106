import React from "react";
import SbEditable from "storyblok-react";
import Link from "@utility/Link";
import { LinkType } from "@src/types";

/*
 * Visual Reference
 * https://www.figma.com/file/TWO2VuPSqYKYww3Ni83udq/Bike-Business-Landing-Page?node-id=440%3A413
 */

type Props = {
  blok: {
    title: string;
    body: string;
    cta_label: string;
    cta_link: LinkType;
  };
};

const MissionStatement: React.FC<Props> = ({
  blok,
  blok: { title, body, cta_label, cta_link }
}) => {
  const logo = "/logo.svg";
  return (
    <div className="mission-statement">
      <SbEditable content={blok}>
        <div className="py-25 md:py-50">
          <div className="grid-wrapper">
            <div className="grid-row">
              <div className="col-12 md:col-5 md:col-offset-1">
                {!!title && (
                  <h3 className="type-serif-4260 md:type-serif-6460 mb-8 text-neutral-black">
                    {title}
                  </h3>
                )}

                <div className="has-delay">
                  {!!body && (
                    <p className="pt-16 md:pt-0 type-2030 md:type-2240 pb-8">
                      {body}
                    </p>
                  )}
                </div>

                {cta_link?.url && <Link to={cta_link.url}>{cta_label}</Link>}
              </div>

              <div className="col-12 md:col-5">
                <img
                  className="md:float-right"
                  src={logo}
                  alt="Engine Digital"
                />
              </div>
            </div>
          </div>
        </div>
      </SbEditable>
    </div>
  );
};

export default MissionStatement;
