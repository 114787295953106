import React from "react";
import SbEditable from "storyblok-react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import CardPerspective from "@utility/CardPerspective";
import Label from "@utility/Label";
import ScrollInView from "@utility/ScrollInView";
import clsxm from "@helpers/clsxm";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1386%3A2
 */

const RecentPerspectivesListing = ({
  blok,
  blok: { label, title, bg_wash_desktop, bg_wash_mobile },
  current_post
}) => {
  // Use useStaticQuery is required here because this is technically a component within
  // the page storyblokEntry
  const data = useStaticQuery(
    graphql`
      query {
        allStoryblokEntry(
          sort: { field_date_string: DESC }
          filter: {
            field_component: { eq: "perspective_post" }
            published_at: { ne: null }
          }
          limit: 4
        ) {
          edges {
            node {
              full_slug
              content
            }
          }
        }
      }
    `
  );
  const exclusion = current_post || "";
  const perspectivesListings = data.allStoryblokEntry.edges
    .filter(post => post.node.full_slug !== exclusion)
    .slice(0, 3);
  return (
    <div
      className={clsxm(
        bg_wash_mobile && "bg_wash-mobile",
        bg_wash_desktop && "bg_wash-desktop"
      )}
    >
      <SbEditable content={blok}>
        <div className="grid-wrapper">
          <div className="pb-12 pt-24 sm:pb-24">
            {!!label && (
              <div className="grid-row">
                <div className="col-10 xl:col-offset-1 lg:col-6">
                  <ScrollInView>
                    <Label className="mb-10 md:mb-12">{label}</Label>
                  </ScrollInView>
                </div>
              </div>
            )}
            {!!title && (
              <div className="grid-row">
                <div className="col-10 xl:col-offset-1 xl:col-6">
                  <ScrollInView>
                    <h3 className="type-serif-4260 md:type-serif-6460 mb-8 text-neutral-black">
                      {title}
                    </h3>
                  </ScrollInView>
                </div>
              </div>
            )}
            <div className="grid-row">
              <div className="col-12 xl:col-offset-1 xl:col-10">
                <ScrollInView delayChildren="0.5">
                  <div className="has-delay-container flex flex-col -mb-3 sm:flex-row sm:flex-wrap sm:-mx-6 sm:-mb-16">
                    {perspectivesListings.map(
                      ({ node, node: { content } }, index) => {
                        const contentObj = JSON.parse(content);
                        return (
                          <CardPerspective
                            key={`storyblok-link${index}`}
                            author={contentObj.author.name}
                            date={contentObj.date}
                            category={contentObj.categories}
                            title={contentObj.title}
                            url={`/${node.full_slug}`}
                            image={
                              contentObj.thumbnail_image
                                ? contentObj.thumbnail_image
                                : contentObj.hero_image
                            }
                            className="has-delay"
                          />
                        );
                      }
                    )}
                  </div>
                </ScrollInView>
              </div>
            </div>
          </div>
        </div>
      </SbEditable>
    </div>
  );
};

RecentPerspectivesListing.propTypes = {
  blok: PropTypes.shape({
    label: PropTypes.string,
    title: PropTypes.string,
    bg_wash_desktop: PropTypes.bool,
    bg_wash_mobile: PropTypes.bool
  }).isRequired,
  current_post: PropTypes.string
};

RecentPerspectivesListing.defaultProps = {
  current_post: ""
};

export default RecentPerspectivesListing;
