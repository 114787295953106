import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import SwiperCustom from "react-id-swiper/lib/ReactIdSwiper.custom";
import { Autoplay, Swiper } from "swiper";
import styled, { css } from "styled-components";
import tw from "twin.macro";
import clsxm from "@helpers/clsxm";
import CarouselNavigation from "@utility/CarouselControls";

const CarouselControls = styled.div`
  ${tw`absolute bottom[-3.4rem] right-0 transform md:(static left-2/4 translate-x-0 bottom[2.75rem])`}
  ${props =>
    props.desktopOnly &&
    css`
      ${tw`hidden md:block`}
    `}
    ${props =>
      !props.hasCaption &&
      css`
        ${tw`bottom[-1.5rem]`}
      `}
`;

const CarouselReveal = ({
  images,
  children,
  duration,
  carouselHideControls,
  desktopOnly,
  hasCaption,
  bgWashMobile
}) => {
  const swiperRef = useRef(null);
  const [size, setSize] = useState(0);
  const params = {
    Swiper,
    modules: [Autoplay],
    slidesPerView: 1,
    watchSlidesProgress: true,
    lazy: false,
    keyboard: {
      enabled: true
    },
    speed: 1000,
    autoplay: {
      delay: duration,
      disableOnInteraction: false
    },
    loop: true,
    simulateTouch: false
  };
  useEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  // Add eventlisteners for swiper after initializing
  useEffect(() => {
    if (
      swiperRef.current &&
      swiperRef.current.swiper &&
      typeof window !== "undefined"
    ) {
      const { swiper } = swiperRef.current;
      swiper.updateSize();
      // prevent blank initial slide on looping
      swiper.on("slideChangeTransitionStart", () => {
        swiper.slideToLoop(swiper?.realIndex, 1000, false);
      });
      if (matchMedia("(min-width: 1024px)").matches) {
        swiper.on("progress", () => {
          const interleaveOffset = 0.5;
          for (let i = 0; i < swiper.slides.length; i += 1) {
            const slideProgress = swiper.slides[i].progress;
            const innerOffset = swiper.width * interleaveOffset;
            const innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(
              ".js-swiper-slide-inner"
            ).style.transform = `translate3d(${innerTranslate}px, 0, 0)`;
          }
        });
        swiper.on("transitionEnd", () => {
          for (let i = 0; i < swiper.slides.length; i += 1) {
            swiper.slides[i].style.transition = "";
            swiper.slides[i].querySelector(
              ".js-swiper-slide-inner"
            ).style.transition = "";
          }
        });
        swiper.on("setTransition", () => {
          for (let i = 0; i < swiper.slides.length; i += 1) {
            swiper.slides[
              i
            ].style.transition = `${swiper.originalParams.speed}ms`;
            swiper.slides[i].querySelector(
              ".js-swiper-slide-inner"
            ).style.transition = `${swiper.originalParams.speed}ms`;
          }
        });
      }
    }

    return () => {
      if (swiperRef.current && swiperRef.current.swiper) {
        const { swiper } = swiperRef.current;
        swiper.off("progress");
        swiper.off("transitionEnd");
        swiper.off("setTransition");
        swiper.off("slideChangeTransitionStart");
      }
    };
  }, [swiperRef?.current?.swiper]);

  const childrenWithProps = image =>
    React.Children.map(children, child => {
      return React.cloneElement(child, {
        image: image.image
      });
    });
  return (
    <>
      <SwiperCustom
        {...params}
        containerClass={`swiper-container ${
          desktopOnly ? "hidden md:block" : ""
        }`}
        ref={swiperRef}
        key={size}
      >
        {images &&
          images.map(el => (
            <div key={el._uid}>
              <div
                className={clsxm(
                  "js-swiper-slide-inner",
                  bgWashMobile && "grid-overflow__clear"
                )}
              >
                {childrenWithProps(el)}
              </div>
            </div>
          ))}
      </SwiperCustom>
      {!carouselHideControls && (
        <CarouselControls
          className={clsxm(
            images.length > 1 && "md:col-offset-10",
            bgWashMobile && "mx-[15px]"
          )}
          desktopOnly={desktopOnly}
          hasCaption={hasCaption}
        >
          <CarouselNavigation swiperRef={swiperRef} duration={duration} />
        </CarouselControls>
      )}
    </>
  );
};

CarouselReveal.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.node.isRequired,
  duration: PropTypes.string,
  carouselHideControls: PropTypes.bool,
  desktopOnly: PropTypes.bool,
  hasCaption: PropTypes.bool
};

CarouselReveal.defaultProps = {
  duration: "3000",
  desktopOnly: false,
  carouselHideControls: false,
  hasCaption: false
};

export default CarouselReveal;
