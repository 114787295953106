import React, { ReactNode } from "react";
import { keyframes, CSSProp } from "styled-components";
import tw, { styled, css } from "twin.macro";
import { useInView } from "react-intersection-observer";

type Props = {
  children: ReactNode;
  twStyles?: CSSProp;
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const AnimatedContainer = styled.div<{ twStyles: CSSProp }>(({ twStyles }) => [
  css`
    &.has-keyframe-animation .fade-in {
      animation: 1.25s ${fadeIn} cubic-bezier(0, 0, 0.1, 0.97) forwards;
      &__1 {
        animation-delay: 1s;
      }
      &__2 {
        animation-delay: 1.3s;
      }
      &__3 {
        animation-delay: 1.5s;
      }
      &__4 {
        animation-delay: 2s;
      }
      &__5 {
        animation-delay: 2.5s;
      }
      &__6 {
        animation-delay: 3s;
      }
    }
    .fade-in {
      ${tw`opacity-0`}
      transform: translate3d(0, 32px, 0);
    }
  `,
  css`
    ${twStyles}
  `
]);

const AnimateKeyframes: React.FC<Props> = ({ children, twStyles = `` }) => {
  const [ref, inView] = useInView({
    triggerOnce: true
  });
  return (
    <AnimatedContainer
      ref={ref}
      className={`${inView ? "has-keyframe-animation" : ""}`}
      twStyles={twStyles}
    >
      {children}
    </AnimatedContainer>
  );
};

export default AnimateKeyframes;
