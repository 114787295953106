import React from "react";
import tw, { styled } from "twin.macro";

type Props = {
  name: string;
  message?: string;
  onChange?: (
    e: React.FormEvent & {
      target: HTMLInputElement | HTMLFormElement;
    }
  ) => void;
  value?: boolean;
  className?: string;
  required?: boolean;
};

const Label = styled.label`
  ${tw`inline-block relative pl-4 cursor-pointer`}
  &:before {
    content: "";
    ${tw`absolute top-0 w-5 h-5 border border-mono-300 rounded-full`}
    left: -20px;
  }
  &:after {
    content: "";
    ${tw`absolute bg-mono-600 rounded-full opacity-0`}
    top: 5px;
    left: -15px;
    width: 10px;
    height: 10px;
    transition: opacity 0.5s ease-in-out;
  }
`;
const Checkbox = styled.input`
  ${tw`opacity-0 cursor-pointer`}
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  &:checked + ${Label}:after {
    content: "";
    ${tw`opacity-100`}
  }
`;

const FormCheckbox = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      message = "",
      onChange,
      value = false,
      className = "",
      required = false
    },
    ref
  ) => {
    return (
      <div className={className}>
        <Checkbox
          type="checkbox"
          name={name}
          id={name}
          value={value.toString()}
          required={required}
          onChange={onChange}
          ref={ref}
        />
        <Label htmlFor={name} css={tw`type-1630-loose`}>
          {message}
        </Label>
      </div>
    );
  }
);

// https://github.com/yannickcr/eslint-plugin-react/issues/2269
FormCheckbox.displayName = "FormCheckbox";

export default FormCheckbox;
