import React from "react";
import tw, { css } from "twin.macro";
import { motion } from "framer-motion";
import revealLight from "@src/lottie/reveal-light.json";
import useWindowDimensions from "@utility/useWindowDimensions";
import Lottie from "react-lottie-player";
import { REVEAL_DURATION } from "@src/common";

const exitVariants = {
  initial: {
    height: 0,
    bottom: 0
  },

  exit: {
    height: "100%",
    bottom: 0,
    transition: {
      duration: REVEAL_DURATION,
      ease: [0.87, 0, 0.13, 1]
    }
  }
};

const enterVariants = {
  initial: {
    height: "100%",
    top: 0
  },
  animate: {
    height: 0,
    top: 0,
    transition: {
      duration: REVEAL_DURATION,
      ease: [0.87, 0, 0.13, 1]
    }
  }
};
type Props = {
  variants: {
    initial: {};
    exit?: {};
    animate?: {};
  };
  exit?: string;
  animate?: string;
};
const RevealContainer: React.FC<Props> = ({ variants, exit, animate }) => {
  const { windowHeight } = useWindowDimensions();

  return (
    windowHeight && (
      <motion.div
        tw="w-full fixed items-center flex flex-col text-center justify-center overflow-hidden pointer-events-none bg-mono-100 z-index[200]"
        initial="initial"
        variants={variants}
        exit={exit}
        animate={animate}
      >
        <div
          css={[
            tw`absolute transform[translateY(-50%)] top[50vh]`,
            css`
              top: ${windowHeight / 2}px;
            `
          ]}
        >
          <Lottie
            loop
            animationData={revealLight}
            play
            style={{ width: 160, height: 40 }}
          />
        </div>
      </motion.div>
    )
  );
};

const PageReveal = () => (
  <>
    <RevealContainer variants={exitVariants} exit="exit" />
    <RevealContainer variants={enterVariants} animate="animate" />
  </>
);

export default PageReveal;
