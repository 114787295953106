import React from "react";
import tw, { styled, TwStyle } from "twin.macro";
import Link from "@utility/Link";
import { addSlash } from "@src/common";

type Props = {
  button: boolean;
  type: "button" | "submit" | "reset";
  onClick?: () => void;
  label?: string;
  to?: string;
  id?: string;
  twStyles?: TwStyle;
};

const ButtonStyle = styled("button")`
  ${tw`rounded-sm font-extralight type-1630-loose border border-solid border-mono-400 px-12 py-5 text-neutral-black`};
  height: 60px;
  will-change: background;
  transition: background 0.3s ease-in-out;
  &:hover,
  &:focus {
    ${tw`bg-neutral-black text-white`}
  }
`;

const Button: React.FC<Props> = ({
  button = false,
  type,
  label,
  to = "",
  id = "",
  twStyles,
  onClick
}) => {
  if (button) {
    return (
      <ButtonStyle onClick={onClick} type={type} css={twStyles} id={id || ""}>
        {label}
      </ButtonStyle>
    );
  }
  return (
    <span>
      <Link to={addSlash(to)} type="underline" className="type-1430">
        {label}
      </Link>
    </span>
  );
};

export default Button;
