import React from "react";

const Glyph4Up = () => (
  <svg
    width="480px"
    viewBox="0 0 480 120"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className="w-full"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Agency"
        className="js-svg"
        transform="translate(-60.000000, -1395.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <path d="M90,1455 C90,1438.32 103.32,1425 120,1425 L120,1395 C86.64,1395 60,1421.64 60,1455 L90,1455 Z M150,1455 C150,1438.32 163.32,1425 180,1425 L180,1395 C146.64,1395 120,1421.64 120,1455 L150,1455 Z M90,1515 C90,1498.32 103.32,1485 120,1485 L120,1455 C86.64,1455 60,1481.64 60,1515 L90,1515 Z M150,1515 C150,1498.32 163.32,1485 180,1485 L180,1455 C146.64,1455 120,1481.64 120,1515 L150,1515 Z M300,1515 L300,1485 C249.96,1485 210,1445.04 210,1395 L180,1395 C180,1461.72 233.28,1515 300,1515 Z M300,1455 L300,1425 C283.32,1425 270,1411.68 270,1395 L240,1395 C240,1428.36 266.64,1455 300,1455 Z M360,1515 C393.36,1515 420,1488.36 420,1455 C420,1421.64 393.36,1395 360,1395 C326.64,1395 300,1421.64 300,1455 C300,1488.36 326.64,1515 360,1515 Z M360,1485 C343.32,1485 330,1471.68 330,1455 C330,1438.32 343.32,1425 360,1425 C376.68,1425 390,1438.32 390,1455 C390,1471.68 376.68,1485 360,1485 Z M540,1515 L540,1395 L420,1395 L420,1515 L540,1515 Z M510,1485 L450,1485 L450,1425 L510,1425 L510,1485 Z" />
      </g>
    </g>
  </svg>
);

export default Glyph4Up;
