import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Svg = styled.svg`
  width: 100%;
  max-width: 360px;
`;

const Glyph3Up = ({ className }) => {
  return (
    <Svg
      width="360px"
      height="120px"
      viewBox="0 0 360 120"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g
        id="Layouts"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Home---Desktop"
          className="js-svg"
          transform="translate(-950.000000, -639.000000)"
          fill="#444444"
          fillRule="nonzero"
        >
          <path
            d="M1010,669 C1010,685.68 996.68,699 980,699 C963.32,699 950,685.68 950,669 C950,652.32 963.32,639 980,639 C996.68,639 1010,652.32 1010,669 Z M1070,729 C1070,745.68 1056.68,759 1040,759 C1023.32,759 1010,745.68 1010,729 C1010,712.32 1023.32,699 1040,699 C1056.68,699 1070,712.32 1070,729 Z M1010,729 C1010,745.68 996.68,759 980,759 C963.32,759 950,745.68 950,729 C950,712.32 963.32,699 980,699 C996.68,699 1010,712.32 1010,729 Z M1070,669 C1070,685.68 1056.68,699 1040,699 C1023.32,699 1010,685.68 1010,669 C1010,652.32 1023.32,639 1040,639 C1056.68,639 1070,652.32 1070,669 Z M1130,639 L1130,669 C1113.32,669 1100,682.32 1100,699 L1070,699 C1070,665.64 1096.64,639 1130,639 Z M1190,639 L1190,669 C1173.32,669 1160,682.32 1160,699 L1130,699 C1130,665.64 1156.64,639 1190,639 Z M1130,699 L1130,729 C1113.32,729 1100,742.32 1100,759 L1070,759 C1070,725.64 1096.64,699 1130,699 Z M1190,699 L1190,729 C1173.32,729 1160,742.32 1160,759 L1130,759 C1130,725.64 1156.64,699 1190,699 Z M1250,759 C1216.64,759 1190,732.36 1190,699 L1220,699 C1220,715.68 1233.32,729 1250,729 C1266.68,729 1280,715.68 1280,699 L1310,699 C1310,732.36 1283.36,759 1250,759 Z M1250,699 C1216.64,699 1190,672.36 1190,639 L1220,639 C1220,655.68 1233.32,669 1250,669 C1266.68,669 1280,655.68 1280,639 L1310,639 C1310,672.36 1283.36,699 1250,699 Z"
            id="tXs"
          />
        </g>
      </g>
    </Svg>
  );
};

Glyph3Up.propTypes = {
  className: PropTypes.string
};

Glyph3Up.defaultProps = {
  className: ""
};

export default Glyph3Up;
