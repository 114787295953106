import React from "react";
import tw, { styled } from "twin.macro";
import Close from "@utility/icons/Close";

type Props = {
  disabled?: boolean;
  onClick: () => void;
};

const Button = styled.button`
  ${tw`box-border border border-solid border-transparent rounded-full hover:border-mono-400 focus:border-mono-400 disabled:(text-mono-500 border-mono-500 pointer-events-none)`}
  ${tw`p-[1.125rem]`}
`;

const CloseButton: React.FC<Props &
  React.HTMLAttributes<HTMLButtonElement>> = ({ onClick, ...rest }) => {
  return (
    <Button {...rest} type="button" onClick={onClick}>
      <Close />
    </Button>
  );
};

export default CloseButton;
