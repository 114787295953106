import React from "react";

const ArrowLeftFilled = () => (
  <svg
    width="32px"
    height="9px"
    viewBox="0 0 32 9"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Layouts"
      stroke="none"
      strokeWidth="1"
      fill="#777777"
      fillRule="evenodd"
    >
      <g
        id="News---Article"
        transform="translate(-489.000000, -1935.000000)"
        fill="#777777"
        fillRule="nonzero"
      >
        <path
          id="Line-3"
          d="M498.5,1935 L498.5,1939 L520.5,1939 L520.5,1940 L498.5,1940 L498.5,1944 L489.5,1939.5 L498.5,1935 Z"
        />
      </g>
    </g>
  </svg>
);

export default ArrowLeftFilled;
