import React, { ReactNode } from "react";
import * as CSS from "csstype";
import tw, { styled } from "twin.macro";
import { screen } from "@helpers/media";

type Props = {
  className?: string;
  style: CSS.Properties;
  children: ReactNode;
};

const AccordionContentStyle = styled.div`
  @media ${screen.mddown} {
    ${tw`h-auto overflow-hidden`}
    transition: max-height 0.4s ease;
  }
`;

const AccordionContent = React.forwardRef<HTMLDivElement, Props>(
  ({ className = "", children, style }, ref) => {
    return (
      <AccordionContentStyle className={className} ref={ref} style={style}>
        {children}
      </AccordionContentStyle>
    );
  }
);

// https://github.com/yannickcr/eslint-plugin-react/issues/2269
AccordionContent.displayName = "AccordionContent";

export default AccordionContent;
