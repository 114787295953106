import React from "react";
import SbEditable from "storyblok-react";
import ScrollInView from "@utility/ScrollInView";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1378%3A6176
 */

type Props = {
  blok: {
    title: string;
    description: string;
    label: string;
    _uid: string;
    component: string;
  };
};

const LandingFeature: React.FC<Props> = ({
  blok,
  blok: { title, description, label }
}) => {
  return (
    <div className="grid-wrapper">
      <div className="grid-overflow">
        <div className="grid-overflow__clear">
          <SbEditable content={blok}>
            <div className="pb-25">
              <div className="grid-row">
                <div className="col-12 md:mr-auto md:col-8 lg:col-7 md:col-offset-1" />
              </div>
              <div className="grid-row">
                <div className="col-1 lg:col-5 md:col-offset-1">
                  <ScrollInView>
                    <p className="has-delay pt-1 text-mono-600 type-1830">
                      {label} —
                    </p>
                  </ScrollInView>
                </div>
                <div className="col-10 lg:col-5 mr-auto">
                  <ScrollInView>
                    <div>
                      <div>
                        {!!title && (
                          <h2 className="pb-6 md:pb-8 type-3630 text-neutral-black">
                            {title}
                          </h2>
                        )}
                      </div>
                      <div>
                        {!!description && (
                          <p className="has-delay type-1830 text-mono-600">
                            {description}
                          </p>
                        )}
                      </div>
                    </div>
                  </ScrollInView>
                </div>
              </div>
            </div>
          </SbEditable>
        </div>
      </div>
    </div>
  );
};

export default LandingFeature;
