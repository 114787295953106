import React from "react";
import SbEditable from "storyblok-react";
import tw, { styled } from "twin.macro";
import { screen } from "@helpers/media";
import DynamicGlyphs from "@utility/glyphs/DynamicGlyphs";
import Label from "@utility/Label";
import ScrollInView from "@utility/ScrollInView";
import { DynamicGlyphType } from "@src/types";
import { VerticalBlock } from "./types";
import Vertical from "./Vertical";

/*
 * Visual Reference
 * https://www.figma.com/file/zODzhJxGWO9syPmmnPEb7z/ED-Website-2022%2F23?node-id=3365%3A30772&t=rqyviShSFYA7E8VX-11
 */

type Props = {
  blok: {
    label?: string;
    description: any;
    vertical_blocks: VerticalBlock[];
    component: string;
    _uid: string;
    glyphs: DynamicGlyphType[];
  };
};

const ListingWrapper = styled.div`
  ${tw`col-12 md:(col-10 col-offset-1)`}
  @media ${screen.xl} {
    display: grid;
    grid-template-columns: 4fr 5fr;
    grid-column-gap: 140px;
  }
`;

const VerticalListingAccordion: React.FC<Props> = ({
  blok,
  blok: { vertical_blocks, label, description, glyphs }
}) => {
  return (
    <div className="relative">
      <SbEditable content={blok}>
        {glyphs && <DynamicGlyphs glyphs={glyphs} />}
        <div className="grid-wrapper text-neutral-black py-25 lg:py-50">
          <div className="grid-row">
            <ListingWrapper>
              <ScrollInView>
                <div className="has-delay-container">
                  {!!label && (
                    <Label className="has-delay mb-10 lg:mb-25">{label}</Label>
                  )}
                  {!!description && (
                    <p className="has-delay type-2430 lg:type-2830 whitespace-pre-wrap mb-6">
                      {description}
                    </p>
                  )}
                </div>
              </ScrollInView>
              <ScrollInView>
                <div className="has-delay-container">
                  {vertical_blocks &&
                    vertical_blocks.map((vertical_block, index) => (
                      <Vertical
                        key={vertical_block._uid}
                        blok={vertical_block}
                        accordion
                        isLastBlock={index === vertical_blocks.length - 1}
                      />
                    ))}
                </div>
              </ScrollInView>
            </ListingWrapper>
          </div>
        </div>
      </SbEditable>
    </div>
  );
};

export default VerticalListingAccordion;
