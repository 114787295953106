import React from "react";
import SbEditable from "storyblok-react";
import Engagement from "@utility/Engagement";
import Label from "@utility/Label";
import ScrollInView from "@utility/ScrollInView";
import { EngagementBlock } from "@src/types";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1378%3A6187
 */

type Props = {
  blok: {
    label?: string;
    description?: string;
    engagement_blocks: EngagementBlock[];
    component: string;
    _uid: string;
  };
};

const EngagementListing: React.FC<Props> = ({
  blok,
  blok: { engagement_blocks, label = "Engagements", description }
}) => {
  return (
    <SbEditable content={blok}>
      <div className="py-25 lg:py-50">
        <div className="grid-wrapper">
          <div className="grid-row">
            <div className="col-10 md:col-6 md:col-offset-1 lg:col-4">
              <ScrollInView>
                <div className="has-delay-container">
                  <Label className="has-delay pb-10">{label}</Label>
                  {!!description && (
                    <p className="has-delay mb-8 type-1830">{description}</p>
                  )}
                </div>
              </ScrollInView>
            </div>
          </div>
        </div>
        <div className="grid-wrapper">
          <div className="grid-row">
            <div className="col-12 lg:col-offset-1 lg:col-10">
              <ScrollInView>
                <div className="has-delay-container">
                  <div className="flex flex-wrap flex-col md:flex-row md:-mx-5 lg:-mx-10">
                    {engagement_blocks &&
                      engagement_blocks.map((engagement_block, index) => (
                        <Engagement
                          key={engagement_block._uid}
                          blok={engagement_block}
                          isLastBlock={index === engagement_blocks.length - 1}
                        />
                      ))}
                  </div>
                </div>
              </ScrollInView>
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  );
};

export default EngagementListing;
