import Intro from "@components/Intro";
import BeliefListing from "@components/BeliefListing";
import QuickGuide from "@components/QuickGuide";
import LandingContactForm from "@components/LandingContactForm";
import LandingTextBlock from "@components/LandingTextBlock";
import TwoUp from "@components/TwoUp";
import HowWeCanHelp from "@components/HowCanWeHelp";
import Service from "@components/Service";
import EngagementListing from "@components/EngagementListing";
import FeatureWorkDriverListing from "@components/FeatureWorkDriverListing";
import PerspectivesListing from "@components/RecentPerspectivesListing";
import FeaturedPerspectivesListing from "@components/FeaturedPerspectivesListing";
import LandingFeature from "@components/LandingFeature";
import MissionStatement from "@components/MissionStatement";
import QuoteBlockCarousel from "@components/Quote/QuoteCarousel";
import ComponentNotFound from "@components/ComponentNotFound";
import LandingHero from "@src/components/LandingHero";
import Hero from "@src/components/Hero";
import LandingBenefitsListing from "@components/LandingBenefitsListing";
import ImageTextDriver from "@components/ImageTextDriver";
import LogoListing from "@components/LogoListing";
import VerticalListing from "@src/components/VerticalListing";
import LandingHorizontalText from "@src/components/LandingHorizontalText";

const ComponentList = {
  intro: Intro,
  services_service: Service,
  list_engagements: EngagementListing,
  list_beliefs: BeliefListing,
  landing_feature: LandingFeature,
  mission_statement: MissionStatement,
  list_benefits: HowWeCanHelp,
  list_landing_benefits: LandingBenefitsListing,
  quick_guide: QuickGuide,
  landing_text_block: LandingTextBlock,
  two_up: TwoUp,
  perspectives_listing: PerspectivesListing,
  featured_perspectives_listing: FeaturedPerspectivesListing,
  landing_contact_form: LandingContactForm,
  featured_works: FeatureWorkDriverListing,
  image_text_driver: ImageTextDriver,
  quote_block_carousel: QuoteBlockCarousel,
  logo_listing: LogoListing,
  landing_hero: LandingHero,
  hero: Hero,
  list_verticals: VerticalListing,
  horizontal_text_block: LandingHorizontalText
};

const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
