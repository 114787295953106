import React from "react";
import SbEditable from "storyblok-react";
import { keyframes } from "styled-components";
import { css, styled } from "twin.macro";
import { CUSTOM_BEZIER } from "@src/common";
import ScrollInView from "@utility/ScrollInView";

import Carousel from "@utility/Carousel";
import Label from "@utility/Label";
import clsxm from "@helpers/clsxm";
import { screen } from "@helpers/media";
import Quote from ".";

/*
 * Visual Reference
 * https://www.figma.com/file/zODzhJxGWO9syPmmnPEb7z/ED-Website-2022%2F23?node-id=3365%3A30946&t=rqyviShSFYA7E8VX-11
 */

type Props = {
  blok: {
    _uid: string;
    component: string;
    label?: string;
    quotes: typeof Quote[];
    bg_wash_desktop?: boolean;
    bg_wash_mobile?: boolean;
  };
};

const fadeInAnim = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const QuoteCarouselWrapper = styled.div`
  ${({ bg_wash_desktop }: { bg_wash_desktop?: boolean }) =>
    css`
      ${bg_wash_desktop &&
        `
          @media ${screen.lg} {
            padding-left: 10px;
          }
        `}
      .swiper-slide {
        &.swiper-slide-visible {
          opacity: 1;
          animation: ${fadeInAnim} 1s ease-in;
        }
        &:not(.swiper-slide-visible) {
          opacity: 0;
          transition: opacity 1s ${CUSTOM_BEZIER};
        }
      }
    `}
`;

const QuoteCarousel: React.FC<Props> = ({
  blok,
  blok: { label, quotes = [], bg_wash_desktop, bg_wash_mobile }
}) => {
  return (
    <QuoteCarouselWrapper
      bg_wash_desktop={bg_wash_desktop}
      className={clsxm(
        "w-full",
        bg_wash_mobile && "bg_wash-mobile",
        bg_wash_desktop && "bg_wash-desktop"
      )}
    >
      <SbEditable content={blok}>
        <div className="py-16 md:py-32">
          {!!label && (
            <div className="grid-wrapper">
              <div className="grid-row">
                <div className="col-12 md:col-10 md:col-offset-1 text-center">
                  <ScrollInView>
                    <Label className="mb-5 md:mb-10">{label}</Label>
                  </ScrollInView>
                </div>
              </div>
            </div>
          )}
          <ScrollInView>
            <Carousel images={quotes} alignment="center">
              {quotes.map((quote, index) => (
                <div key={index} className="px-[0.9375rem] md:px-0 mb-2">
                  <Quote {...quote} center_alignment serif_font small_font />
                </div>
              ))}
            </Carousel>
          </ScrollInView>
        </div>
      </SbEditable>
    </QuoteCarouselWrapper>
  );
};

export default QuoteCarousel;
