import React, { useState, useRef, useEffect } from "react";
import SbEditable from "storyblok-react";
import tw, { styled } from "twin.macro";
import HorizontalLine from "@utility/HorizontalLine";
import CollapseTitle from "@utility/AccordionTitle";
import CollapseButton from "@utility/AccordionButton";
import CollapseContent from "@utility/AccordionContent";
import useWindowDimensions from "@utility/useWindowDimensions";
import { screen } from "@helpers/media";
import { EngagementBlock } from "@src/types";

/*
 * Visual Reference
 * https://www.figma.com/file/zODzhJxGWO9syPmmnPEb7z/ED-Website-2022%2F23?node-id=3365%3A31170&t=GLpPWROvsT8oTjSe-4
 */

type Props = {
  blok: EngagementBlock;
  isFirstBlock: boolean;
  isLastBlock?: boolean;
};

const BlockContainer = styled.div`
  ${tw`w-full md:mx-5 lg:mx-10`}
  @media ${screen.md} {
    width: calc(33.3333% - 2.5rem);
  }
  @media ${screen.lg} {
    width: calc(33.3333% - 5rem);
  }
`;

const ImageContainer = styled.div`
  ${tw`mb-10`}

  img {
    ${tw`inline align-baseline`}
  }
`;

const LandingBenefit: React.FC<Props> = ({
  blok,
  blok: { icon, title, description },
  isFirstBlock = false,
  isLastBlock = false
}) => {
  const content = useRef(null);

  return (
    <BlockContainer>
      <SbEditable content={blok}>
        {!isFirstBlock && (
          <div className="grid-overflow block md:hidden">
            <div className="grid-overflow">
              <HorizontalLine />
            </div>
          </div>
        )}
        <div className="py-10 md:pb-0 md:pt-20">
          <ImageContainer className="has-delay">
            <img src={icon} alt="" role="presentation" />
          </ImageContainer>
          <div className="has-delay">
            <h3 className="pr-5 type-2430 md:pr-0 md:mb-10 md:self-auto">
              {title}
            </h3>
            <p className="pt-6 md:pt-0 type-1830">{description}</p>
          </div>
        </div>
      </SbEditable>
      {isLastBlock && (
        <div className="grid-overflow block md:hidden">
          <div className="grid-overflow">
            <HorizontalLine />
          </div>
        </div>
      )}
    </BlockContainer>
  );
};

export default LandingBenefit;
