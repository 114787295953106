import React, { useState, useRef, useEffect } from "react";
import SbEditable from "storyblok-react";
import tw from "twin.macro";
import styled, { css } from "styled-components";
import ReactMarkdown from "react-markdown";
import Image from "@utility/Image";
import CollapseTitle from "@utility/AccordionTitle";
import CollapseButton from "@utility/AccordionButton";
import CollapseContent from "@utility/AccordionContent";
import ScrollInView from "@utility/ScrollInView";
import useWindowDimensions from "@utility/useWindowDimensions";
import { BG_WASH_MOBILE, BG_WASH_DESKTOP } from "@src/common";
import { screen } from "@helpers/media";
import clsxm from "@helpers/clsxm";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1461%3A3
 */

const ContentText = styled.div`
  ${tw`flex flex-col md:flex-row`}
  p {
    ${tw`mb-6`}
  }
  ul {
    ${tw`pl-4 md:ml-5`}
    list-style-type: none;
  }
  ul li:before {
    margin-left: -1rem;
    padding-right: 0.5rem;
    content: "–";
  }
`;

const ServiceContainer = styled.div`
  ${tw`py-5 md:py-12 lg:pt-0 lg:padding-bottom[140px]`}
`;

const BackgroundWash = styled.section`
  ${tw`w-full`}
  ${css`
    ${BG_WASH_MOBILE}
  `}
  ${css`
    ${BG_WASH_DESKTOP}
  `}

  &:last-of-type {
    @media ${screen.lg} {
      ${ServiceContainer} {
        ${tw`pb-50`}
      }
    }

    ${ServiceContainer} {
      ${tw`pb-25 md:pb-50`}
    }
  }
`;

type Props = {
  blok: {
    icon: string;
    title: string;
    image: string;
    image_mobile: string;
    reverse_orientation: boolean;
    services_list: string;
    description: string;
    has_parallax: boolean;
    _uid: string;
    component: string;
  };
};

const Service: React.FC<Props> = ({
  blok,
  blok: {
    icon = "",
    title = "",
    reverse_orientation = false,
    services_list = "",
    description = "",
    image = "",
    has_parallax = false,
    image_mobile = ""
  }
}) => {
  const { windowWidth } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const [maxHeight, setmaxHeight] = useState("");
  const content = useRef(null);
  const hasMobileImage = image_mobile !== "";
  const { current: refServiceImage } = useRef(
    <div className="col-12 lg:col-4 lg:col-offset-1 relative w-full h-full block mb-10 md:hidden lg:block lg:mb-0 lg:w-auto">
      <div className="grid-overflow">
        <ScrollInView>
          <Image
            image={image}
            maxWidth={920}
            maxHeight={1080}
            quality={80}
            blok={image}
            hasParallax={has_parallax}
            sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, (max-width: 1440px) 33vw, 460px"
            mobileImage={hasMobileImage ? image_mobile : ""}
          />
        </ScrollInView>
      </div>
    </div>
  );

  useEffect(() => {
    setmaxHeight(windowWidth > 767 ? "none" : "0px");
  }, [windowWidth]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setmaxHeight(isOpen ? "0px" : `${content.current.scrollHeight}px`);
  };

  return (
    <BackgroundWash>
      <SbEditable content={blok}>
        <ServiceContainer>
          <div className="grid-wrapper">
            <div
              className={clsxm(
                "grid-row flex-col-reverse lg:items-center",
                reverse_orientation ? "lg:flex-row-reverse" : "lg:flex-row"
              )}
            >
              <div
                className={clsxm(
                  "col-12 md:col-10 lg:col-5 md:col-offset-1",
                  reverse_orientation && "lg:mr-auto"
                )}
              >
                <ScrollInView className="relative lg:h-full">
                  <div className="has-delay-container">
                    <div className="has-delay pb-6 md:pb-8">
                      <img src={icon} alt="" />
                    </div>
                    <CollapseTitle className="has-delay pb-6 md:pb-0">
                      <h2 className="pr-5 md:pr-0 md:pb-8 md:self-auto type-2430 md:type-2430">
                        {title}
                      </h2>
                      <CollapseButton
                        className={`${isOpen ? "is-open" : "is-closed"}`}
                        onClick={toggleAccordion}
                      />
                    </CollapseTitle>
                    <ContentText className="has-delay">
                      {description && description !== "" && (
                        <ReactMarkdown className="w-full md:w-3/5 type-1830 mr-6">
                          {description}
                        </ReactMarkdown>
                      )}
                      {!!services_list && (
                        <div className="w-full md:w-2/5">
                          <CollapseContent
                            ref={content}
                            style={{ maxHeight: `${maxHeight}` }}
                          >
                            <ReactMarkdown className="type-1830">
                              {services_list}
                            </ReactMarkdown>
                          </CollapseContent>
                        </div>
                      )}
                    </ContentText>
                  </div>
                </ScrollInView>
              </div>
              {refServiceImage}
            </div>
          </div>
        </ServiceContainer>
      </SbEditable>
    </BackgroundWash>
  );
};

export default Service;
