import React, { useState } from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Link from "@utility/Link";
import FormInput from "@utility/FormInput";
import Button from "@utility/Button";
import ScrollInView from "@utility/ScrollInView";
import TextArea from "@utility/TextArea";
import gtmPush from "@helpers/gtmPush";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1421%3A6196
 */

const schema = yup.object().shape({
  "cm-xkiyhi-xkiyhi": yup
    .string()
    .required("Email is required.")
    .email("Please input valid email.")
});

const SubscribeForm = ({
  blok,
  blok: { signup_cta_head, signup_socials_description, signup_social_medias },
  email_signup_thankyou
}) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });
  // used for input of field
  const [state, setState] = React.useState({});
  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const [submitted, setSubmitted] = useState(false);
  const onSubmit = (data, e) => {
    e.preventDefault();

    gtmPush("Form", "Newsletter", "Subscribe");

    fetch(
      "https://n8n.enginedigital.com/webhook/161b9cb3-c063-48e9-8d7f-79de27a427d2",
      {
        method: "POST",
        body: new FormData(e.target)
      }
    )
      .then(() => setSubmitted(true))
      .catch(error => alert(error));
  };

  return (
    <div className="py-12 md:py-24 lg:py-50">
      <SbEditable content={blok}>
        <div className="grid-wrapper">
          <ScrollInView>
            <div className="has-delay-container featured-works__content">
              <div className="grid-row">
                <div className="col-12 mb-8 md:col-10 md:col-offset-1 lg:col-8 lg:col-offset-2">
                  <h4 className="type-2430 text-mono-700">{signup_cta_head}</h4>
                </div>
              </div>
              {!submitted ? (
                <form
                  name="subscribe"
                  className="mb-20 grid-row"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <div className="col-12 md:col-4 md:col-offset-1 lg:col-3 lg:col-offset-2">
                    <FormInput
                      type="text"
                      name="cm-name"
                      message="Name"
                      maxlength="200"
                      ref={register}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 md:col-4 lg:col-3">
                    <FormInput
                      type="email"
                      name="cm-xkiyhi-xkiyhi"
                      message="Email *"
                      ref={register}
                      onChange={handleChange}
                      required
                    />
                    <p className="text-error type-1430">
                      {errors["cm-xkiyhi-xkiyhi"]?.message}
                    </p>
                  </div>
                  <div className="col-12 md:col-2">
                    <Button button label="Subscribe" type="submit" />
                  </div>
                </form>
              ) : (
                <div className="grid-row">
                  <div className="col-12 md:col-10 md:col-offset-1 lg:col-8 lg:col-offset-2">
                    <TextArea
                      text={email_signup_thankyou}
                      className="type-2030 mb-8 lg:type-2830 lg:mb-12"
                    />
                  </div>
                </div>
              )}
              <div className="grid-row mt-4 md:mt-8">
                <div className="col-12 md:col-10 md:col-offset-1 lg:col-9 lg:col-offset-2">
                  <p className="type-1830 text-mono-700">
                    {signup_socials_description}
                    {signup_social_medias &&
                      signup_social_medias.map((media, index) => (
                        <Link
                          to={media.link.url}
                          type="underline"
                          className="ml-4"
                          key={`link${index}`}
                        >
                          {media.link_title}
                        </Link>
                      ))}
                  </p>
                </div>
              </div>
            </div>
          </ScrollInView>
        </div>
      </SbEditable>
    </div>
  );
};

SubscribeForm.propTypes = {
  blok: PropTypes.shape({
    signup_cta_head: PropTypes.string,
    signup_socials_description: PropTypes.string,
    signup_social_medias: PropTypes.array
  }),
  email_signup_thankyou: PropTypes.string
};

SubscribeForm.defaultProps = {
  blok: {
    signup_cta_head: "Get updates, insights, and recommended reads.",
    signup_socials_description: "Join us on these networks"
  },
  email_signup_thankyou: "Thanks for signing up!"
};

export default SubscribeForm;
