import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import PropTypes from "prop-types";
import HeaderType from "@utility/Header";
import AnimateKeyframes from "@utility/AnimateKeyframes";
import AnimateHeroText from "@utility/AnimateHeroText";
import DynamicGlyphs from "@utility/glyphs/DynamicGlyphs";
import Glyph4Up from "@utility/glyphs/GlyphFourUp";
import TextArea from "@utility/TextArea";
import Image from "@utility/Image";
import Components from "@builder/ComponentsAgency";
import SearchEngines from "@utility/Seo";
import ScrollInView from "@utility/ScrollInView";
import HeaderContainer from "@utility/HeaderContainer";
import HeroInnerContainer from "@utility/HeroInnerContainer";
import { screen } from "@helpers/media";
import { BG_WASH_DESKTOP, BG_WASH_MOBILE_HERO } from "@src/common";

const BackgroundWash = styled.div`
  ${BG_WASH_DESKTOP}
  ${BG_WASH_MOBILE_HERO}
  &:after {
    top: 90px;
    margin-left: calc((100% - 100vw) / 2);
  }
`;

const GlyphScrollContainer = styled.div`
  ${tw`overflow-hidden w-full absolute`}
  transform: translateY(-9vw);
  height: 9vw;
  @media ${screen.xl} {
    transform: translateY(-120px);
    height: 120px;
  }
`;

const Glyph4UpContainerStyle = styled.div`
  ${tw`absolute ml-2 md:ml-0`}
  top: 0;
  width: 36vw;
  z-index: 1;
  @media ${screen.md} {
    left: 2rem;
  }
  @media ${screen.xl} {
    width: 480px;
  }
`;

const SecondaryPageTemplate = ({
  blok,
  blok: {
    seo,
    title,
    mobile_title,
    hero_image,
    hero_image_mobile,
    hero_glyphs,
    show_hero_image_glyph,
    body
  },
  pageContext
}) => {
  const hasMobileImage =
    hero_image_mobile && hero_image_mobile !== "" ? true : false;
  return (
    <div>
      <SearchEngines
        title={seo.title || pageContext.story.name || ""}
        description={seo.description || title || ""}
        image={seo.og_image || hero_image || ""}
      />
      <AnimateKeyframes>
        <HeaderContainer>
          <div className="grid-wrapper w-full">
            <div className="grid-row relative z-50">
              <div className="col-12">
                <AnimateHeroText>
                  <HeaderType>
                    <TextArea
                      text={title}
                      mobile_text={mobile_title}
                      nestedAnimation
                    />
                  </HeaderType>
                </AnimateHeroText>
              </div>
            </div>
          </div>
          {hero_glyphs && <DynamicGlyphs glyphs={hero_glyphs} isHero />}
        </HeaderContainer>
        <div className="grid-wrapper relative">
          <BackgroundWash className="fade-in fade-in__3">
            <div className="grid-row">
              <div className="col-12">
                <div className="grid-overflow overflow-hidden">
                  <ScrollInView>
                    <HeroInnerContainer>
                      <Image
                        image={hero_image}
                        blok={blok}
                        maxWidth={2640}
                        maxHeight={1632}
                        quality={80}
                        sizes="(max-width: 1440px) 100vw, 1400px"
                        mobileImage={hasMobileImage ? hero_image_mobile : ""}
                        loading="eager"
                      />
                    </HeroInnerContainer>
                  </ScrollInView>
                  {show_hero_image_glyph && (
                    <GlyphScrollContainer>
                      <ScrollInView>
                        <Glyph4UpContainerStyle>
                          <Glyph4Up />
                        </Glyph4UpContainerStyle>
                      </ScrollInView>
                    </GlyphScrollContainer>
                  )}
                </div>
              </div>
            </div>
          </BackgroundWash>
        </div>
      </AnimateKeyframes>

      <div>
        {body &&
          body.map(element =>
            React.createElement(Components(element.component), {
              key: element._uid,
              blok: element
            })
          )}
      </div>
    </div>
  );
};

SecondaryPageTemplate.propTypes = {
  blok: PropTypes.shape({
    seo: PropTypes.object,
    title: PropTypes.string,
    mobile_title: PropTypes.string,
    hero_image: PropTypes.string,
    hero_image_mobile: PropTypes.string,
    hero_glyphs: PropTypes.array,
    show_hero_image_glyph: PropTypes.bool,
    component: PropTypes.string,
    body: PropTypes.array
  }).isRequired,
  seo: PropTypes.objectOf(PropTypes.string),
  pageContext: PropTypes.shape({
    story: PropTypes.object
  })
};

SecondaryPageTemplate.defaultProps = {
  seo: {},
  pageContext: {}
};

export default SecondaryPageTemplate;
