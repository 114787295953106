import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import PropTypes from "prop-types";
import { screen } from "@helpers/media";

const RteBody = styled.div`
  ${tw` text-lg leading-normal text-mono-700 flow-root`}
  a,
  .underline-links a {
    ${tw`text-mono-700`}
  }
  p {
    ${tw`text-lg mb-6`}
    line-height: 1.75rem;
  }
  blockquote {
    ${tw`border-l-4 border-mono-600 pl-2`}
    p {
      ${tw`text-xl text-mono-600 italic mb-6`}
    }
  }
  h2 {
    ${tw`type-2830 mb-8 md:mb-12 font-extralight text-mono-700`}
  }
  h3 {
    ${tw`mt-8 md:mt-16 font-normal`}
    font-size: 1.75rem;
    line-height: 2.5rem;
    margin-bottom: 30px;
  }
  h4 {
    ${tw`mt-8 md:mt-12 font-normal`}
    font-size: 1.375rem;
    line-height: 2rem;
    margin-bottom: 1em;
  }
  h5 {
    ${tw`mt-8 font-normal`}
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 1em;
  }
  h6 {
    ${tw`mt-8 font-normal`}
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 1em;
  }
  ul {
    ${tw`ml-0 pl-4 mb-4 overflow-auto`}
    list-style: none;
  }
  ul > li,
  ol > li {
    ${tw`relative`}
  }
  ul > li:before {
    ${tw`inline-block relative`}
    content: "–";
    left: -1rem;
    width: 0;
    float: left;
  }
  ol {
    ${tw`pl-6 mb-4 overflow-auto`}
    list-style-type: none;
    counter-reset: ol-counter;
  }
  ol > li:before {
    ${tw`inline-block relative text-sm`}
    content: counter(ol-counter) ' -';
    counter-increment: ol-counter;
    left: -1.5rem;
    top: 0.25rem;
    margin-right: -1rem;
    width: 1rem;
    float: left;
  }
  li > ol,
  li > ul {
    ${tw`pt-4`}
  }
  li {
    ${tw`pb-4`}
  }
  li p {
    ${tw`mb-0`}
  }
  img {
    ${tw`my-2`}
  }
  bold,
  strong {
    ${tw`font-normal`}
  }
  @media ${screen.lg} {
    .pull-image-left,
    .pull-image-right {
      ${tw`block pb-16`}
      width:33.33333%;
      width: calc(33.33333% + 64px);
      > div {
        ${tw`overflow-visible`}
        > div {
          ${tw`overflow-visible`}
          > div {
            width: 200%;
            max-width: 460px;
          }
        }
      }
      img {
        width: 200%;
        max-width: 460px;
      }
    }
    .pull-image-right {
      ${tw`float-right pl-16`}
    }
    .pull-image-left {
      ${tw`float-left pr-16`}
      > div > div > div,
      img {
        transform: translateX(-50%);
      }
    }
    .wide-image {
      ${tw`relative block`}
      left:-16.666665%;
      left: calc(-16.666665% - 4px);
      width: 133.3333%;
      width: calc(133.3333% + 8px);
      max-width: 940px;
    }
  }
  .caption {
    ${tw`italic inline-block mb-4`}
  }
  .iframe {
    ${tw`w-full relative`}
    padding-bottom: 56.25%;
  }
  .iframe iframe {
    ${tw`w-full h-full absolute top-0`}
  }
`;

const RichTextEditorStyles = ({ children, className }) => {
  if (children !== undefined) {
    return <RteBody className={className}>{children}</RteBody>;
  }
  return <RteBody>Define children</RteBody>;
};

RichTextEditorStyles.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

RichTextEditorStyles.defaultProps = {
  className: ""
};

export default RichTextEditorStyles;
