import React from "react";
import SbEditable from "storyblok-react";
import Link from "@utility/Link";
import { addSlash } from "@src/common";
import { FooterItemType } from "./types";

type Props = {
  blok: FooterItemType;
};

const FooterItem: React.FC<Props> = ({
  blok,
  blok: { footer_link, footer_label }
}) => (
  <SbEditable content={blok}>
    <li className="mr-6 mb-6 md:mb-0">
      <Link
        type="underline"
        className="type-2030 text-mono-700"
        to={`/${addSlash(footer_link.cached_url)}`}
      >
        {footer_label}
      </Link>
    </li>
  </SbEditable>
);

export default FooterItem;
