import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import tw from "twin.macro";
import PropTypes from "prop-types";
import { widowFixer } from "@src/common";
import WorkSectionContainer from "@utility/WorkSectionContainer";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1463%3A6178
 */

const TitleStyle = styled.h2`
  ${tw`text-mono-700 font-light mb-4 lg:mb-0`}
`;

const MarkdownStyle = styled(ReactMarkdown)`
  p {
    ${tw`text-mono-700 mb-6 lg:mb-8`}
    &:last-of-type {
      ${tw`mb-0`}
    }
  }
  a {
    ${tw`text-mono-700`}
  }
`;

const outputFont = font_type => {
  let classes = "";
  if (font_type === "gibson_light") {
    classes = "type-3630 font-extralight";
  } else if (font_type === "gibson_book") {
    classes = "type-3630 font-light";
  } else if (font_type === "gibson_regular") {
    classes = "type-3630 font-medium";
  } else if (font_type === "gibson_medium") {
    classes = "type-3630 font-bold";
  } else {
    classes = "type-1830";
  }
  return classes;
};

const HorizontalText = ({ blok, blok: { text, font_type } }) => {
  return (
    <WorkSectionContainer blok={blok}>
      <div className="col-12 lg:col-offset-1 lg:col-3">
        <TitleStyle className={`type-2830 ${outputFont(font_type)}`}>
          {widowFixer(blok.title)}
        </TitleStyle>
      </div>

      <div className="col-12 lg:col-offset-1 lg:col-5">
        <MarkdownStyle
          className={`${outputFont(font_type)} underline-links`}
          escapeHtml={false}
        >
          {text}
        </MarkdownStyle>
      </div>
    </WorkSectionContainer>
  );
};

HorizontalText.propTypes = {
  blok: PropTypes.shape({
    has_background: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
    font_type: PropTypes.string
  })
};

HorizontalText.defaultProps = {
  blok: {
    has_background: false
  }
};

export default HorizontalText;
