import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import tw from "twin.macro";
import { useInView } from "react-intersection-observer";
import useWindowDimensions from "@utility/useWindowDimensions";
import { screen } from "@helpers/media";

const VideoHolder = styled.div`
  ${tw`relative mx-auto overflow-hidden`}
  padding-bottom: ${props =>
    props.video_aspect ? props.video_aspect : "56.23"}%;
  ${props =>
    props.video_aspect_mobile &&
    css`
      @media ${screen.mddown} {
        padding-bottom: ${props.video_aspect_mobile}%;
      }
    `}
  ${props =>
    props.mobileRatio &&
    css`
      @media ${screen.mddown} {
        padding-bottom: 76%;
      }
    `}
`;

const StyledVideo = styled.video`
  ${tw`absolute w-full h-full inset-0`}
  object-fit: cover;
  object-position: center center;
  ${props =>
    props.mobileRatio &&
    css`
      @media ${screen.mddown} {
        object-fit: cover;
      }
    `}
`;

const srcBasedOnWidth = (windowWidth, sd, hdmd, hd) => {
  if (windowWidth >= 1200) {
    return hd;
  }
  if (windowWidth >= 768) {
    return hdmd;
  }
  return sd;
};

const Video = ({
  autoPlay,
  loop,
  sd,
  lazyLoad,
  hdmd,
  hd,
  className,
  videoClassName,
  video_aspect,
  video_aspect_mobile
  // poster_image
}) => {
  // https://github.com/thebuilder/react-intersection-observer/blob/HEAD/docs/Recipes.md
  const [ref, inView] = useInView({
    /* Optional options */
    // threshold: 0.05,
    rootMargin: "200px 0px",
    triggerOnce: true
  });
  const { windowWidth } = useWindowDimensions();

  if (!sd || !hdmd || !hd || autoPlay === undefined || loop === undefined) {
    return (
      <VideoHolder>
        <p>Please add all video versions</p>
      </VideoHolder>
    );
  }
  // const renderPosterImage = () => {
  //   if (poster_image) {
  //     return <img src={poster_image} alt="client work poster" />;
  //   }
  // };
  return (
    <VideoHolder
      className={className}
      video_aspect={video_aspect}
      video_aspect_mobile={video_aspect_mobile}
      ref={lazyLoad ? ref : undefined}
    >
      {(!autoPlay && inView) || !lazyLoad ? (
        <StyledVideo
          className={videoClassName}
          autoPlay={autoPlay}
          loop={loop}
          preload="auto"
          src={srcBasedOnWidth(windowWidth, sd, hdmd, hd)}
          controls
        />
      ) : (
        <StyledVideo
          className={videoClassName}
          autoPlay={autoPlay}
          muted
          loop={loop}
          preload="auto"
          src={srcBasedOnWidth(windowWidth, sd, hdmd, hd)}
          playsInline
        />
      )}
    </VideoHolder>
  );
};

Video.propTypes = {
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  sd: PropTypes.string,
  hdmd: PropTypes.string,
  hd: PropTypes.string,
  video_aspect: PropTypes.string,
  video_aspect_mobile: PropTypes.string,
  className: PropTypes.string,
  videoClassName: PropTypes.string,
  mobileRatio: PropTypes.bool,
  lazyLoad: PropTypes.bool
  // poster_image: PropTypes.string
};

Video.defaultProps = {
  autoPlay: false,
  loop: false,
  sd: "",
  hdmd: "",
  video_aspect: "75",
  video_aspect_mobile: "75",
  hd: "",
  className: "",
  videoClassName: "",
  mobileRatio: false,
  lazyLoad: true
  // poster_image: ""
};

export default Video;
