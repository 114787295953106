import React, { useState, useRef, useEffect } from "react";
import SbEditable from "storyblok-react";
import tw, { styled } from "twin.macro";
import useWindowDimensions from "@utility/useWindowDimensions";
import { screen } from "@helpers/media";
import { EngagementBlock } from "@src/types";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1378%3A6178
 */

type Props = {
  blok: EngagementBlock;
  isLastBlock: boolean;
};

const BlockContainer = styled.div`
  ${tw`w-full md:mx-5 lg:mx-10`}
  @media ${screen.md} {
    width: calc(33.3333% - 2.5rem);
  }
  @media ${screen.lg} {
    width: calc(33.3333% - 5rem);
  }
`;

const ImageContainer = styled.div`
  ${tw`mb-12 block`}

  img {
    ${tw`inline align-baseline`}
  }
`;

const HelpPoint: React.FC<Props> = ({
  blok,
  blok: { icon, title, description, estimate_text = "" },
  isLastBlock = false
}) => {
  return (
    <BlockContainer>
      <SbEditable content={blok}>
        <div className="pt-20 pb-0">
          <ImageContainer className="has-delay">
            <img src={icon} alt="" />
          </ImageContainer>
          <div className="has-delay">
            {!!title && <h3 className="pr-5 type-1830 pb-5">{title} —</h3>}
            <p className="pt-0 type-1830 text-mono-600">{description}</p>
          </div>
        </div>
      </SbEditable>
    </BlockContainer>
  );
};

export default HelpPoint;
