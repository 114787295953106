import React, { useEffect, useState, useRef } from "react";
import SbEditable from "storyblok-react";
import tw, { styled, css } from "twin.macro";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "@utility/FormInput";
import Button from "@utility/Button";
import ScrollInView from "@utility/ScrollInView";
import gtmPush from "@helpers/gtmPush";
import BikeForm from "../Forms/BikeCampaignMonitor";

/*
 * Visual Reference
 * https://www.figma.com/file/TWO2VuPSqYKYww3Ni83udq/Bike-Business-Landing-Page?node-id=440%3A413
 */

type Props = {
  blok: {
    title: string;
    body: string;
    form_content: string;
    component: string;
    subscribe_glyph: string;
    _editable: string;
    _uid: string;
  };
};

type FormData = {
  Company: string;
  Email: string;
  Name: string;
  status: string;
};

type EncodeData = {
  Company: string;
  Email: string;
  Name: string;
  "form-name": string;
};

const FormContainer = styled.div`
  ${tw`relative`}
  z-index:61;
`;

const ParallaxContainer = styled.div`
  ${tw`absolute inset-0 overflow-hidden hidden lg:block`}
`;
type ParallaxBackgroundType = {
  bgImage: string;
};

const ParallaxImageLayer = styled.div(({ bgImage }: ParallaxBackgroundType) => [
  css`
  ${tw`absolute w-full inset-x-0 top-0 hidden lg:block`}
  background-image: url(${bgImage});
  background-position: right top;
  will-change: transform;
  background-repeat: no-repeat repeat;
  background-size: 35% auto;
  height: 9999px;
`
]);

const schema = yup.object().shape({
  Name: yup.string().required("Name is required."),
  Email: yup
    .string()
    .required("Email is required.")
    .email("Please input valid email.")
});

const LandingSubscribeForm: React.FC<Props> = ({
  blok,
  blok: { title, body, form_content, subscribe_glyph }
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setOffset(Math.floor(window.pageYOffset * 0.1));
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [offset]);
  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  function encode(data: EncodeData) {
    return Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join("&");
  }

  const [state, setState] = React.useState<FormData>();
  const formRef = useRef<HTMLFormElement>(null);
  // const recaptchaRef = useRef(null);

  const handleChange = (
    e: React.FormEvent & {
      target: HTMLFormElement;
    }
  ) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onSubmit = async (_: FormData, e: React.FormEvent) => {
    e.preventDefault();

    gtmPush("Contact Us", "Form", "Submit");

    const form = e.target as HTMLFormElement;
    // const recaptchaValue = await recaptchaRef.current.executeAsync();
    fetch("/contact?no-cache=1", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        // "g-recaptcha-response": recaptchaValue,
        ...state
      })
    })
      .then(() => setIsSubmitted(true))
      .catch(error => alert(error));
  };

  return (
    <div>
      <div className="relative z-10">
        <div className="py-25">
          <SbEditable content={blok}>
            <ScrollInView>
              <div className="grid-wrapper has-delay-container featured-works__content">
                <div className="grid-row md:pt-8">
                  <div className="col-12 md:col-10 md:col-offset-1 lg:col-6 lg:col-offset-1 xl:col-4 xl:col-offset-1">
                    {!!title && (
                      <h3 className="type-serif-4260 md:type-serif-6460 mb-8 text-neutral-black">
                        {title}
                      </h3>
                    )}
                    {!!body && (
                      <p className="type-2030 md:type-2430 mb-12 text-neutral-black">
                        {body}
                      </p>
                    )}
                    {form_content === "cm_bikes" && (
                      <BikeForm blok={{ form_content }} />
                    )}
                    {form_content === "default" && (
                      <FormContainer>
                        <form
                          ref={formRef}
                          name="contact"
                          method="POST"
                          action="/thank-you"
                          data-netlify="true"
                          // data-netlify-recaptcha="true"
                          netlify-honeypot="status"
                          onSubmit={handleSubmit(
                            async (data: FormData, e: React.FormEvent) =>
                              onSubmit(data, e)
                          )}
                          className="mt-6 mb-0"
                          noValidate
                        >
                          <input
                            type="text"
                            name="status"
                            className="invisible"
                            ref={register}
                          />
                          <noscript>
                            <p>This form won’t work with Javascript disabled</p>
                          </noscript>
                          <div className="mb-4">
                            <FormInput
                              type="text"
                              message="Company"
                              name="Company"
                              onChange={handleChange}
                              ref={register}
                            />
                          </div>
                          <div className="mb-4">
                            <FormInput
                              type="text"
                              message="Name*"
                              name="Name"
                              onChange={handleChange}
                              ref={register}
                              style={{
                                borderColor: errors.Name && "#ff0303"
                              }}
                              errorMessage={errors.Name?.message}
                            />
                          </div>
                          <div className="mb-4">
                            <FormInput
                              type="email"
                              message="Email*"
                              name="Email"
                              onChange={handleChange}
                              ref={register}
                              style={{
                                borderColor: errors.Email && "#ff0303"
                              }}
                              errorMessage={errors.Email?.message}
                            />
                          </div>
                          <div className="mt-4 mb-0">
                            <Button
                              button
                              label="Send"
                              type="submit"
                              twStyles={tw`mt-8`}
                            />

                            {/* <Recaptcha
                            ref={recaptchaRef}
                            sitekey={RECAPTCHA_KEY}
                            size="invisible"
                            onExpired={onExpired}
                          /> */}
                          </div>
                        </form>
                      </FormContainer>
                    )}
                  </div>
                </div>
              </div>
            </ScrollInView>
          </SbEditable>
        </div>
      </div>
      <ParallaxContainer>
        {subscribe_glyph && (
          <ParallaxImageLayer
            bgImage={subscribe_glyph}
            style={{
              transform: `translateY(-${offset}px)`
            }}
          />
        )}
      </ParallaxContainer>
    </div>
  );
};

export default LandingSubscribeForm;
