import HeroDriver from "@components/HeroDriver";
import FeaturedWork from "@components/WorkDriver";
import LogoTextListing from "@components/LogoTextListing";
import ComponentNotFound from "@components/ComponentNotFound";

const ComponentList = {
  hero_driver: HeroDriver,
  featured_work: FeaturedWork,
  logo_text_listing: LogoTextListing
};

const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
