import React from "react";
import tw, { styled } from "twin.macro";
import { screen } from "@helpers/media";

const Svg = styled.svg`
  ${tw`text-mono-700`}
  max-width: 180px;
  transition: color 0.3s ease;
  .is-dark & {
    ${tw`text-white`}
  }
  @media ${screen.lg} {
    .has-menu-opened & {
      color: #d9d9d9;
    }
    .is-dark.has-menu-opened & {
      ${tw`text-mono-700`}
    }
  }
`;

const Logo = () => (
  <Svg
    width="180px"
    height="40px"
    viewBox="0 0 180 29"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon
        id="path-1"
        points="0.444702035 0.481395349 5.18082122 0.481395349 5.18082122 4.69139535 0.444702035 4.69139535"
      />
      <polygon
        id="path-3"
        points="0.430704942 0.481395349 5.16420785 0.481395349 5.16420785 4.69139535 0.430704942 4.69139535"
      />
      <polygon
        id="path-5"
        points="0.0570348837 0.481395349 4.79053779 0.481395349 4.79053779 4.69139535 0.0570348837 4.69139535"
      />
      <polygon
        id="path-7"
        points="0 29.9054001 180 29.9054001 180 0.603074497 0 0.603074497"
      />
    </defs>
    <g
      id="Layouts"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Home" transform="translate(-130.000000, -61.000000)">
        <g id="Group-28" transform="translate(130.000000, 60.000000)">
          <path
            d="M169.547697,20.7367438 L169.444936,20.7367438 C168.109649,22.7945883 166.060568,23.6263303 163.768428,23.6263303 C158.909091,23.6263303 155.930233,19.9707285 155.930233,15.1882118 C155.930233,10.6375312 159.044466,6.88214426 164.07179,6.88214426 C166.298704,6.88214426 168.179797,7.67923038 169.342174,9.40664864 L169.409861,9.40664864 C169.374787,8.6764843 169.374787,7.18090218 169.374787,7.18090218 L173.72093,7.18090218 L173.72093,23.2618457 L169.582772,23.2618457 L169.547697,20.7367438 Z M169.48001,15.1882118 C169.48001,12.4981955 167.665989,10.1386055 164.860656,10.1386055 C162.02271,10.1386055 160.379753,12.4981955 160.379753,15.1882118 C160.379753,17.9786107 162.158084,20.1702988 164.997876,20.1702988 C167.63153,20.1702988 169.48001,18.2116419 169.48001,15.1882118 L169.48001,15.1882118 Z"
            id="Fill-1"
            fill="currentColor"
          />
          <path
            d="M16.6765651,16.5822162 L4.50970515,16.5822162 C4.91973423,19.1073181 6.97172384,20.369869 9.42943938,20.369869 C11.6541699,20.369869 13.6360796,19.4066735 14.4217125,18.7422359 L16.1306793,21.0337091 C15.2048865,21.9652363 12.7102868,23.6263303 8.67884641,23.6263303 C3.69087646,23.6263303 0,20.668627 0,15.4875672 C0,10.3381758 3.85869946,6.88214426 8.57618546,6.88214426 C13.6686606,6.88214426 16.744186,10.1386055 16.744186,14.4245866 C16.744186,15.2539385 16.6765651,16.5822162 16.6765651,16.5822162 M4.43962522,13.8288633 L12.3697229,13.8288633 C12.2996429,12.0649966 11.616671,10.0388204 8.54114549,10.0388204 C5.94388483,10.0388204 4.57917035,11.7662386 4.43962522,13.8288633"
            id="Fill-3"
            fill="currentColor"
          />
          <path
            d="M29.0135633,14.8288068 C29.0135633,12.3849485 28.5557695,10.4474532 25.9699891,10.4474532 C23.2100206,10.4474532 22.2636199,12.6903544 22.2636199,15.4402295 L22.2636199,23.6263303 L17.7906977,23.6263303 L17.7906977,7.1875502 L22.2636199,7.1875502 L22.2296627,9.66683562 L22.3346786,9.66683562 C23.2439778,7.93579476 24.9563028,6.88214426 27.4037389,6.88214426 C32.264654,6.88214426 33.4883721,9.94047934 33.4883721,13.8789943 L33.4883721,23.6263303 L29.0135633,23.6263303 L29.0135633,14.8288068 Z"
            id="Fill-5"
            fill="currentColor"
          />
          <path
            d="M47.9122086,20.3214183 C46.7825624,21.9971082 44.7964523,22.8687081 42.2664385,22.8687081 C38.0936385,22.8687081 34.5348837,20.255114 34.5348837,15.094833 C34.5348837,10.6042252 37.7527758,6.88214426 42.6774427,6.88214426 C44.8992034,6.88214426 46.7825624,7.68683707 47.9448182,9.42943401 L48.0149596,9.42943401 L47.9798889,7.18413011 L52.3255814,7.18413011 L52.3255814,20.0543929 C52.3255814,23.6070966 51.5718686,25.6167189 50.16904,27.05733 C48.7336017,28.531696 46.612131,29.9054001 42.642372,29.9054001 C38.7076837,29.9054001 35.767281,28.0633467 34.9477337,27.4919244 L36.7609513,24.5100403 C37.8186103,25.2466205 40.5233624,26.4190007 42.642372,26.4190007 C46.3721735,26.4190007 47.9798889,24.7113642 47.9798889,21.1261111 L47.9798889,20.3214183 L47.9122086,20.3214183 Z M43.5665161,10.2684845 C40.6937938,10.2684845 38.982712,12.3468222 38.982712,14.9941711 C38.982712,17.4721427 40.6261134,19.5167255 43.4963747,19.5167255 C46.6447406,19.5167255 48.2204617,17.4721427 48.2204617,14.9941711 C48.2204617,12.648808 46.4392385,10.2684845 43.5665161,10.2684845 L43.5665161,10.2684845 Z"
            id="Fill-7"
            fill="currentColor"
          />
          <g id="Group-11" transform="translate(53.372093, 0.603074)">
            <mask id="mask-2" fill="#f8f8f8">
              <use xlinkHref="#path-1" />
            </mask>
            <g id="Clip-10" />
            <path
              d="M5.18082122,2.58732558 C5.18082122,1.42511628 4.1212282,0.480930233 2.81374273,0.480930233 C1.50364099,0.480930233 0.444702035,1.42511628 0.444702035,2.58732558 C0.444702035,3.74953488 1.50364099,4.69139535 2.81374273,4.69139535 C4.1212282,4.69139535 5.18082122,3.74953488 5.18082122,2.58732558"
              id="Fill-9"
              fill="currentColor"
              mask="url(#mask-2)"
            />
          </g>
          <g id="Group-14" transform="translate(114.069767, 0.603074)">
            <mask id="mask-4" fill="#f8f8f8">
              <use xlinkHref="#path-3" />
            </mask>
            <g id="Clip-13" />
            <path
              d="M5.16420785,2.58732558 C5.16420785,1.42511628 4.10461483,0.480930233 2.79712936,0.480930233 C1.48702762,0.480930233 0.430704942,1.42511628 0.430704942,2.58732558 C0.430704942,3.74953488 1.48702762,4.69139535 2.79712936,4.69139535 C4.10461483,4.69139535 5.16420785,3.74953488 5.16420785,2.58732558"
              id="Fill-12"
              fill="currentColor"
              mask="url(#mask-4)"
            />
          </g>
          <g id="Group-17" transform="translate(139.186047, 0.603074)">
            <mask id="mask-6" fill="#f8f8f8">
              <use xlinkHref="#path-5" />
            </mask>
            <g id="Clip-16" />
            <path
              d="M4.79053779,2.58732558 C4.79053779,1.42511628 3.73356105,0.480930233 2.4234593,0.480930233 C1.11597384,0.480930233 0.0570348837,1.42511628 0.0570348837,2.58732558 C0.0570348837,3.74953488 1.11597384,4.69139535 2.4234593,4.69139535 C3.73356105,4.69139535 4.79053779,3.74953488 4.79053779,2.58732558"
              id="Fill-15"
              fill="currentColor"
              mask="url(#mask-6)"
            />
          </g>
          <mask id="mask-8" fill="#f8f8f8">
            <use xlinkHref="#path-7" />
          </mask>
          <g id="Clip-19" />
          <polygon
            id="Fill-18"
            fill="currentColor"
            mask="url(#mask-8)"
            points="54.4186047 23.6263303 58.6046512 23.6263303 58.6046512 6.88214426 54.4186047 6.88214426"
          />
          <path
            d="M71.918742,14.8288068 C71.918742,12.3849485 71.4629078,10.4474532 68.8781703,10.4474532 C66.1161291,10.4474532 65.1717663,12.6903544 65.1717663,15.4402295 L65.1717663,23.6263303 L60.6976744,23.6263303 L60.6976744,7.1875502 L65.1717663,7.1875502 L65.1384432,9.66683562 L65.2409273,9.66683562 C66.1494522,7.93579476 67.8640177,6.88214426 70.3110618,6.88214426 C75.1718268,6.88214426 76.3953488,9.94047934 76.3953488,13.8789943 L76.3953488,23.6263303 L71.918742,23.6263303 L71.918742,14.8288068 Z"
            id="Fill-20"
            fill="currentColor"
            mask="url(#mask-8)"
          />
          <path
            d="M94.1159769,16.5822162 L81.9509034,16.5822162 C82.3633309,19.1073181 84.4125606,20.369869 86.8748325,20.369869 C89.0943191,20.369869 91.0759378,19.4066735 91.863914,18.7422359 L93.5701712,21.0337091 C92.646973,21.9652363 90.1551981,23.6263303 86.1212765,23.6263303 C81.132195,23.6263303 77.4418605,20.668627 77.4418605,15.4875672 C77.4418605,10.3381758 81.3049105,6.88214426 86.0192452,6.88214426 C91.1128166,6.88214426 94.1860465,10.1386055 94.1860465,14.4245866 C94.1860465,15.2539385 94.1159769,16.5822162 94.1159769,16.5822162 M81.8851363,13.8288633 L89.8122257,13.8288633 C89.7446146,12.0649966 89.0611283,10.0388204 85.9842104,10.0388204 C83.3891751,10.0388204 82.0203584,11.7662386 81.8851363,13.8288633"
            id="Fill-21"
            fill="currentColor"
            mask="url(#mask-8)"
          />
          <path
            d="M108.849878,20.7458919 L108.747113,20.7458919 C107.41178,22.7972215 105.360167,23.6263303 103.067948,23.6263303 C98.2084432,23.6263303 95.2325581,19.9823018 95.2325581,15.2149261 C95.2325581,10.6786526 98.3462841,6.93515481 103.374398,6.93515481 C105.600773,6.93515481 107.47947,7.72971743 108.644348,9.45166684 L108.709576,9.45166684 C108.676962,8.72381413 108.676962,7.86373286 108.676962,6.73621635 L108.676962,2.69609775 L113.023256,2.69609775 L113.023256,23.2629996 L108.882493,23.2629996 L108.849878,20.7458919 Z M108.779727,15.2149261 C108.779727,12.5334262 106.965644,10.1813064 104.162675,10.1813064 C101.324631,10.1813064 99.6816171,12.5334262 99.6816171,15.2149261 C99.6816171,17.9964909 101.46001,20.1812402 104.29867,20.1812402 C106.931183,20.1812402 108.779727,18.2287843 108.779727,15.2149261 L108.779727,15.2149261 Z"
            id="Fill-22"
            fill="currentColor"
            mask="url(#mask-8)"
          />
          <polygon
            id="Fill-23"
            fill="currentColor"
            mask="url(#mask-8)"
            points="115.116279 23.6263303 119.302326 23.6263303 119.302326 6.88214426 115.116279 6.88214426"
          />
          <path
            d="M133.723701,20.3214183 C132.596516,21.9971082 130.614713,22.8687081 128.080392,22.8687081 C123.907592,22.8687081 120.348837,20.255114 120.348837,15.094833 C120.348837,10.6042252 123.566729,6.88214426 128.493242,6.88214426 C130.717464,6.88214426 132.596516,7.68683707 133.761233,9.42943401 L133.826452,9.42943401 L133.793227,7.18413011 L138.139535,7.18413011 L138.139535,20.0543929 C138.139535,23.6070966 137.387668,25.6167189 135.982993,27.05733 C134.547555,28.531696 132.426084,29.9054001 128.458171,29.9054001 C124.523483,29.9054001 121.58308,28.0633467 120.761687,27.4919244 L122.572444,24.5100403 C123.635025,25.2466205 126.334855,26.4190007 128.458171,26.4190007 C132.185512,26.4190007 133.793227,24.7113642 133.793227,21.1261111 L133.793227,20.3214183 L133.723701,20.3214183 Z M129.379854,10.2684845 C126.507132,10.2684845 124.796665,12.3468222 124.796665,14.9941711 C124.796665,17.4721427 126.436991,19.5167255 129.312789,19.5167255 C132.46054,19.5167255 134.031954,17.4721427 134.031954,14.9941711 C134.031954,12.648808 132.255653,10.2684845 129.379854,10.2684845 L129.379854,10.2684845 Z"
            id="Fill-24"
            fill="currentColor"
            mask="url(#mask-8)"
          />
          <polygon
            id="Fill-25"
            fill="currentColor"
            mask="url(#mask-8)"
            points="140.232558 23.6263303 144.418605 23.6263303 144.418605 6.88214426 140.232558 6.88214426"
          />
          <path
            d="M152.162815,17.0361572 C152.162815,19.4872203 152.601056,20.0816701 154.116739,20.0816701 C154.991405,20.0816701 155.663898,19.8172055 155.663898,19.8172055 L155.898151,23.0956141 C155.69598,23.1635172 154.417576,23.6263303 152.601056,23.6263303 C149.267642,23.6263303 147.889362,22.1360363 147.889362,17.9629747 L147.889362,10.5454561 L145.465116,10.5454561 L145.465116,7.26704754 L147.889362,7.26704754 L147.889362,2.69609775 L152.162815,2.69609775 L152.162815,7.26704754 L155.930233,7.26704754 L155.930233,10.5454561 L152.162815,10.5454561 L152.162815,17.0361572 Z"
            id="Fill-26"
            fill="currentColor"
            mask="url(#mask-8)"
          />
          <polygon
            id="Fill-27"
            fill="currentColor"
            mask="url(#mask-8)"
            points="175.813953 23.6263303 180 23.6263303 180 2.69609775 175.813953 2.69609775"
          />
        </g>
      </g>
    </g>
  </Svg>
);

export default Logo;
