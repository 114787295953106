import React, { useState, useRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import tw, { styled } from "twin.macro";
import HeroGlyphAnimation from "@utility/glyphs/HeroGlyphAnimation";
import { NAV_HEIGHT_MOBILE, NAV_HEIGHT_DESKTOP } from "@src/common";
import { screen } from "@helpers/media";
import { DynamicGlyphType } from "./types";

const LottieGlyphCycleContainer = styled.div`
  ${tw`absolute block w-full h-full z-30`}
  top:-${NAV_HEIGHT_MOBILE};
  height:calc(100% + ${NAV_HEIGHT_MOBILE});
  @media ${screen.lg} {
    top:-${NAV_HEIGHT_DESKTOP};
    height:calc(100% + ${NAV_HEIGHT_DESKTOP});
  }
`;
const LottieGlyphContainer = styled.div``;

const LottieGlyphCycle = () => {
  const { allStoryblokEntry } = useStaticQuery(
    graphql`
      query {
        allStoryblokEntry(
          filter: {
            field_component: { eq: "hero_glyph_animation" }
            published_at: { ne: null }
          }
        ) {
          edges {
            node {
              full_slug
              content
            }
          }
        }
      }
    `
  );
  type Props = {
    bg_image: {
      filename: string;
    };
    component: string;
    lottie_glyphs: DynamicGlyphType[];
    _uid: string;
  };

  type RefProps = {
    current?: {};
  };

  // Set initial scene randomly, then begin an interval to switch scenes on a regular basis
  const [glyphScene, setGlyphScene] = useState<Props | null>(null);

  const stateRef = useRef<RefProps | null>(null);
  stateRef.current = glyphScene;
  useEffect(() => {
    const randScene = (arr, excludeID) => {
      const randNumber = Math.floor(Math.random() * arr.length);
      const scene = JSON.parse(arr[randNumber].node.content);
      // Keep selecting scene randomly if there is more than 1 array item and the previous scene was selected again
      if (arr.length > 1 && scene._uid === excludeID) {
        return randScene(arr, excludeID);
      }
      return scene;
    };
    const firstScene = randScene(allStoryblokEntry.edges, "0");
    setGlyphScene(firstScene);
    // Select new scene every 15 seconds
    const intervalId = setInterval(() => {
      const newScene = randScene(
        allStoryblokEntry.edges,
        stateRef.current && stateRef?.current?._uid
          ? stateRef?.current?._uid
          : "0"
      );
      setGlyphScene(newScene);
    }, 13000);

    // Clear required for useEffect intervals.
    return () => clearInterval(intervalId);
  }, [allStoryblokEntry]);
  return (
    <LottieGlyphCycleContainer>
      {glyphScene && (
        <LottieGlyphContainer>
          <HeroGlyphAnimation blok={glyphScene} />
        </LottieGlyphContainer>
      )}
    </LottieGlyphCycleContainer>
  );
};

export default LottieGlyphCycle;
