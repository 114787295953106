import React from "react";
import LandingContactForm from "./LandingContactForm";
import LandingSubscribeForm from "./LandingSubscribeForm";

type Props = {
  blok: {
    title: string;
    body: string;
    form_content: string;
    component: string;
    thank_you_top_glyph?: any;
    thank_you_bottom_glyph?: any;
    thank_you_bottom_mobile_glyph?: any;
    subscribe_glyph: string;
    bg_wash_desktop?: boolean;
    bg_wash_mobile?: boolean;
    _editable: string;
    _uid: string;
  };
};

const LandingForm: React.FC<Props> = ({ blok, blok: { form_content } }) => {
  if (form_content === "cm_bikes") {
    return <LandingSubscribeForm blok={blok} />;
  }

  return <LandingContactForm blok={blok} />;
};

export default LandingForm;
