import React from "react";
import SbEditable from "storyblok-react";
import tw, { styled } from "twin.macro";
import RichText from "@utility/RichText";
import Label from "@utility/Label";
import HorizontalLine from "@utility/HorizontalLine";
import { LogoTextType } from "./types";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1380%3A32
 */

type Props = {
  blok: LogoTextType;
};

const ImageContainer = styled.div`
  ${tw`xl:mb-12`}

  img {
    ${tw`block`}
  }
`;

const LogoText: React.FC<Props> = ({
  blok,
  blok: { image, label, description }
}) => {
  return (
    <div className="w-full md:w-1/3 md:px-5 lg:px-10">
      <div className="grid-overflow__full block md:hidden">
        <HorizontalLine />
      </div>
      <div className="pt-8 pb-16 lg:py-20">
        <ImageContainer key={image} className="has-delay">
          <SbEditable content={blok}>
            <img src={image} alt="" />
          </SbEditable>
        </ImageContainer>
        <div className="has-delay">
          {!!label && <Label className="mb-5 text-white">{label}</Label>}
          <RichText className="type-1830" content={description} />
        </div>
      </div>
    </div>
  );
};

export default LogoText;
