import React from "react";
import SearchEngines from "@utility/Seo";
import Components from "@builder/ComponentsPage";

const Page = ({ blok, blok: { seo, title, body }, pageContext }) => {
  return (
    <div>
      <SearchEngines
        title={seo?.title || title || pageContext?.story?.name || ""}
        description={seo?.description || title || ""}
        image={seo?.og_image || ""}
      />
      {blok.body &&
        blok.body.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok
          })
        )}
    </div>
  );
};

export default Page;
