import React from "react";
import tw, { styled } from "twin.macro";
import TagWrapper from "@utility/TagWrapper";

type Props = {
  as?: ElementType;
  className: "is-open" | "is-closed";
  onClick: () => void;
};

const AccordionButtonStyle = styled(TagWrapper)`
  ${tw`rounded-full border border-mono-600 border-solid w-10 h-10 relative block flex-none md:hidden`}
  &:focus {
    ${tw`outline-none`}
  }
  &:after,
  &:before {
    ${tw`absolute bg-mono-600`}
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:after {
    height: 1px;
    width: 50%;
  }

  &:before {
    height: 50%;
    width: 1px;
  }

  &.is-open {
    opacity: 1;
    &:before {
      transition: all 0.5s ease-in-out;
      transform: translate(-50%, -50%) rotate(90deg);
    }
    &:after {
      transition: all 0.5s ease-in-out;
      transform: translate(-50%, -50%) rotate(90deg);
      opacity: 0;
    }
  }

  &.is-closed {
    &:before {
      transition: all 0.5s ease-in-out;
      transform: translate(-50%, -50%) rotate(-90deg);
    }
    &:after {
      transition: all 0.5s ease-in-out;
      transform: translate(-50%, -50%) rotate(-90deg);
      opacity: 1;
    }
  }
`;

const AccordionButton: React.FC<Props> = ({
  as = "button",
  className = "",
  onClick
}) => {
  return (
    <AccordionButtonStyle as={as} className={className} onClick={onClick} />
  );
};

export default AccordionButton;
