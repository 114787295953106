export const STORYBLOK_BASE_URL = "https://a.storyblok.com";
export const VALID_IMAGE_URL_PATTERN = /^(https?:)?\/\/a.storyblok.com\/f\/*[0-9]+\/*[0-9]*x*[0-9]*\/[A-Za-z0-9]+\/[\S]+\.[a-zA-Z]+/;
export const STORYBLOK_REGEX = /^(https?:)?\/\/a.storyblok.com\//;

/**
 *  maxSmaller used to check if the maximum size of an image is smaller than the image itself
 *
 * @param {*} croppedValue
 * @param {*} originalValue
 * @returns Boolean value
 */

export const maxSmaller = (croppedValue, originalValue) => {
  if (!croppedValue) {
    // if no max is specified
    return false;
  }
  if (croppedValue > originalValue) {
    // test if crop is smaller than original otherwise there is no point in cropping
    return false;
  }
  return true;
};

/**
 * urlBuilder passed into gatsbys getImageData to generate a image url, place any global configuration here (ref https://www.storyblok.com/docs/image-service)
 *
 * @param {*} { baseUrl, width, height }
 * @returns String
 */
export const urlBuilder = ({ baseUrl, width, height, format, options }) => {
  let url = `${STORYBLOK_BASE_URL}/${baseUrl}`;

  if (height > 4000) {
    if (options && options.hasOwnProperty("quality")) {
      return `${url}/m/${width}x0/filters:quality(${options.quality})`;
    } else {
      return `${url}/m/${width}x0`;
    }
  }

  if (width && height) {
    if (options && options.hasOwnProperty("quality")) {
      url += `/m/${width}x${height}/filters:quality(${options.quality})`;
    } else {
      url += `/m/${width}x${height}`;
    }
  }

  return `${url}`;
};
