import ComponentNotFound from "@components/ComponentNotFound";
import WorkVideo from "@components/WorkVideo";
import HorizontalText from "@components/HorizontalText";
import MobileThreeUp from "@components/MobileThreeUp";
import OneUp from "@components/OneUp";
import QuoteBlock from "@components/Quote/QuoteBlock";
import FullWidthText from "@components/FullWidthText";
import TwoUp from "@components/TwoUp";

const ComponentList = {
  work_video: WorkVideo,
  quote_block: QuoteBlock,
  one_up: OneUp,
  two_up: TwoUp,
  mobile_three_up: MobileThreeUp,
  text_block: FullWidthText,
  horizontal_text_block: HorizontalText
};

const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
