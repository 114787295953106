import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import Components from "@builder/ComponentsWorkLanding";
import SearchEngines from "@utility/Seo";
import ThemeContext from "@components/ThemeContext";

const WorkPageTemplate = ({
  blok: { seo, title, hero_image, body, learn_more_driver },
  pageContext
}) => {
  const { isDark } = useContext(ThemeContext);
  useEffect(() => {
    isDark(true);
    return () => {
      isDark(false);
    };
  }, [isDark]);
  return (
    <div className="work-page">
      <SearchEngines
        title={seo.title || pageContext.story.name || ""}
        description={seo.description || title || ""}
        image={seo.og_image || hero_image || ""}
      />
      <div>
        {body &&
          body.map((element, index) =>
            React.createElement(Components(element.component), {
              key: element._uid,
              blok: element,
              size: "default",
              index
            })
          )}
      </div>

      <div className="grid-wrapper">
        <div className="type-1830 col-12 md:col-5 md:col-offset-5 pt-12 pb-25 md:pt-25 lg:py-52">
          {learn_more_driver[0] && learn_more_driver[0].label.length > 0 && (
            <h4 className="type-1630-loose">
              <span className="text-mono-400">
                {learn_more_driver[0].label} —
              </span>
            </h4>
          )}
          <ReactMarkdown className="mt-10 type-1830 underline-links">
            {learn_more_driver[0].paragraph}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

WorkPageTemplate.propTypes = {
  blok: PropTypes.shape({
    seo: PropTypes.object,
    title: PropTypes.string,
    hero_image: PropTypes.string,
    component: PropTypes.string,
    body: PropTypes.array,
    learn_more_driver: PropTypes.array
  }).isRequired,
  seo: PropTypes.objectOf(PropTypes.string),
  pageContext: PropTypes.shape({
    story: PropTypes.object
  })
};

WorkPageTemplate.defaultProps = {
  seo: {},
  pageContext: {}
};

export default WorkPageTemplate;
