import BeliefListing from "@components/BeliefListing";
import BenefitsListing from "@components/BenefitsListing";
import EngagementListing from "@components/EngagementListing";
import FeatureDriverListing from "@components/FeatureDriverListing";
import FeaturedPerspectivesListing from "@components/FeaturedPerspectivesListing";
import FeaturedWork from "@components/WorkDriver";
import FeatureWorkDriverListing from "@components/FeatureWorkDriverListing";
import FullWidthText from "@components/FullWidthText";
import Gallery from "@components/Gallery";
import HeroDriver from "@components/HeroDriver";
import HorizontalText from "@components/HorizontalText";
import Intro from "@components/Intro";
import LandingContactForm from "@components/LandingContactForm";
import LandingFeature from "@components/LandingFeature";
import LandingTextBlock from "@components/LandingTextBlock";
import LogoListing from "@components/LogoListing";
import LogoTextListing from "@components/LogoTextListing";
import MissionStatement from "@components/MissionStatement";
import MobileThreeUp from "@components/MobileThreeUp";
import NewsListing from "@components/NewsListing";
import OneUp from "@components/OneUp";
import PerspectivesListing from "@components/RecentPerspectivesListing";
import QuickGuide from "@components/QuickGuide";
import QuoteBlock from "@components/Quote/QuoteBlock";
import Service from "@components/Service";
import SubscribeForm from "@components/SubscribeForm";
import TwoUp from "@components/TwoUp";
import VerticalListing from "@components/VerticalListing";
import WorkVideo from "@components/WorkVideo";
import ComponentNotFound from "@components/ComponentNotFound";
import ListLandingFeatures from "@components/ListLandingFeatures";

const ComponentList = {
  featured_perspectives_listing: FeaturedPerspectivesListing,
  featured_work: FeaturedWork,
  featured_works: FeatureWorkDriverListing,
  gallery_section: Gallery,
  hero_driver: HeroDriver,
  horizontal_text_block: HorizontalText,
  intro: Intro,
  landing_contact_form: LandingContactForm,
  landing_feature: LandingFeature,
  landing_text_block: LandingTextBlock,
  list_beliefs: BeliefListing,
  list_benefits: BenefitsListing,
  list_engagements: EngagementListing,
  list_landing_features: ListLandingFeatures,
  list_feature_drivers: FeatureDriverListing,
  list_verticals: VerticalListing,
  logo_listing: LogoListing,
  logo_text_listing: LogoTextListing,
  mission_statement: MissionStatement,
  mobile_three_up: MobileThreeUp,
  news_listing: NewsListing,
  one_up: OneUp,
  perspectives_listing: PerspectivesListing,
  quick_guide: QuickGuide,
  services_service: Service,
  signup_cta: SubscribeForm,
  quote_block: QuoteBlock,
  text_block: FullWidthText,
  two_up: TwoUp,
  work_video: WorkVideo
  // list_benefits: HowWeCanHelp,
};

const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
