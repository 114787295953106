import React from "react";
import SbEditable from "storyblok-react";
import PropTypes from "prop-types";
import Link from "@utility/Link";
import { NavItemType } from "./types";

type Props = {
  blok: NavItemType;
};

const NavItemSocial: React.FC<Props> = ({
  blok,
  blok: { nav_link, nav_label }
}) => (
  <SbEditable content={blok}>
    <li className="nav-link--social mb-4 lg:mb-0 lg:pl-2 lg:ml-6 list-none line-height[44px]!">
      <Link type="hover" to={`${nav_link.cached_url}`}>
        {nav_label}
      </Link>
    </li>
  </SbEditable>
);

NavItemSocial.propTypes = {
  blok: PropTypes.shape({
    nav_link: PropTypes.any,
    icon: PropTypes.string,
    nav_label: PropTypes.string
  }).isRequired
};

export default NavItemSocial;
