import React, { useState, useRef, useEffect } from "react";
import SbEditable from "storyblok-react";
import tw, { styled } from "twin.macro";
import HorizontalLine from "@utility/HorizontalLine";
import CollapseTitle from "@utility/AccordionTitle";
import CollapseButton from "@utility/AccordionButton";
import CollapseContent from "@utility/AccordionContent";
import useWindowDimensions from "@utility/useWindowDimensions";
import { screen } from "@helpers/media";
import { EngagementBlock } from "@src/types";
import clsxm from "@helpers/clsxm";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1378%3A6178
 */

type Props = {
  blok: EngagementBlock;
  largeText?: boolean;
  isLastBlock: boolean;
};

const BlockContainer = styled.div`
  ${tw`w-full md:mx-5 lg:mx-10`}
  @media ${screen.md} {
    width: calc(33.3333% - 2.5rem);
  }
  @media ${screen.lg} {
    width: calc(33.3333% - 5rem);
  }
`;

const ImageContainer = styled.div`
  ${tw`mb-10 hidden md:block`}

  img {
    ${tw`inline align-baseline`}
  }
`;

const Engagement: React.FC<Props> = ({
  blok,
  largeText = false,
  blok: { icon, title, description },
  isLastBlock = false
}) => {
  const { windowWidth } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [maxHeight, setmaxHeight] = useState<string>("");

  useEffect(() => {
    setmaxHeight(windowWidth > 767 ? "none" : "0px");
  }, [windowWidth]);

  const content = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setmaxHeight(isOpen ? "0px" : `${content.current.scrollHeight}px`);
  };

  return (
    <BlockContainer>
      <SbEditable content={blok}>
        <div className="grid-overflow block md:hidden">
          <div className="grid-overflow">
            <HorizontalLine />
          </div>
        </div>
        <div className="py-6 md:pb-0 md:pt-24">
          <ImageContainer className="has-delay">
            <img src={icon} alt="" role="presentation" />
          </ImageContainer>
          <div className="has-delay">
            <CollapseTitle>
              <h3 className="pr-5 type-1830 md:type-2430 md:pr-0 md:mb-10 md:self-auto">
                {title}
              </h3>
              <CollapseButton
                className={isOpen ? "is-open" : "is-closed"}
                onClick={toggleAccordion}
              />
            </CollapseTitle>
            <CollapseContent
              ref={content}
              style={{ maxHeight: `${maxHeight}` }}
            >
              <p
                className={clsxm(
                  "pt-6 md:pt-0",
                  largeText ? "type-2030" : "type-1830"
                )}
              >
                {description}
              </p>
            </CollapseContent>
          </div>
        </div>
      </SbEditable>
      {isLastBlock && (
        <div className="grid-overflow block md:hidden">
          <div className="grid-overflow">
            <HorizontalLine />
          </div>
        </div>
      )}
    </BlockContainer>
  );
};

export default Engagement;
