import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import PropTypes from "prop-types";
import RichText from "@utility/RichText";
import { screen } from "@helpers/media";
import clsxm from "@helpers/clsxm";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1463%3A6256
 */

const RichTextWrapper = styled.div`
  ${tw`font-sans-rebrand-book`}
`;

const RichTextQuote = styled.div`
  ${tw`mb-4`}
  p {
    &:before {
      ${tw`relative top-0`}
      content: '“';
      margin-right: 4px;
      margin-left: ${props => (props.smallFont ? "-.8rem" : "-.85rem")};
      @media ${screen.md} {
        left: -4px;
        margin-left: ${props => (props.smallFont ? "-.95rem" : "-1.05rem")};
      }
      @media ${screen.lg} {
        margin-left: ${props => (props.smallFont ? "-1.05rem" : "-1.5rem")};
      }
    }
    &:after {
      ${tw`relative top-0`}
      content: '”';
      left: 4px;
    }
  }
`;

const QuoteBlock = ({
  has_background,
  small_font,
  serif_font,
  rich_text_sample,
  rich_text_quote_speaker,
  center_alignment
}) => {
  return (
    <RichTextWrapper
      className={clsxm(
        "col-12 md:col-offset-1 md:col-7 lg:col-offset-2",
        center_alignment && "text-center md:col-8 md:col-offset-2"
      )}
    >
      <RichTextQuote smallFont={small_font}>
        <div
          className={clsxm(
            "inline mb-12 type-2430 md:type-2830 lg:type-4820",
            serif_font &&
              (small_font
                ? "type-serif-2260 md:type-serif-3260 lg:type-serif-3260"
                : "type-serif-3660 md:type-serif-4260 lg:type-serif-6460"),
            !serif_font && small_font && "type-2030 md:type-2430 lg:type-2830"
          )}
        >
          <RichText content={rich_text_sample} />
        </div>
      </RichTextQuote>
      <div className="type-1630-loose md:type-1830">
        <RichText content={rich_text_quote_speaker} />
      </div>
    </RichTextWrapper>
  );
};

QuoteBlock.propTypes = {
  has_background: PropTypes.bool,
  small_font: PropTypes.bool,
  serif_font: PropTypes.bool,
  center_alignment: PropTypes.bool,
  rich_text_sample: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  rich_text_quote_speaker: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]).isRequired
};

QuoteBlock.defaultProps = {
  has_background: false,
  small_font: false,
  serif_font: false,
  center_alignment: false
};

export default QuoteBlock;
