import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import PropTypes from "prop-types";
import AnchorLink from "react-anchor-link-smooth-scroll";
import HeaderType from "@utility/Header";
import AnimateKeyframes from "@utility/AnimateKeyframes";
import AnimateHeroText from "@utility/AnimateHeroText";
import DynamicGlyphs from "@utility/glyphs/DynamicGlyphs";
import TextArea from "@utility/TextArea";
import Image from "@utility/Image";
import Components from "@builder/ComponentsCareers";
import SearchEngines from "@utility/Seo";
import ScrollInView from "@utility/ScrollInView";
import CareerListing from "@components/CareerListing";
import HeaderContainer from "@utility/HeaderContainer";
import HeroInnerContainer from "@utility/HeroInnerContainer";

const JumpNavLink = styled.div`
  ${tw`mx-auto w-auto inline-block relative z-40 mt-12`}
`;

const CareersPageTemplate = ({
  blok,
  blok: {
    seo,
    title,
    mobile_title,
    hero_image,
    hero_image_mobile,
    hero_glyphs,
    body,
    careers_listing_title,
    career_listings
  },
  pageContext
}) => {
  const hasMobileImage = !!(hero_image_mobile && hero_image_mobile !== "");
  return (
    <div>
      <SearchEngines
        title={seo.title || pageContext.story.name || ""}
        description={seo.description || title || ""}
        image={seo.og_image || hero_image || ""}
      />
      <AnimateKeyframes>
        <HeaderContainer>
          <div className="grid-wrapper">
            <div className="grid-row relative z-50">
              <div className="col-12 md:col-10 md:col-offset-1 lg:col-8 lg:col-offset-2">
                <AnimateHeroText>
                  <HeaderType>
                    <TextArea
                      text={title}
                      mobile_text={mobile_title}
                      nestedAnimation
                    />
                  </HeaderType>
                </AnimateHeroText>
              </div>
              <ScrollInView
                delaySelf="0.2"
                className="col-12 relative z-40 md:text-center"
              >
                <JumpNavLink className="has-keyframe-animation" nestedAnimation>
                  <AnchorLink
                    className="link underline-link w-auto text-mono-700 type-1830 relative z-40"
                    offset="100"
                    href="#career_listings"
                  >
                    View open roles
                  </AnchorLink>
                </JumpNavLink>
              </ScrollInView>
            </div>
          </div>
          {hero_glyphs && <DynamicGlyphs isHero glyphs={hero_glyphs} />}
        </HeaderContainer>
        <div className="grid-wrapper">
          <div className="grid-row">
            <div className="col-12">
              <div className="grid-overflow overflow-hidden">
                <ScrollInView delaySelf="1">
                  <HeroInnerContainer>
                    <Image
                      image={hero_image}
                      blok={blok}
                      maxWidth={2640}
                      maxHeight={1632}
                      quality={80}
                      sizes="(max-width: 1440px) 100vw, 1400px"
                      mobileImage={hasMobileImage ? hero_image_mobile : ""}
                      loading="eager"
                    />
                  </HeroInnerContainer>
                </ScrollInView>
              </div>
            </div>
          </div>
        </div>
      </AnimateKeyframes>
      <div>
        {body &&
          body.map(element =>
            React.createElement(Components(element.component), {
              key: element._uid,
              blok: element
            })
          )}
      </div>
      <div id="career_listings" className="grid-wrapper">
        <div className="grid-row mt-24 lg:mt-50 mb-12 lg:mb-24">
          <div className="col-12 lg:col-11 lg:col-offset-1">
            <h3 className="type-1630-loose text-mono-500">
              {careers_listing_title}
            </h3>
          </div>
        </div>
        {career_listings &&
          career_listings.map((element, index) =>
            React.createElement(CareerListing, {
              key: element._uid,
              blok: element,
              isLast: career_listings.length === index + 1
            })
          )}
      </div>
    </div>
  );
};

CareersPageTemplate.propTypes = {
  blok: PropTypes.shape({
    seo: PropTypes.object,
    title: PropTypes.string,
    mobile_title: PropTypes.string,
    hero_image: PropTypes.string,
    hero_image_mobile: PropTypes.string,
    hero_glyphs: PropTypes.array,
    component: PropTypes.string,
    body: PropTypes.array,
    careers_listing_title: PropTypes.string,
    career_listings: PropTypes.array
  }).isRequired,
  seo: PropTypes.objectOf(PropTypes.string),
  pageContext: PropTypes.shape({
    story: PropTypes.object
  })
};

CareersPageTemplate.defaultProps = {
  seo: {},
  pageContext: {}
};

export default CareersPageTemplate;
