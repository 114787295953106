import React, { useCallback, useEffect, useRef, useState } from "react";
import SbEditable from "storyblok-react";
import tw, { css, styled } from "twin.macro";
import { useForm } from "react-hook-form";
import Lottie from "react-lottie-player";
import { yupResolver } from "@hookform/resolvers/yup";
import { REVEAL_DURATION } from "@src/common";
import topJsonGlyph from "@src/lottie/contact-page-b.json";
import bottomJsonGlyph from "@src/lottie/careers-page-2.json";
import bottomMobileJsonGlyph from "@src/lottie/services-page-1b.json";
import { animate, motion, useViewportScroll } from "framer-motion";
import * as yup from "yup";
import Button from "@utility/Button";
import FormInput from "@utility/FormInput";
import FormTextArea from "@utility/FormTextArea";
import FormCheckbox from "@utility/FormCheckbox";
import Label from "@utility/Label";
import ScrollInView from "@utility/ScrollInView";
import clsxm from "@helpers/clsxm";
import gtmPush from "@helpers/gtmPush";
import { screen } from "@helpers/media";

/*
 * Visual Reference
 * https://www.figma.com/file/zODzhJxGWO9syPmmnPEb7z/ED-Website-2022%2F23?node-id=3365%3A31045&t=rqyviShSFYA7E8VX-11
 */
/*
 * Form submission notifications are managed through Zapier
 * using a Netlify to Gmail Integration. Notifications are not directly sent
 * via Email from netlify because some recipient addresses have hard bounces
 * reference - https://answers.netlify.com/t/forms-notifications-are-not-sending-email-notifications/25729/18
 * Form submissions can also be seen through the Netlify Forms Dashboard
 */

type Props = {
  blok: {
    title: string;
    body: string;
    form_content: string;
    component: string;
    thank_you_top_glyph?: any;
    thank_you_bottom_glyph?: any;
    thank_you_bottom_mobile_glyph?: any;
    bg_wash_desktop?: boolean;
    bg_wash_mobile?: boolean;
    _editable: string;
    _uid: string;
  };
};

type FormProps = {
  setIsSubmitted: (isSubmitted: boolean) => void;
};

type FormData = {
  Company: string;
  Email: string;
  JobTitle: string;
  Message: string;
  Name: string;
  status: string;
  Newsletter: boolean;
};

type EncodeData = {
  Company: string;
  Email: string;
  JobTitle: string;
  Message: string;
  Name: string;
  "form-name": string;
};

const FormWrapper = styled.div`
  ${tw`col-12 md:(col-10 col-offset-1) py-16 md:py-32`}
  @media ${screen.xl} {
    display: grid;
    grid-template-columns: 3fr 6fr;
    grid-column-gap: 140px;
  }
`;

const FormContainer = styled.div`
  ${tw`relative`}
  z-index: 61;
`;

const LottieGlyphContainer = styled(Lottie)`
  ${({
    $bottom,
    $hideOnMobile,
    $hideOnDesktop
  }: {
    $bottom?: boolean;
    $hideOnMobile?: boolean;
    $hideOnDesktop?: boolean;
  }) => [
    tw`text-mono-200`,
    $bottom
      ? css`
          height: 3.85rem;
          @media ${screen.lg} {
            height: 23.25rem;
          }
        `
      : css`
          height: 5.25rem;
          @media ${screen.lg} {
            height: 8.75rem;
          }
        `,
    $hideOnMobile && tw`hidden lg:block`,
    $hideOnDesktop && tw`lg:hidden`
  ]}
`;

const getLottieData = async (filename: string, callback: (json) => void) => {
  const isJSON = filename.indexOf(".json") > -1;
  if (!isJSON) {
    return;
  }
  const resp = await fetch(filename);
  const json = await resp.json();

  callback(json);
};

const schema = yup.object().shape({
  Name: yup.string().required("Name is required."),
  Email: yup
    .string()
    .required("Email is required.")
    .email("Please input valid email."),
  Message: yup.string().required("Message is required.")
});

const ContactForm: React.FC<FormProps> = ({ setIsSubmitted }) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  function encode(data: EncodeData) {
    return Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join("&");
  }

  const [state, setState] = React.useState<FormData>();
  const formRef = useRef<HTMLFormElement>(null);

  const handleChange = (
    e: React.FormEvent & {
      target: HTMLFormElement;
    }
  ) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onSubmit = async (_: FormData, e: React.FormEvent) => {
    e.preventDefault();

    gtmPush("Landing", "Form", "Submit");

    const form = e.target as HTMLFormElement;
    fetch(
      "https://n8n.enginedigital.com/webhook/f1300a3f-5de4-46e8-8499-224032a564dd",
      {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...state
        })
      }
    )
      .then(() => setIsSubmitted(true))
      .catch(error => alert(error));
  };
  return (
    <FormContainer id="contact">
      <form
        ref={formRef}
        name="contact"
        method="POST"
        action="/thank-you"
        data-netlify="true"
        netlify-honeypot="status"
        onSubmit={handleSubmit(async (data: FormData, e: React.FormEvent) =>
          onSubmit(data, e)
        )}
        noValidate
      >
        <input type="text" name="status" className="hidden" ref={register} />
        <noscript>
          <p>This form won’t work with Javascript disabled</p>
        </noscript>
        <div className="grid-row">
          <div className="col-12 mb-4 md:col-6">
            <FormInput
              type="text"
              message="Name*"
              name="Name"
              onChange={handleChange}
              ref={register}
              style={{
                borderColor: errors.Name && "#ff0303"
              }}
              errorMessage={errors.Name?.message}
            />
          </div>
          <div className="col-12 mb-4 md:col-6">
            <FormInput
              type="text"
              message="Title"
              name="JobTitle"
              onChange={handleChange}
              ref={register}
            />
          </div>
          <div className="col-12 mb-4">
            <FormInput
              type="text"
              message="Organization"
              name="Company"
              onChange={handleChange}
              ref={register}
            />
          </div>
          <div className="col-12 mb-4">
            <FormInput
              type="email"
              message="Email*"
              name="Email"
              onChange={handleChange}
              ref={register}
              style={{
                borderColor: errors.Email && "#ff0303"
              }}
              errorMessage={errors.Email?.message}
            />
          </div>
          <div className="col-12">
            <FormTextArea
              message="Message*"
              name="Message"
              onChange={handleChange}
              ref={register}
              cols={30}
              rows={3}
              style={{
                borderColor: errors.Message && "#ff0303"
              }}
              errorMessage={errors.Message?.message}
            />
          </div>
          <div className="col-12 mt-4 sm:flex sm:justify-between sm:items-center sm:mt-8">
            <FormCheckbox
              name="Newsletter"
              message="Sign up for our Dispatch newsletter and get monthly insights to your inbox."
              value
              onChange={handleChange}
              className="flex sm:w-1/2"
              ref={register}
            />
            <Button
              button
              label="Send"
              type="submit"
              twStyles={tw`border-neutral-black py-0 mt-6 sm:(mt-0 ml-8) rounded-full`}
            />
          </div>
        </div>
      </form>
    </FormContainer>
  );
};

const LandingContactForm: React.FC<Props> = ({
  blok,
  blok: {
    title,
    body,
    thank_you_top_glyph,
    thank_you_bottom_glyph,
    thank_you_bottom_mobile_glyph,
    bg_wash_desktop = false,
    bg_wash_mobile = false
  }
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [topJsonData, updateTopJsonData] = useState();
  const [bottomJsonData, updateBottomJsonData] = useState();
  const [bottomMobileJsonData, updateBottomMobileJsonData] = useState();
  const [playGlyph, setPlayGlyph] = useState(false);
  const { scrollY } = useViewportScroll();

  useEffect(() => {
    if (!thank_you_top_glyph) {
      return;
    }
    getLottieData(thank_you_top_glyph.filename, updateTopJsonData);
  }, [thank_you_top_glyph]);

  useEffect(() => {
    if (!thank_you_bottom_glyph) {
      return;
    }
    getLottieData(thank_you_bottom_glyph.filename, updateBottomJsonData);
  }, [thank_you_bottom_glyph]);

  useEffect(() => {
    if (!thank_you_bottom_mobile_glyph) {
      return;
    }
    getLottieData(
      thank_you_bottom_mobile_glyph.filename,
      updateBottomMobileJsonData
    );
  }, [thank_you_bottom_mobile_glyph]);

  useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        setPlayGlyph(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isSubmitted]);

  const scrollToThankYouMessage = useCallback(node => {
    if (node) {
      const pos =
        window.scrollY +
        node.getBoundingClientRect().top -
        (window.innerHeight - node.getBoundingClientRect().height) / 2;
      animate(scrollY.current, pos, {
        onUpdate: v => window.scroll(0, v)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderThankYouBlock = () => (
    <div
      ref={scrollToThankYouMessage}
      className={clsxm(
        "h-screen min-h-[20rem] md:min-h-[32rem] max-h-screen w-full",
        bg_wash_mobile && "bg_wash-mobile",
        bg_wash_desktop && "bg_wash-desktop"
      )}
    >
      <ScrollInView delaySelf="0.4" className="h-full relative">
        <div className="absolute top-0 left-0 z-10 pointer-events-none">
          <LottieGlyphContainer
            animationData={topJsonData || topJsonGlyph}
            play={playGlyph}
            loop={false}
          />
        </div>
        <div className="absolute bottom-0 right-0 z-10">
          <LottieGlyphContainer
            animationData={bottomJsonData || bottomJsonGlyph}
            play={playGlyph}
            loop={false}
            $bottom
            $hideOnMobile
          />
          <LottieGlyphContainer
            animationData={bottomMobileJsonData || bottomMobileJsonGlyph}
            play={playGlyph}
            loop={false}
            $bottom
            $hideOnDesktop
          />
        </div>
        <motion.div
          className="grid-wrapper items-center flex flex-col text-center justify-center overflow-hidden"
          initial="initial"
          variants={{
            initial: {
              height: "100%",
              top: 0
            },
            animate: {
              height: 0,
              top: 0,
              transition: {
                duration: REVEAL_DURATION,
                ease: [0.87, 0, 0.13, 1]
              }
            }
          }}
          exit="exit"
          animate={animate}
        >
          <div className="h-full grid-row flex items-center">
            <div className="col-12 md:col-10 md:col-offset-1">
              <div className="text-center text-neutral-black">
                <h3 className="mx-auto max-w-[44rem] type-serif-3660 md:type-serif-5660 font-semibold mb-5 md:mb-10">
                  They say the best way to predict the future is to create it.
                </h3>
                <p className="mx-auto max-w-[28rem] type-1830 md:type-2430">
                  Thank you, your message has been sent and we will be in touch
                  soon.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      </ScrollInView>
    </div>
  );

  if (isSubmitted) {
    return renderThankYouBlock();
  }

  return (
    <div
      className={clsxm(
        "w-full",
        bg_wash_mobile && "bg_wash-mobile",
        bg_wash_desktop && "bg_wash-desktop"
      )}
    >
      <SbEditable content={blok}>
        <ScrollInView>
          <div className="grid-wrapper has-delay-container">
            <div className="grid-row">
              <FormWrapper>
                <div className="mb-10">
                  {title && title !== "" && (
                    <ScrollInView>
                      <Label className="has-delay pb-10">{title}</Label>
                    </ScrollInView>
                  )}
                  {body && body !== "" && (
                    <ScrollInView>
                      <h3 className="type-2830 md:type-3630 text-neutral-black">
                        {body}
                      </h3>
                    </ScrollInView>
                  )}
                </div>
                <ContactForm
                  setIsSubmitted={submitted => {
                    setIsSubmitted(submitted);
                  }}
                />
              </FormWrapper>
            </div>
          </div>
        </ScrollInView>
      </SbEditable>
    </div>
  );
};

export default LandingContactForm;
