import React, { useEffect, useContext } from "react";
import tw, { css } from "twin.macro";
import ReactMarkdown from "react-markdown";
import Components from "@builder/ComponentsWorkPost";
import HeaderType from "@utility/Header";
import HeaderContainer from "@utility/HeaderContainer";
import TextArea from "@utility/TextArea";
import Hero from "@components/Hero";
import HeroInnerContainer from "@utility/HeroInnerContainer";
import SearchEngines from "@utility/Seo";
import ScrollInView from "@utility/ScrollInView";
import AnimateHeroText from "@utility/AnimateHeroText";
import AnimateKeyframes from "@utility/AnimateKeyframes";
import Link from "@utility/Link";
import Label from "@utility/Label";
import HorizontalLine from "@utility/HorizontalLine";
import ThemeContext from "@components/ThemeContext";
import { addSlash } from "@src/common";

const prettyURL = url => {
  const prettyurl = url.replace(/^https?:\/\//i, "");
  return prettyurl;
};

// outside of template (dynamic) we will have non flexible components like title, hero
const WorkPostTemplate = ({
  blok: {
    seo,
    component,
    title,
    mobile_title,
    client_name,
    hero,
    logo,
    url,
    intro_title,
    intro_copy,
    body,
    more_works
  },
  pageContext
}) => {
  const { isDark } = useContext(ThemeContext);

  useEffect(() => {
    isDark(true);
    return () => {
      isDark(false);
    };
  }, [isDark]);

  return (
    <div>
      <SearchEngines
        title={seo.title || pageContext.story.name || ""}
        description={seo.description || title || ""}
        image={seo.og_image || hero[0]?.hero_image || ""}
      />
      <AnimateKeyframes>
        <HeaderContainer componentName={component}>
          <div className="grid-wrapper">
            <div className="grid-row">
              <div className="col-12 lg:col-10 lg:col-offset-1">
                <AnimateHeroText>
                  <HeaderType className="text-white pb-10 md:pb-12 lg:pb-24 xxl:type-serif-8860">
                    <TextArea
                      text={title}
                      mobile_text={mobile_title}
                      nestedAnimation
                    />
                  </HeaderType>
                </AnimateHeroText>
                <div className="fade-in fade-in__2 type-2030 text-white md:text-center md:type-2430 lg:type-2830">
                  <p className="inline">{client_name.name}</p>
                </div>
              </div>
            </div>
          </div>
        </HeaderContainer>
        <div className="bg-neutral-black">
          <HeroInnerContainer>
            <div className="grid-wrapper mb-16 md:mb-24">
              {hero ? (
                <Hero
                  blok={hero[0]}
                  bgWashType="white"
                  className="fade-in fade-in__3"
                />
              ) : (
                ""
              )}
            </div>
          </HeroInnerContainer>
        </div>
        <ScrollInView>
          <div className="grid-wrapper">
            <div className="grid-row pb-20 lg:pb-32">
              <div className="col-12 pb-12 md:pb-0 md:col-4 lg:col-3 lg:col-offset-2">
                {logo ? (
                  <div className="mb-6 md:(mb-20 pt-3)">
                    <img src={logo.filename} alt={logo.alt} />
                  </div>
                ) : (
                  ""
                )}
                {url.url ? (
                  <Link
                    type="underline"
                    className="type-1630-loose text-mono-600"
                    to={addSlash(url.url)}
                  >
                    {prettyURL(url.url)}
                  </Link>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 md:col-8 lg:col-5">
                <h2 className="mb-8 type-2430 md:mb-10 lg:type-2830">
                  {intro_title}
                </h2>
                <ReactMarkdown
                  css={[
                    css`
                      ${tw`mb-0 type-1830`}
                      p {
                        ${tw`mb-5`}
                      }
                    `
                  ]}
                  escapeHtml={false}
                >
                  {intro_copy}
                </ReactMarkdown>
              </div>
            </div>
            {body.length > 0 && (
              <div className="mb-28 lg:mb-50">
                {body.map(blok =>
                  React.createElement(Components(blok.component), {
                    key: blok._uid,
                    blok
                  })
                )}
              </div>
            )}
          </div>
        </ScrollInView>
        <HorizontalLine />
        <div className="grid-wrapper">
          <ScrollInView>
            <div className="grid-row py-28 lg:py-50">
              <div className="col-12 md:col-offset-3 md:col-9 lg:col-offset-4 lg:col-6 xl:col-offset-5 xl:col-5">
                <Label className="mb-8 type-1630-loose">View more work</Label>
                <div className="type-1830 md:type-2430">
                  {/* slug is resolved relations and driver_label is on work_post itself */}
                  {more_works &&
                    more_works.map((work, index) => (
                      <div className="block mb-4" key={`more-work-${index}`}>
                        <Link
                          type="hover"
                          className="inline text-mono-700"
                          to={`/${work.full_slug}/`}
                        >
                          {work.content.driver_label}
                          &nbsp;—
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </ScrollInView>
        </div>
      </AnimateKeyframes>
    </div>
  );
};

export default WorkPostTemplate;
