import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import tw, { css, styled } from "twin.macro";
import GlyphLottie from "@utility/glyphs/GlyphLottie";
import { REVEAL_DURATION_MS } from "@src/common";
import { DynamicGlyphType } from "@src/types";

type GlyphBackgroundType = {
  bgImage: string;
};
const GlyphBackground = styled.div(({ bgImage }: GlyphBackgroundType) => [
  css`
  ${tw`absolute right-0 top-0 left-0 bottom-0 bg-cover bg-no-repeat opacity-0`}
  background-image: url(${bgImage});
  background-position: center bottom;
  transition: opacity ${REVEAL_DURATION_MS}ms;
  &.fade-appear,
  &.fade-enter {
    ${tw`opacity-0`}
  }
  &.fade-enter-active,
  &.fade-appear-active {
    ${tw`opacity-100 delay-1000 duration-1000 transition-opacity`}
  }
  &.fade-appear-done,
  &.fade-enter-done,
  &.fade-exit {
    ${tw`opacity-100`}
  }
  &.fade-exit-active {
    ${tw`opacity-0 transition-opacity duration-1000`}
  }
`
]);

const getRandomKey = () => {
  return new Date().getTime() / 1000;
};

type Props = {
  blok: {
    lottie_glyphs: DynamicGlyphType[];
    bg_image: {
      filename: string;
    };
  };
};

const HeroGlyphAnimation: React.FC<Props> = ({
  blok: { bg_image, lottie_glyphs }
}) => {
  const [delayOver, setDelayOver] = useState<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayOver(true);
    }, REVEAL_DURATION_MS);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div tw="absolute z-30 right-0 top-0 left-0 bottom-0">
      <TransitionGroup>
        <CSSTransition
          key={Date.now()}
          timeout={{
            appear: 1000,
            enter: 2000,
            exit: 1000
          }}
          classNames="fade"
        >
          <GlyphBackground bgImage={bg_image.filename} key={getRandomKey()} />
        </CSSTransition>
        {lottie_glyphs &&
          delayOver &&
          lottie_glyphs.map(glyph => {
            return (
              <CSSTransition
                key={glyph._uid}
                timeout={REVEAL_DURATION_MS}
                classNames="fade"
              >
                <GlyphLottie key={glyph._uid} blok={glyph} isHomepage />
              </CSSTransition>
            );
          })}
      </TransitionGroup>
    </div>
  );
};

export default HeroGlyphAnimation;
