import { getImageData } from "gatsby-plugin-image";
import {
  VALID_IMAGE_URL_PATTERN,
  STORYBLOK_REGEX,
  maxSmaller,
  urlBuilder
} from "./storyblokImage";

/**
 * Custom wrapper to integrate Storyblok with gatsby-plugin image
 *
 * @param {*} { image, maxWidth, maxHeight, ...props }
 * @returns { image, aspectRatio } image to be passed into gatsbyImage compontent from 'gatsby-plugin-image', aspect ratio to create mobile frame (see https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#withartdirection)
 */

function getGatsbyMobileImageData({ image, maxWidth, maxHeight, ...props }) {
  let url = null;

  if (typeof image === "string") {
    url = image;
  }

  url = VALID_IMAGE_URL_PATTERN.test(url) ? url : null;

  if (!url) {
    return false;
  }

  let originalPath = url.replace(STORYBLOK_REGEX, "");

  let [, , dimensions, , filename] = originalPath.split("/");
  let [width, height] = dimensions.split("x").map(num => parseInt(num, 10));
  let aspectRatio = height / width;

  let cropped = {
    width: null,
    height: null
  };

  if (aspectRatio > 1) {
    // portrait image, testing height
    if (maxSmaller(maxHeight, height)) {
      cropped.height = maxHeight;
      cropped.width = Math.floor(maxHeight / aspectRatio);
    }
  } else {
    // landscape or square image, testing width
    if (maxSmaller(maxWidth, width)) {
      cropped.width = maxWidth;
      cropped.height = Math.floor(maxWidth * aspectRatio);
    }
  }

  //https://www.gatsbyjs.com/docs/how-to/plugins-and-themes/adding-gatsby-image-support/

  return {
    image: getImageData({
      baseUrl: originalPath,
      sourceWidth: cropped.width ?? width,
      sourceHeight: cropped.height ?? height,
      urlBuilder,
      pluginName: "ed-storyblok-image",
      formats: ["auto"],
      breakpoints: [
        368,
        654,
        768,
        1024,
        1400,
        1600,
        1920,
        2048,
        2560,
        3440,
        3840,
        4096
      ],
      ...props
    }),
    aspectRatio
  };
}

/**
 * Simple wrapper for getGatsbyMobileImageData that returns just the image object
 *
 * @param {*} { image, maxWidth, maxHeight, ...props }
 * @returns Image object to be consumed by gatsby-plugin-image
 */
function getGatsbyImageData(props) {
  let { image } = getGatsbyMobileImageData(props);
  return image;
}

export { getGatsbyImageData, getGatsbyMobileImageData };
