import React from "react";
import tw, { styled, css } from "twin.macro";
import Image from "@utility/Image";
import { NavAssetLayoutType } from "./types";

type Props = {
  assets: NavAssetLayoutType;
};
const layoutData = {
  "1": {
    "0": {
      w: 340 * 2,
      h: 220 * 2,
      styleW: "39.7",
      styleH: "24",
      c: "col-offset-5 col-7"
    },
    "1": {
      w: 220 * 2,
      h: 300 * 2,
      styleW: "24",
      styleH: "35",
      c: "col-offset-8 col-4"
    },
    "2": {
      w: 340 * 2,
      h: 244 * 2,
      styleW: "39.7",
      styleH: "28.5",
      c: "col-offset-6 col-6"
    }
  },
  "2": {
    "0": {
      w: 220 * 2,
      h: 200 * 2,
      styleW: "24",
      styleH: "23.4",
      c: "col-offset-7 col-5"
    },
    "1": {
      w: 340 * 2,
      h: 300 * 2,
      styleW: "39.7",
      styleH: "35",
      c: "col-offset-8 col-4"
    },
    "2": {
      w: 220 * 2,
      h: 244 * 2,
      styleW: "24",
      styleH: "28.5",
      c: "col-offset-6 col-6"
    }
  },
  "3": {
    "0": {
      w: 340 * 2,
      h: 380 * 2,
      styleW: "30",
      styleH: "44.4",
      c: "col-offset-8 col-4"
    },
    "1": {
      w: 340 * 2,
      h: 240 * 2,
      styleW: "39.7",
      styleH: "28",
      c: "col-offset-7 col-5"
    }
  },
  "4": {
    "0": {
      w: 220 * 2,
      h: 260 * 2,
      styleW: "24",
      styleH: "28.4",
      c: "col-offset-8 col-4"
    },
    "1": {
      w: 220 * 2,
      h: 220 * 2,
      styleW: "24",
      styleH: "24",
      c: "col-offset-9 col-3"
    },
    "2": {
      w: 460 * 2,
      h: 224 * 2,
      styleW: "50",
      styleH: "24.4",
      c: "col-offset-6 col-6"
    }
  },
  "5": {
    "0": {
      w: 460 * 2,
      h: 180 * 2,
      styleW: "59.6",
      styleH: "23.4",
      c: "col-offset-5 col-7"
    },
    "1": {
      w: 340 * 2,
      h: 360 * 2,
      styleW: "44",
      styleH: "46.7",
      c: "col-offset-8 col-4"
    }
  }
};

const ImageWrapper = styled.div`
  ${tw`w-full flex flex-col h-screen justify-between py-8`}
`;

const ImageRow = styled.div`
  ${tw`opacity-0`}
  will-change: opacity;
  transition: opacity 0.3s ease-out;
  .has-menu-opened & {
    transition: none;
    ${({ index }) =>
      css`
        animation: fadeIn 1s ${0.4 + index * 0.15}s
          cubic-bezier(0.77, 0.17, 0.33, 0.95) forwards;
      `}
  }
`;
const StyledImage = styled(Image)`
  ${({ $styleW, $styleH, maxWidth, maxHeight }) =>
    css`
      margin: 10px 0;
      height: calc(${$styleH}vh - 20px);
      width: calc(${$styleW}vh - 20px);
      max-height: calc(${maxHeight / 2}px);
      max-width: calc(${maxWidth / 2}px);
    `}
`;
const NavImageSet: React.FC<Props> = ({ assets: { images, layout_type } }) => {
  return (
    <ImageWrapper>
      {images &&
        images.map((element, index) => (
          <div className="flex" key={index}>
            <ImageRow
              index={index}
              className={layoutData[layout_type][index].c}
            >
              <StyledImage
                keystring={element._uid}
                blok={element.image}
                image={element.image}
                $styleW={layoutData[layout_type][index].styleW}
                $styleH={layoutData[layout_type][index].styleH}
                maxWidth={layoutData[layout_type][index].w}
                maxHeight={layoutData[layout_type][index].h}
                sizes={`(max-width: 1440px) calc(${
                  layoutData[layout_type][index].styleW
                }vh - 20px), ${layoutData[layout_type][index].w / 2}px`}
                quality={80}
              />
            </ImageRow>
          </div>
        ))}
    </ImageWrapper>
  );
};

export default NavImageSet;
