import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const HorizontalLineStyle = styled.hr`
  ${tw`relative w-full bg-mono-300 m-0 border-0`};
  height: 1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  .is-dark-content & {
    background-color: #222222;
  }
`;

const HorizontalLine = () => <HorizontalLineStyle />;

export default HorizontalLine;
