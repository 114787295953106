import React from "react";
import SbEditable from "storyblok-react";
import tw, { styled } from "twin.macro";
import Label from "@utility/Label";
import RichText from "@utility/RichText";
import Link from "@utility/Link";
import ScrollInView from "@utility/ScrollInView";
import HorizontalLine from "@utility/HorizontalLine";
import RteBlock from "@utility/RichTextEditorStyles";
import { LinkType } from "@src/types";
import clsxm from "@helpers/clsxm";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1380%3A10
 */

type Props = {
  blok: {
    title: string;
    description: any;
    career_postings: {
      _uid: string;
      link_title: string;
      link: LinkType;
      posting_location: string;
      job_level: string;
    }[];
    component: string;
    _uid: string;
  };
  isLast: boolean;
};

const CareerListingRow = styled.div`
  ${tw`mb-12 md:mb-20 md:border-mono-300 md:border-b lg:border-b-0!`}
  &.is-last {
    ${tw`mb-24 lg:mb-40`}
    .career-listing__details {
      border-bottom: none;
    }
  }
`;

const CareerListing: React.FC<Props> = ({
  blok,
  isLast = false,
  blok: { title = "", description = "", career_postings }
}) => {
  return (
    <SbEditable content={blok}>
      <ScrollInView>
        <CareerListingRow className={clsxm(isLast && "is-last")}>
          <div className="grid-row w-full">
            <div
              className={clsxm(
                "col-12 md:col-offset-1 md:col-10 lg:col-2 xl:col-3",
                !title && "hidden lg:flex"
              )}
            >
              <Label className="mb-6 md:mt-2" showLabel={false} largeText>
                {title}
              </Label>
            </div>
            <div className="career-listing__details col-12 md:col-offset-1 md:col-10 lg:mr-auto lg:col-offset-0 lg:col-8 xl:col-5">
              <div
                className={clsxm(
                  "pb-4 md:pb-10 lg:border-b lg:border-mono-300",
                  isLast && "border-b-0"
                )}
              >
                {!!description && (
                  <div
                    className={clsxm(
                      "grid-row",
                      career_postings.length && "mb-12 lg:mb-16"
                    )}
                  >
                    <div className="col-12">
                      <RteBlock>
                        <RichText
                          className="type-1830 md:type-2030 lg:mt-3"
                          content={description}
                        />
                      </RteBlock>
                    </div>
                  </div>
                )}
                {career_postings &&
                  career_postings.map((posting, index) => {
                    return (
                      <div key={`link${index}`} className="grid-row my-10">
                        <div className="col-12">
                          <p className="type-1630-loose text-mono-500">
                            <Link to={posting.link.url} type="underline">
                              {posting.link_title}
                            </Link>
                            {posting.job_level && posting.job_level.length > 0 && (
                              <span>
                                &ensp;–&ensp;
                                {posting.job_level}
                              </span>
                            )}
                            {posting.posting_location &&
                              posting.posting_location.length > 0 && (
                                <span className="block md:inline-block">
                                  <span className="hidden md:inline-block">
                                    &ensp;–&ensp;
                                  </span>
                                  {posting.posting_location}
                                </span>
                              )}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            {!isLast && (
              <div className="grid-overflow block md:hidden">
                <div className="grid-overflow">
                  <HorizontalLine />
                </div>
              </div>
            )}
          </div>
        </CareerListingRow>
      </ScrollInView>
    </SbEditable>
  );
};

export default CareerListing;
