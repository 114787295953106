import React from "react";
import PropTypes from "prop-types";

const IntroLayout: React.FC = ({ children }) => (
  <div className="intro-layout-text underline-links">{children}</div>
);

IntroLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default IntroLayout;
