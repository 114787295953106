import React from "react";
import SbEditable from "storyblok-react";
import ReactMarkdown from "react-markdown";
import tw, { styled } from "twin.macro";
import ScrollInView from "@utility/ScrollInView";
import Glyph4Up from "@utility/glyphs/GlyphFourUp";
import { screen } from "@helpers/media";
import { generateDynamicColOffset } from "@helpers/generateDynamicClasses";
import { ColOffset } from "@src/types";
import clsxm from "@helpers/clsxm";
import IntroLayout from "./IntroLayout";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1460%3A6
 */

type Props = {
  blok: {
    text: string;
    column_offset: ColOffset;
    show_glyph: boolean;
    animate_in: boolean;
    bg_wash_desktop: boolean;
    bg_wash_mobile: boolean;
    component: string;
    _uid: string;
  };
};

const Glyph4UpContainerStyle = styled.div`
  ${tw`absolute text-mono-100`}
  bottom: -9vw;
  left: 0;
  width: 36vw;
  z-index: 1;
  @media ${screen.lg} {
    width: 360px;
    bottom: -90px;
  }
  @media ${screen.xl} {
    width: 400px;
    bottom: -107px;
  }
  .js-svg {
    fill: currentColor;
  }
`;

const renderGlyph = (show_glyph: boolean) => {
  if (show_glyph) {
    return (
      <Glyph4UpContainerStyle>
        <Glyph4Up />
      </Glyph4UpContainerStyle>
    );
  }
};
const components = {
  paragraph: ({ children }) => (
    <ScrollInView delaySelf="0.3" as="p">
      {children}
    </ScrollInView>
  ),
  li: ({ children }) => (
    <ScrollInView delaySelf="0.3" as="ul">
      {children}
    </ScrollInView>
  ),
  heading: ({ children, level }) => (
    <ScrollInView delaySelf="0.3" as={`h${level}`}>
      {children}
    </ScrollInView>
  )
};

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const Intro: React.FC<Props> = ({
  blok,
  blok: {
    text,
    column_offset = "2",
    show_glyph = false,
    animate_in = false,
    bg_wash_desktop,
    bg_wash_mobile
  }
}) => {
  return (
    <ConditionalWrapper
      condition={animate_in}
      wrapper={children => (
        <ScrollInView animateType="-fade" delaySelf="1">
          {children}
        </ScrollInView>
      )}
    >
      <div
        className={clsxm(
          "w-full",
          bg_wash_mobile && "bg_wash-mobile",
          bg_wash_desktop && "bg_wash-desktop"
        )}
      >
        <SbEditable content={blok}>
          <div className="grid-wrapper">
            <div className="grid-row">
              <div
                className="col-12 md:col-8 lg:col-6"
                css={[
                  generateDynamicColOffset("md:col-offset-", column_offset)
                ]}
              >
                <IntroLayout>
                  <ReactMarkdown
                    components={components}
                    className="text-mono-700"
                  >
                    {text}
                  </ReactMarkdown>
                </IntroLayout>
              </div>
            </div>
            {renderGlyph(show_glyph)}
          </div>
        </SbEditable>
      </div>
    </ConditionalWrapper>
  );
};

export default Intro;
