const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";
const siteUrl = process.env.URL || `https://www.enginedigital.com`;
const indexSite = process.env.GATSBY_INDEX_SITE || "index";

console.log(`Using environment config: '${activeEnv}', ${indexSite}`);

module.exports = {
  siteMetadata: {
    title: `Engine Digital - Digital Agency - New York, Vancouver, and Toronto`,
    description: `Engine Digital is a full service digital consultancy and design studio based in New York, Vancouver, and Toronto. We help our clients build the digital products and experiences that invent their future.`,
    author: `@EngineDigital`,
    siteUrl,
    image:
      "https://a.storyblok.com/f/65160/2236x1200/1d79bb8cc6/share-image.jpeg"
  },
  plugins: [
    {
      resolve: `gatsby-plugin-typescript`,
      options: {
        isTSX: true,
        jsxPragma: `React`,
        allExtensions: true
      }
    },
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-styled-components`,
    `gatsby-plugin-image`,
    `gatsby-plugin-react-head`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`
      }
    },
    {
      resolve: "gatsby-plugin-robots-txt",
      options: {
        host: siteUrl,
        sitemap: `${siteUrl}/sitemap/sitemap-index.xml`,
        policy:
          indexSite === "noindex"
            ? [{ userAgent: "*", disallow: ["/"] }]
            : [
                {
                  userAgent: "*",
                  disallow: [
                    "/404",
                    "/404.html",
                    "/confirmation",
                    "/editor",
                    "/thank-you",
                    "/authors/",
                    "/clients/",
                    "/hero-glpyh-animations/",
                    "/shoplocal/"
                  ],
                  allow: "/"
                }
              ]
      }
    },
    `gatsby-transformer-remark`,
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    `gatsby-plugin-styled-components`,
    `gatsby-plugin-force-trailing-slashes`,
    `gatsby-plugin-catch-links`,
    `gatsby-plugin-postcss`,
    {
      resolve: "gatsby-plugin-netlify",
      options: {
        headers: {
          "/*": [
            "Content-Security-Policy: frame-ancestors 'self' https://*.storyblok.com/ https://*.vimeo.com/ https://www.youtube.com/",
            "X-Frame-Options: ALLOW-FROM https://app.storyblok.com/",
            "Strict-Transport-Security: max-age=31536000",
            "X-Content-Type-Options: no-sniff",
            "X-XSS-Protection: 1; mode=block",
            "Referrer-Policy: strict-origin-when-cross-origin"
          ]
        },
        mergeSecurityHeaders: false
      }
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Engine Digital`,
        short_name: `Engine Digital`,
        start_url: `/`,
        background_color: `#222222`,
        theme_color: `#222222`,
        display: `minimal-ui`,
        icon: `src/images/glyph-icon.jpg` // This path is relative to the root of the site.
      }
    },
    // Storyblok plugin to connect to cms
    // Deploy only published content, when the NODE_ENV is set to production
    // we’ll use the published version and otherwise the draft version.
    // https://www.storyblok.com/tp/using-relationship-resolving-to-include-other-content-entries
    // component.field_name
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: "qlqcXyCabEoAr3XBHrH0Lgtt",
        homeSlug: "home",
        version: activeEnv === "production" ? "published" : "draft",
        resolveRelations: [
          "perspective_post.author",
          "work_post.client_name",
          "work_post.more_works",
          "news_listing.news_items",
          "featured_perspectives_listing.perspective_posts",
          "navigation",
          "footer"
        ]
      }
    },
    {
      resolve: "gatsby-plugin-alias-imports",
      options: {
        alias: {
          "@src": "src",
          "@components": "src/components",
          "@builder": "src/builder",
          "@helpers": "src/helpers",
          "@utility": "src/utility",
          "@pages": "src/pages",
          "@styles": "src/styles",
          "@templates": "src/templates"
        },
        extensions: [".js", ".ts", ".tsx", ".css", ".json"]
      }
    },
    {
      resolve: `gatsby-plugin-leadfeeder`,
      options: {
        key: "v1_X0n7aEZ47w8gNpPG"
      }
    },
    {
      resolve: `gatsby-plugin-google-tagmanager`,
      options: {
        id: "GTM-N7NMP8",
        includeInDevelopment: false
      }
    },
    {
      resolve: "gatsby-plugin-sitemap",
      options: {
        resolveSiteUrl: () => siteUrl,
        excludes: ["/editor/", "/confirmation", "/thank-you/"]
      }
    },
    {
      resolve: `gatsby-plugin-purgecss`,
      options: {
        printRejected: false,
        develop: false,
        tailwind: true,
        // printAll: true,
        purgeCSSOptions: {
          safelist: [
            /^js-/,
            /^is-/,
            /^has-/,
            /^menu-/,
            /fade/,
            /grid/,
            /^transition/,
            /enter/,
            /active/,
            /exit/,
            /col/,
            /^swiper/,
            /iframe/
          ],
        },
        ignore: [
          "/tailwind-custom-plugins/typography.js",
          "/tailwind-custom-plugins/tailwind-flexbox-grid.js",
          "/src/components/Layout.js",
          "/src/styles/swiper.css"
        ]
      }
    }
  ]
};
