import React, { useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";
import ThemeContext from "@components/ThemeContext";
import PropTypes from "prop-types";
import CookieFooter from "@components/CookieFooter";
import AnimateKeyframes from "@utility/AnimateKeyframes";
import AnimateHeroText from "@utility/AnimateHeroText";
import DynamicGlyphs from "@utility/glyphs/DynamicGlyphs";
import TextArea from "@utility/TextArea";
import HeroInnerContainer from "@utility/HeroInnerContainer";
import Components from "@builder/ComponentsLanding";
import SearchEngines from "@utility/Seo";
import LandingHero from "@src/components/LandingHero";
import { screen } from "@helpers/media";
import { NAV_HEIGHT_MOBILE, NAV_HEIGHT_DESKTOP } from "@src/common";

const LandingPageTemplate = ({
  blok: { seo, hero_glyphs, landing_hero, body },
  pageContext
}) => {
  const { isDark } = useContext(ThemeContext);
  useEffect(() => {
    isDark(true);
    return () => {
      isDark(false);
    };
  }, [isDark]);

  const HeroContainer = styled.div`
  ${tw`relative flex items-center`}
	min-height:calc(470px - ${NAV_HEIGHT_MOBILE});
	@media ${screen.lg} {
		min-height:calc(600px - ${NAV_HEIGHT_DESKTOP});
    ${props =>
      props.tall &&
      css`
        height: calc(100vh - ${NAV_HEIGHT_DESKTOP} - 50px);
        max-height: calc(840px - ${NAV_HEIGHT_DESKTOP});
      `}
	}
  .is-dark & {
    ${tw`bg-neutral-black`}
  }
`;

  return (
    <div>
      <SearchEngines
        title={seo.title || pageContext.story.name || ""}
        description={seo.description || ""}
        image={seo.og_image || ""}
      />
      <AnimateKeyframes>
        <HeroContainer>
          {hero_glyphs && (
            <DynamicGlyphs
              isHero
              glyphs={hero_glyphs}
              className="landing-glyphs"
            />
          )}
          {landing_hero && !!landing_hero.length && (
            <LandingHero blok={landing_hero[0]} />
          )}
        </HeroContainer>
      </AnimateKeyframes>
      <div>
        {body &&
          body.map(element =>
            React.createElement(Components(element.component), {
              key: element._uid,
              blok: element
            })
          )}
      </div>
      <CookieFooter bg_dark={isDark} />
    </div>
  );
};

LandingPageTemplate.propTypes = {
  blok: PropTypes.shape({
    seo: PropTypes.object,
    title: PropTypes.string,
    hero_label: PropTypes.string,
    mobile_title: PropTypes.string,
    description: PropTypes.string,
    hero_image: PropTypes.string,
    image: PropTypes.string,
    component: PropTypes.string,
    hero_glyphs: PropTypes.array,
    body: PropTypes.array,
    use_landing_hero: PropTypes.bool,
    landing_hero_image: PropTypes.string,
    landing_hero_title: PropTypes.string,
    landing_hero_cta_label: PropTypes.string,
    landing_hero_link: PropTypes.string
  }).isRequired,
  seo: PropTypes.objectOf(PropTypes.string),
  pageContext: PropTypes.shape({
    story: PropTypes.object
  })
};

LandingPageTemplate.defaultProps = {
  seo: {},
  pageContext: {}
};

export default LandingPageTemplate;
