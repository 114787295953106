import React from "react";
import SbEditable from "storyblok-react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import tw from "twin.macro";
import { widowFixer } from "@src/common";
import { WorkConfigType } from "@src/types";
import ScrollInView from "@utility/ScrollInView";
import clsxm from "@src/helpers/clsxm";
import { generateDynamicViewport } from "@helpers/generateDynamicClasses";
import { screen } from "@helpers/media";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1463%3A6178
 */

type Props = {
  blok: {
    title: string;
    text: string;
    font_type: string;
    work_config: WorkConfigType;
    component: string;
    _editable: string;
    _uid: string;
  };
};

const TitleStyle = styled.h2`
  ${tw`text-mono-700 font-light mb-4 lg:mb-0`}
`;

const MarkdownStyle = styled(ReactMarkdown)`
  p {
    ${tw`text-mono-700 mb-6 lg:mb-8`}
    &:last-of-type {
      ${tw`mb-0`}
    }
  }
  a {
    ${tw`text-mono-700`}
  }
`;

const outputFont = font_type => {
  let classes = "";
  if (font_type === "gibson_light") {
    classes = "type-3630 font-extralight";
  } else if (font_type === "gibson_book") {
    classes = "type-3630 font-light";
  } else if (font_type === "gibson_regular") {
    classes = "type-3630 font-medium";
  } else if (font_type === "gibson_medium") {
    classes = "type-3630 font-bold";
  } else {
    classes = "type-1830";
  }
  return classes;
};

const LandingHorizontalText: React.FC<Props> = ({
  blok,
  blok: { title, text, font_type, work_config }
}) => {
  const ContainerStyle = styled.div`
    margin-top: ${work_config.padding_top_mobile}px;
    margin-bottom: ${work_config.padding_bottom_mobile}px;
    @media ${screen.md} {
      margin-top: ${work_config.padding_top_desktop}px;
      margin-bottom: ${work_config.padding_bottom_desktop}px;
    }
  `;

  return (
    <SbEditable content={blok}>
      <ContainerStyle
        className={clsxm(
          "w-full",
          generateDynamicViewport(work_config.viewport_display),
          work_config.bg_wash_desktop && "bg-mono-100",
          work_config.bg_wash_mobile && "bg-mono-100"
        )}
      >
        <ScrollInView>
          <div className="grid-wrapper grid grid-cols-6 gap-x-2.5 gap-y-12 md:grid-cols-12 md:gap-x-5">
            <div className="col-span-full md:col-span-3 md:col-start-2">
              <TitleStyle className={`type-2830 ${outputFont(font_type)}`}>
                {widowFixer(title)}
              </TitleStyle>
            </div>
            <div className="col-span-full md:col-span-5 md:col-start-6">
              <MarkdownStyle
                className={`${outputFont(font_type)} underline-links`}
              >
                {text}
              </MarkdownStyle>
            </div>
          </div>
        </ScrollInView>
      </ContainerStyle>
    </SbEditable>
  );
};

export default LandingHorizontalText;
