import React from "react";
import tw, { styled } from "twin.macro";
import { screen } from "@helpers/media";

type Props = {
  className?: string;
  children: React.ReactNode;
};

const MobileWrap = styled.div`
  ${tw`px-8 sm:px-4 md:px-2 lg:px-4 xl:px-8`}
  &.three-up,
  &.three-up--carousel {
    padding-left: 16.6666667%;
    padding-right: 16.6666667%;
    @media ${screen.md} {
      padding-left: 13.35%;
      padding-right: 13.35%;
    }
    @media ${screen.lg} {
      padding-left: 18.22%;
      padding-right: 18.22%;
    }
    .gatsby-image-wrapper-constrained {
      ${tw`block`}
      img {
        ${tw`rounded-2xl`}
      }
    }
  }
`;
const MobileThreeUpWrapper: React.FC<Props> = ({
  className = "",
  children
}) => {
  return <MobileWrap className={className}>{children}</MobileWrap>;
};

export default MobileThreeUpWrapper;
