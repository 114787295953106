import React from "react";
import SbEditable from "storyblok-react";
import { useStaticQuery, graphql } from "gatsby";
import CardPerspective from "@utility/CardPerspective";
import Label from "@utility/Label";
import ScrollInView from "@utility/ScrollInView";
import { FeaturedPerspectives } from "@src/types";
import clsxm from "@helpers/clsxm";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1421%3A6183
 */

type Props = {
  blok: {
    title?: string;
    label?: string;
    perspective_posts: FeaturedPerspectives[];
    bg_wash_desktop: boolean;
    bg_wash_mobile: boolean;
    component: string;
    _uid: string;
  };
};

type Author = {
  node: {
    uuid: string;
    name: string;
  };
};

const FeaturedPerspectivesListing: React.FC<Props> = ({
  blok,
  blok: { perspective_posts, label, title, bg_wash_desktop, bg_wash_mobile }
}) => {
  // Use useStaticQuery is required here because this is technically a component within
  // the page storyblokEntry
  const data = useStaticQuery(
    graphql`
      query {
        allStoryblokEntry(
          filter: {
            field_component: { eq: "authors" }
            published_at: { ne: null }
          }
        ) {
          edges {
            node {
              uuid
              name
            }
          }
        }
      }
    `
  );
  return (
    <div
      className={clsxm(
        bg_wash_mobile && "bg_wash-mobile",
        bg_wash_desktop && "bg_wash-desktop"
      )}
    >
      <SbEditable content={blok}>
        <div className="grid-wrapper">
          <div className="pb-12 pt-24 sm:pb-24">
            {!!label && (
              <div className="grid-row">
                <div className="col-10 xl:col-offset-1 lg:col-6">
                  <ScrollInView>
                    <Label className="mb-10 md:mb-12">{label}</Label>
                  </ScrollInView>
                </div>
              </div>
            )}
            {!!title && (
              <div className="grid-row">
                <div className="col-10 xl:col-offset-1 xl:col-6">
                  <ScrollInView>
                    <h3 className="type-serif-4260 md:type-serif-6460 mb-8 text-neutral-black">
                      {title}
                    </h3>
                  </ScrollInView>
                </div>
              </div>
            )}
            <div className="grid-row">
              <div className="col-12 xl:col-offset-1 xl:col-10">
                <ScrollInView delayChildren="0.5">
                  <div className="has-delay-container flex flex-col -mb-3 sm:flex-row sm:flex-wrap sm:-mx-6 sm:-mb-16">
                    {perspective_posts.map((post, index) => {
                      let authorObj: Author = { node: { name: "", uuid: "" } };
                      for (const edge of data.allStoryblokEntry.edges) {
                        if (edge.node.uuid === post.content.author) {
                          authorObj = edge;
                          break;
                        }
                      }
                      // TODO - graphql call with post.content.author guid
                      // static query - reference recent perspectives listing
                      // can't resolve a relation inside a relation - https://forum.storyblok.com/t/how-do-i-use-resolve-relations-for-nested-relationships-using-multi-options/319/4
                      return (
                        <CardPerspective
                          key={`perspective-${index}`}
                          author={authorObj.node.name}
                          date={post.content.date}
                          category={post.content.categories}
                          title={post.content.title}
                          url={`/${post.full_slug}`}
                          image={
                            post.content.thumbnail_image
                              ? post.content.thumbnail_image
                              : post.content.hero_image
                          }
                          className="has-delay"
                        />
                      );
                    })}
                  </div>
                </ScrollInView>
              </div>
            </div>
          </div>
        </div>
      </SbEditable>
    </div>
  );
};

export default FeaturedPerspectivesListing;
