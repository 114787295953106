import Intro from "@components/Intro";
import BenefitsListing from "@components/BenefitsListing";
import Gallery from "@components/Gallery";
import ComponentNotFound from "@components/ComponentNotFound";

const ComponentList = {
  intro: Intro,
  list_benefits: BenefitsListing,
  gallery_section: Gallery
};

const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
