import React from "react";
import { Link } from "react-head";
import { css } from "twin.macro";
import SbEditable from "storyblok-react";
import { withArtDirection, GatsbyImage } from "gatsby-plugin-image";
import {
  getGatsbyImageData,
  getGatsbyMobileImageData
} from "@helpers/getGatsbyImageData";
import { screen } from "@helpers/media";
import Parallax from "@utility/Parallax";
import clsxm from "@helpers/clsxm";

type Props = {
  blok: any;
  image: string;
  mobileImage?: string;
  keystring?: string;
  loading?: "eager" | "lazy";
  maxWidth?: number;
  mobileMaxWidth?: number;
  maxHeight?: number;
  mobileMaxHeight?: number;
  className?: string;
  hasParallax?: boolean;
  sizes?: string;
  mobileSizes?: string;
  multipliers?: number[];
  quality?: number;
  mobileMultipliers?: number[];
  styleW?: number;
  styleH?: number;
};

// Base image component using gatsby image
// with storyblok's image processor
const Image: React.FC<Props> = ({
  maxWidth = 1400,
  maxHeight,
  mobileMaxWidth = 710,
  mobileMaxHeight = 940,
  image = "",
  mobileImage = "",
  blok,
  className = "",
  loading = "lazy",
  hasParallax,
  keystring,
  sizes,
  quality,
  ...rest
}) => {
  if (!image) {
    return <></>;
  }

  // add ability to pass in a quality value for images
  let options = null;
  if (quality) {
    options = { quality };
  }

  let imageData = null;
  let desktopImageData = null;
  let retinaImageData = null;
  let mobileImageStyles = null;

  if (mobileImage) {
    const mobileImageData = getGatsbyMobileImageData({
      image: mobileImage,
      maxWidth: mobileMaxWidth,
      maxHeight: mobileMaxHeight,
      options
    });
    desktopImageData = getGatsbyImageData({
      image,
      maxWidth,
      maxHeight,
      options
    });
    // https://jakearchibald.com/2021/serving-sharp-images-to-high-density-screens/
    const retinaMaxWidth = maxWidth * 2;
    const retinaMaxHeight = maxHeight * 2;
    options = { quality: 80 };
    retinaImageData = getGatsbyImageData({
      image,
      retinaMaxWidth,
      retinaMaxHeight,
      options
    });

    imageData = withArtDirection(desktopImageData, [
      {
        media: "(max-width: 768px)",
        image: mobileImageData.image
      },
      {
        media: "(min-width: 769px) and (-webkit-min-device-pixel-ratio: 1.5)",
        image: retinaImageData
      }
    ]);

    mobileImageStyles = css`
      width: 100%;
      padding-top: ${mobileImageData.aspectRatio * 100}%;
      & > :first-child {
        position: absolute;
        top: 0;
      }
      & picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      @media ${screen.md} {
        width: auto;
        padding-top: 0;
        & > :first-child {
          position: static;
        }
        & picture {
          position: static;
          width: auto;
          height: auto;
        }
      }
    `;
  } else {
    desktopImageData = getGatsbyImageData({
      image,
      maxWidth,
      maxHeight,
      options
    });
    // https://jakearchibald.com/2021/serving-sharp-images-to-high-density-screens/
    const retinaMaxWidth = maxWidth * 2;
    const retinaMaxHeight = maxHeight * 2;
    options = { quality: 80 };
    retinaImageData = getGatsbyImageData({
      image,
      retinaMaxWidth,
      retinaMaxHeight,
      options
    });
    imageData = withArtDirection(desktopImageData, [
      {
        media: "(-webkit-min-device-pixel-ratio: 1.5)",
        image: retinaImageData
      }
    ]);
    // imageData = getGatsbyImageData({ image, maxWidth, maxHeight, options });
  }

  if (typeof imageData === "undefined") {
    console.error("Image not supplied");
    return (
      <SbEditable content={blok}>
        <span>Please add src</span>
      </SbEditable>
    );
  }

  return (
    <div
      className={clsxm("w-full overflow-hidden", className)}
      key={keystring}
      {...rest}
    >
      {loading === "eager" && (
        <>
          <Link
            rel="preload"
            as="image"
            imageSrcSet={desktopImageData?.images?.fallback?.srcSet}
            imageSizes={desktopImageData?.images?.fallback?.sizes}
            media="(-webkit-max-device-pixel-ratio: 1)"
          />
          <Link
            rel="preload"
            as="image"
            imageSrcSet={retinaImageData?.images?.fallback?.srcSet}
            imageSizes={retinaImageData?.images?.fallback?.sizes}
            media="(-webkit-min-device-pixel-ratio: 1.5)"
          />
        </>
      )}
      <SbEditable content={blok}>
        {hasParallax ? (
          <Parallax
            animation={[
              { y: 80, playScale: [0, 0] },
              { y: -80, playScale: [0, 1.8], ease: "easeInCubic" }
            ]}
            style={{ marginTop: "-80px" }}
          >
            <GatsbyImage
              image={imageData}
              sizes={sizes}
              loading={loading}
              css={mobileImageStyles}
              alt=""
            />
          </Parallax>
        ) : (
          <GatsbyImage
            image={imageData}
            loading={loading}
            sizes={sizes}
            css={mobileImageStyles}
            alt=""
          />
        )}
      </SbEditable>
    </div>
  );
};

export default Image;
