export const size = {
  smdown: "575px",
  sm: "576px",
  mddown: "767px",
  md: "768px",
  lg: "992px",
  xl: "1200px"
};

export const screen = {
  smdown: `(max-width: ${size.smdown})`,
  sm: `(min-width: ${size.sm})`,
  mddown: `(max-width: ${size.mddown})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`
};
