import React from "react";
import SbEditable from "storyblok-react";
import tw, { styled, css } from "twin.macro";
import ScrollInView from "@utility/ScrollInView";
import { BG_DARK } from "@src/common";
import clsxm from "@helpers/clsxm";
import LogoText from "./LogoText";
import { LogoTextType } from "./types";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1380%3A30
 */

type Props = {
  blok: {
    bg_dark: boolean;
    logo_text_blocks: LogoTextType[];
    component: string;
    _uid: string;
  };
};

export type BgWashProps = {
  bg_dark: boolean;
};

const BackgroundWash = styled.div`
  ${({ bg_dark }: BgWashProps) =>
    bg_dark &&
    css`
      ${BG_DARK}
      &:last-of-type {
        .logo-text-listing__blockrow {
          ${tw`md:border-b md:border-mono-700`}
        }
      }
    `}
`;

const LogoTextListing: React.FC<Props> = ({
  blok,
  blok: { logo_text_blocks, bg_dark }
}) => {
  return (
    <BackgroundWash
      className={clsxm("logo-text-listing", bg_dark && "is-dark-content")}
      bg_dark={bg_dark}
    >
      <SbEditable content={blok}>
        <ScrollInView>
          <div className="logo-text-listing__blockrow md:border-t md:border-mono-700 lg:mt-3">
            <div className="grid-wrapper">
              <div className="grid-row">
                <div className="col-12 lg:col-offset-1 lg:col-10">
                  <div className="flex flex-col md:flex-row md:-mx-5 lg:-mx-10">
                    {logo_text_blocks &&
                      logo_text_blocks.map(logo_text_block => (
                        <LogoText
                          key={logo_text_block._uid}
                          blok={logo_text_block}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollInView>
      </SbEditable>
    </BackgroundWash>
  );
};

export default LogoTextListing;
