import React from "react";

const ArrowLeft = () => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 1L1 5.5M1 5.5L5 10M1 5.5H16" stroke="#444444" />
  </svg>
);

export default ArrowLeft;
