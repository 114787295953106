import React from "react";
import tw, { styled } from "twin.macro";
import { CSSProp } from "styled-components";

type Props = {
  children: React.ReactNode;
  largeText?: boolean;
  className?: string;
  showLabel?: boolean;
  css?: CSSProp;
};

export const LineStyle = styled.span`
  ${tw`overflow-hidden ml-2 inline-block type-1630-loose`}
  ${props =>
    props.largeText ? tw`align-middle` : tw`align-bottom`}
  width: 14px;
  will-change: transform;
  transition: transform 0.5s cubic-bezier(0.42, 0, 0.29, 0.99),
    opacity 0.5s ease-out;
`;

const LabelStyle = styled.h2`
  ${tw`relative inline-block text-mono-600`}
  ${props =>
    props.largeText
      ? tw`type-2830 lg:type-3630`
      : tw`type-1430 lg:type-1630-loose`}
  a {
    will-change: opacity;
    transition: opacity 0.5s ease-out;
    &:hover,
    &:focus {
      ${tw`opacity-50`}
      ~ ${LineStyle} {
        ${tw`opacity-50`}
        transform: translateX(5px);
      }
    }
  }
`;

const outputLine = (children: React.ReactNode) => {
  let classes = "";
  if (children === "") {
    classes = "hidden";
  }
  return classes;
};

const LineStyleInner = styled.span`
  ${tw`block`}
  transform: translateX(-15px);
  will-change: transform;
  transition: transform 1.25s 0.5s cubic-bezier(0.42, 0, 0.29, 0.99);
  .is-in-view & {
    transform: translateX(0);
  }
`;

const Label: React.FC<Props> = ({
  children,
  className = "",
  showLabel = true,
  largeText = false,
  css
}) => {
  return (
    <LabelStyle className={className} css={css} largeText={largeText}>
      {children}
      &nbsp;
      <LineStyle largeText={largeText}>
        {showLabel && (
          <LineStyleInner className={`${outputLine(children)}`}>
            ––
          </LineStyleInner>
        )}
      </LineStyle>
    </LabelStyle>
  );
};

export default Label;
