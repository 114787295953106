import React, { useState, useEffect } from "react";
import tw, { styled, css } from "twin.macro";
import throttle from "lodash.throttle";
import Link from "@utility/Link";
import { screen } from "@helpers/media";
import bodyScroll from "@helpers/bodyScroll";
import {
  ANIMATE_IN,
  NAV_HEIGHT_MOBILE,
  NAV_HEIGHT_DESKTOP,
  SECONDARY_NAV_HEIGHT_MOBILE,
  SECONDARY_NAV_HEIGHT_DESKTOP,
  REVEAL_DURATION_MS
} from "@src/common";
import { LinkType } from "@src/types";
import NavItem from "./NavItem";
import NavItemSecondary from "./NavItemSecondary";
import NavItemSocial from "./NavItemSocial";
import Logo from "./Logo";
import NavAssetLayout from "./NavAssetLayout";
import { NavItemType, NavAssetLayoutType } from "./types";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1462%3A4
 * Landing page nav:
 * https://www.figma.com/file/zODzhJxGWO9syPmmnPEb7z/ED-Website-2022%2F23?node-id=3365%3A31074&t=NSYadPoU8YtcvS28-4
 */

type Props = {
  blok: {
    nav_items: NavItemType[];
    nav_asset_layout: NavAssetLayoutType[];
    text?: string;
    link?: LinkType;
    cta_label?: string;
    component: string;
  };
  componentName: string;
};
const OVERLAY_DURATION_MS = 350;
const SCROLL_OFFSET = 112;
const SECONDARY_NAV_SCROLL_OFFSET = 105;

interface StyledNavProps {
  $isSecondaryNavComponent: boolean;
}

const StyledLink = styled(Link)<StyledNavProps>`
  ${tw`fill-[currentColor] p-0`}
  ${props =>
    props.$isSecondaryNavComponent
      ? tw`relative block mt-9 lg:-mt-4`
      : tw`absolute block top-[36px]`}
  left: 0;
  z-index: 106;
  pointer-events: all;
  transition: opacity 0.3s ease-out;
  @media ${screen.lg} {
    top: 13px;
    &:hover {
      ${tw`opacity-60`}
    }
  }
  .has-scrolled & {
    ${tw`lg:opacity-0 lg:pointer-events-none`}
  }
  .has-menu-opened & {
    ${tw`lg:opacity-100`}
  }
`;

const NavBarWrapper = styled.nav<StyledNavProps>`
  ${tw`w-full`}
  height: ${props =>
    props.$isSecondaryNavComponent
      ? SECONDARY_NAV_HEIGHT_MOBILE
      : NAV_HEIGHT_MOBILE};
  .is-dark & {
    ${tw`bg-neutral-black`}
    color: rgba(255,255,255,0.75);
  }
`;

const NavBar = styled.nav(({ $isSecondaryNavComponent }: StyledNavProps) => [
  css`
    ${tw`w-screen fixed top-0 pointer-events-none bg-mono-000 lg:py-10 lg:bg-transparent!`}
    z-index: 102;
    height: ${$isSecondaryNavComponent
      ? SECONDARY_NAV_HEIGHT_MOBILE
      : NAV_HEIGHT_MOBILE};
    transition: transform 0.3s ease;
    transform: translateY(0);
    @media ${screen.lg} {
      transform: none;
    }
    .is-open & {
      ${tw`overflow-hidden`}
      pointer-events: all;
    }
    .is-dark & {
      ${tw`bg-neutral-black`}
      color: rgba(34, 34, 34, 0.75);
    }
    &.is-hidden {
      transform: translateY(
        -${$isSecondaryNavComponent ? SECONDARY_NAV_SCROLL_OFFSET : SCROLL_OFFSET}px
      );
      @media ${screen.lg} {
        transform: none;
      }
    }
  `
]);

const NavBarContainer = styled.div`
  @media ${screen.lg} {
    ${tw`bg-transparent`}
    z-index: 101;
  }
`;

const NavBarHeader = styled.div(
  ({ $isSecondaryNavComponent }: StyledNavProps) => [
    tw`w-full pointer-events-none relative`,
    css`
    height: ${
      $isSecondaryNavComponent ? SECONDARY_NAV_HEIGHT_MOBILE : NAV_HEIGHT_MOBILE
    };
    @media ${screen.lg} {
      ${tw`bg-transparent`}
      height: calc(${
        $isSecondaryNavComponent
          ? SECONDARY_NAV_HEIGHT_DESKTOP
          : NAV_HEIGHT_DESKTOP
      } - 80px);
    }
    ${tw`opacity-0`}
    ${ANIMATE_IN}
    .navbar--loaded & {
      ${tw`opacity-100`}
    }
    `
  ]
);

const Hamburger = styled.button(() => [
  tw`bg-transparent absolute block cursor-pointer transition-opacity ease-linear pointer-events-auto`,
  css`
      transition-property: filter;
      transition-duration: 0.15s;
      z-index: 103;
      top: 33px;
      right: 0;
      width: 40px;
      height: 40px;
      @media ${screen.lg} {
        top: 10px;
      }
      &:hover,
      &:focus {
        outline: 0;
      }
      &:hover:before {
        ${tw`opacity-100`}
        transform: scale(1.4);
      }
      &:before {
        content: "";
        ${tw`absolute inset-0 opacity-0`}
        @media ${screen.lg} {
          ${tw`border border-solid border-mono-600 border-opacity-30`}
          border-radius: 50%;
          transition: transform 0.2s ease-in-out, opacity 0.2s ease-in;
        }
      }
      &.hidden {
        ${tw`hidden`}
      }
    `
]);

const HamburgerInner = styled.span`
  ${tw`block`}
  &,
    &:before,
    &:after {
    ${tw`absolute bg-neutral-black w-10 h-05 rounded-lg`}
    width: 20px;
    @media ${screen.lg} {
      width: 25px;
    }
  }
  &:before,
  &:after {
    content: " ";
    ${tw`block`}
  }
  &:before {
    top: -6px;
  }
  &:after {
    bottom: -6px;
  }

  .is-dark & {
    ${tw`bg-white`}
    &:before,
      &:after {
      ${tw`bg-white`}
    }
  }
  .has-scrolled-over-hero & {
    ${tw`lg:bg-mono-600`}
    &:before,
      &:after {
      ${tw`lg:bg-mono-600`}
    }
  }
  @media ${screen.lg} {
    .has-menu-opened & {
      ${tw`bg-white`}
      &:before,
        &:after {
        ${tw`bg-white`}
      }
    }
    .is-dark.has-menu-opened & {
      ${tw`bg-neutral-black`}
      &:before,
        &:after {
        ${tw`bg-neutral-black`}
      }
    }
  }
`;

const NavBarList = styled.ul`
  ${tw`p-0 mb-8 text-mono-700 lg:w-auto lg:mx-auto lg:mb-0 opacity-0`}
  transform: translate3d(0,-80px,0);
  transition: 1s 0.3s cubic-bezier(0, 0, 0, 1), opacity 0.5s 0.3s ease-in;
  @media ${screen.lg} {
    transform: translate3d(0, 100px, 0);
    transition: 1.5s 0.3s transform cubic-bezier(0, 0.24, 0, 1),
      opacity 0.1s 0.3s ease-in;
  }

  .is-dark & {
    color: #d9d9d9;
  }
  .has-menu-opened & {
    ${tw`opacity-100`}
    transform: translate3d(0,0,0);
  }
`;
const NavBarListSecondary = styled.ul`
  ${tw`w-1/2 p-0 mb-6 text-mono-700 lg:w-auto lg:mb-0 opacity-0`}
  transform: translate3d(0,-80px,0);
  transition: 1s 0.3s cubic-bezier(0, 0, 0, 1), opacity 0.5s 0.3s ease-in;
  @media ${screen.lg} {
    transform: translate3d(0, 100px, 0);
    transition: 1.5s 0.45s transform cubic-bezier(0, 0.24, 0, 1),
      opacity 0.1s 0.45s ease-in;
  }

  .is-dark & {
    color: #d9d9d9;
  }
  .has-menu-opened & {
    ${tw`opacity-100`}
    transform: translate3d(0,0,0);
  }
`;

const Overlay = styled.div`
  ${tw`bg-white fixed top-0 left-0 overflow-hidden pointer-events-none w-screen h-full lg:h-screen`}
  transform: translateY(-100%);
  transition: transform ${OVERLAY_DURATION_MS}ms cubic-bezier(0.58, 0.57, 0, 1);
  z-index: 101;
  .is-dark & {
    ${tw`bg-neutral-black`}
  }
  @media ${screen.lg} {
    ${tw`pt-0 bg-neutral-black`}
    .is-dark & {
      ${tw`bg-white`}
    }
  }
  .is-open & {
    transform: translateY(0);
  }
`;

const OverlayContainer = styled.div`
  ${tw`relative w-full h-full opacity-0 overflow-y-auto lg:pb-0`}
  transition: opacity 0.1s ease-in;
  padding-top: ${NAV_HEIGHT_MOBILE};
  @media screen and (min-height: 650px) {
    ${tw`flex items-center pb-0 -mt-6`}
  }
  @media ${screen.lg} {
    ${tw`p-0 flex items-center`}
    max-width: 1440px;
    margin: 0 auto;
    min-height: 600px;
  }
  .is-open & {
    ${tw`opacity-100`}
    pointer-events: all;
  }
`;

const MobileNavDivider = styled.div`
  ${tw`pb-10 border-mono-300 border-b mb-12 w-screen`}
  margin-left: -15px;
  margin-right: -15px;
  height: 0;
  .is-dark & {
    ${tw`border-mono-700`}
  }
  @media ${screen.lg} {
    ${tw`hidden`}
    .is-dark & {
      ${tw`hidden`}
    }
  }
`;

const Navigation: React.FC<Props> = ({
  componentName = "default",
  blok: { text, component, nav_items, nav_asset_layout }
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [hasScrolledOverHero, setHasScrolledOverHero] = useState(false);

  const isPrimaryNavComponent = component === "navigation";
  const isSecondaryNavComponent = component === "navigation_secondary";

  const toggle = () => {
    if (isOpen) {
      bodyScroll.unlock();
      document.body.classList.remove("has-menu-opened");
    } else {
      // delay lock until overlay animation is complete
      // to avoid layout shift when position fixed
      setTimeout(() => {
        bodyScroll.lock();
      }, OVERLAY_DURATION_MS);
      document.body.classList.add("has-menu-opened");
    }
    return setIsOpen(!isOpen);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      document.body.classList.add("navbar--loaded");
    }, REVEAL_DURATION_MS + 250);
    return () => clearTimeout(timer);
  }, []);

  /* eslint consistent-return: "error" */
  useEffect(() => {
    return () => {
      bodyScroll.unlock();
      document.body.classList.remove("has-menu-opened");
      setIsOpen(false);
      setVisible(false);
    };
  }, []);

  useEffect(() => {
    // https://www.prwhite.io/blog/sticky-navbar-hides-scroll - but via debounce is strange
    // https://codingreflections.com/hide-header-on-scroll-down/
    const handleScroll = throttle(() => {
      // find current scroll position
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos > SCROLL_OFFSET) {
        // fade out navigation
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
      if (currentScrollPos > SCROLL_OFFSET + 500) {
        // offset + distance to first hero image
        setHasScrolledOverHero(true);
      } else {
        setHasScrolledOverHero(false);
      }
      // set state based on scroll position moving up, moved more than 70 px or near the top of page
      if (prevScrollPos > currentScrollPos) {
        // up
        if (!visible && (componentName !== "landing" || !hasScrolledOverHero)) {
          setVisible(true);
        }
      } else if (
        prevScrollPos < currentScrollPos &&
        currentScrollPos > SCROLL_OFFSET
      ) {
        // down
        if (visible) {
          setVisible(false);
        }
      }
      // set state to new scroll position
      setPrevScrollPos(currentScrollPos);
    }, 400);
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [componentName, hasScrolledOverHero, prevScrollPos, visible]);

  return (
    <NavBarWrapper
      className={`${isOpen ? "is-open" : ""}`}
      $isSecondaryNavComponent={isSecondaryNavComponent}
    >
      <NavBar
        data-category="Header"
        data-action="Nav"
        $isSecondaryNavComponent={isSecondaryNavComponent}
        className={`navbar ${!visible ? "is-hidden" : ""} ${
          hasScrolled ? "has-scrolled" : ""
        } ${hasScrolledOverHero ? "has-scrolled-over-hero" : ""}`}
      >
        <NavBarContainer
          className="grid-wrapper"
          $isSecondaryNavComponent={isSecondaryNavComponent}
        >
          <div className="grid-row">
            <div className="col-12 lg:col-offset-1 lg:col-10">
              <NavBarHeader $isSecondaryNavComponent={isSecondaryNavComponent}>
                <StyledLink
                  className="js-gtm"
                  to="/"
                  title="Home"
                  $isSecondaryNavComponent={isSecondaryNavComponent}
                  data-category="Header"
                  data-action="Nav"
                  data-label="Logo"
                >
                  <Logo />
                </StyledLink>
                {isPrimaryNavComponent && (
                  <Hamburger
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={toggle}
                    className={`hamburger hamburger--squeeze ${isOpen &&
                      "is-active"} ${componentName &&
                      componentName === "landing" &&
                      "hidden"}`}
                  >
                    <span className="sr-only">Toggle Navigation</span>
                    <span className="flex justify-center -mt-px">
                      <HamburgerInner className="hamburger-inner" />
                    </span>
                  </Hamburger>
                )}
                {!!isSecondaryNavComponent && !!text && (
                  <p
                    className="text-white type-1830 lg-down:mt-4 lg:inline-block relative block lg:bottom-0.5 lg:ml-10"
                    css={[
                      css`
                        pointer-events: all;
                        transition: opacity 0.3s ease-out;
                        .has-scrolled & {
                          ${tw`lg:opacity-0 lg:pointer-events-none`}
                        }
                      `
                    ]}
                  >
                    {text}
                  </p>
                )}
              </NavBarHeader>
            </div>
          </div>
        </NavBarContainer>
      </NavBar>
      <Overlay>
        <OverlayContainer>
          {nav_asset_layout && (
            <NavAssetLayout
              className="hidden lg:flex"
              sets={nav_asset_layout}
              reset={isOpen}
            />
          )}
          <div className="grid-wrapper">
            <div className="grid-row">
              <div
                className="col-10 col-offset-2 lg:col-4"
                role="navigation"
                aria-label="Main"
              >
                <NavBarList>
                  {nav_items &&
                    nav_items.map(blok =>
                      blok.component === "nav_item" ? (
                        <NavItem key={blok._uid} blok={blok} />
                      ) : (
                        ""
                      )
                    )}
                </NavBarList>
              </div>
              <MobileNavDivider />
              <div
                className="col-6 col-offset-2 lg:col-offset-0"
                role="navigation"
                aria-label="Secondary"
              >
                <NavBarListSecondary className="lg:mt-2">
                  {nav_items &&
                    nav_items.map(blok =>
                      blok.component === "nav_item_secondary" ? (
                        <NavItemSecondary key={blok._uid} blok={blok} />
                      ) : (
                        ""
                      )
                    )}
                </NavBarListSecondary>
              </div>
              <div className="col-4" role="navigation" aria-label="Social">
                <NavBarListSecondary className="lg:hidden">
                  {nav_items &&
                    nav_items.map(blok =>
                      blok.component === "nav_item_social" ? (
                        <NavItemSocial key={blok._uid} blok={blok} />
                      ) : (
                        ""
                      )
                    )}
                </NavBarListSecondary>
              </div>
            </div>
          </div>
        </OverlayContainer>
      </Overlay>
    </NavBarWrapper>
  );
};

export default Navigation;
