import React, { useState, useEffect } from "react";

type ThemeContextType = {
  dark: boolean;
  toggle: () => void;
  isDark: (val: boolean) => void;
};

const ThemeContext = React.createContext<ThemeContextType>({
  dark: false,
  toggle: () => {},
  isDark: () => {}
});

export default ThemeContext;

type ThemeProviderType = {
  children: React.ReactNode;
};
export function ThemeProvider({ children }: ThemeProviderType) {
  const [dark, setDark] = useState<boolean>(false);

  const toggle = () => {
    setDark(!dark);
  };

  const isDark = (val: boolean) => {
    setDark(val);
  };

  useEffect(() => {
    if (dark) {
      document.body.classList.add("is-dark");
    } else {
      document.body.classList.remove("is-dark");
    }
  }, [dark]);

  return (
    <ThemeContext.Provider value={{ dark, toggle, isDark }}>
      {children}
    </ThemeContext.Provider>
  );
}
