import React, { useState, useEffect } from "react";
import tw, { css } from "twin.macro";
import { useInView } from "react-intersection-observer";
import GlyphLottie from "@utility/glyphs/GlyphLottie";
import {
  NAV_HEIGHT_MOBILE,
  NAV_HEIGHT_DESKTOP,
  REVEAL_DURATION_MS
} from "@src/common";
import { screen } from "@helpers/media";
import { DynamicGlyphType } from "@src/types";
import GlyphDynamic from "./DynamicGlyph";

type Props = {
  glyphs: DynamicGlyphType[];
  className?: string;
  isHero?: boolean;
};

const getGlyphType = (component?: string) => {
  if (component === "moveable_glyph") {
    return GlyphDynamic;
  }
  if (component === "lottie_animation_glyph") {
    return GlyphLottie;
  }
  return GlyphDynamic;
};

const DynamicGlyphs: React.FC<Props> = ({
  glyphs,
  isHero = false,
  className = ""
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-300px 0px -300px 0px"
  });
  const [delayOver, setDelayOver] = useState<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayOver(true);
    }, REVEAL_DURATION_MS);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div
      css={[
        tw`absolute w-full h-full top-0`,
        isHero &&
          css`${tw`z-30`} 
          top:-${NAV_HEIGHT_MOBILE};
          height:calc(100% + ${NAV_HEIGHT_MOBILE});
          @media ${screen.lg} {
            top:-${NAV_HEIGHT_DESKTOP};
            height:calc(100% + ${NAV_HEIGHT_DESKTOP});
          }`
      ]}
      className={className}
      ref={ref}
    >
      {glyphs &&
        inView &&
        delayOver &&
        glyphs.map(glyph =>
          React.createElement(getGlyphType(glyph?.component), {
            key: glyph._uid,
            blok: glyph
          })
        )}
    </div>
  );
};

export default DynamicGlyphs;
