import React from "react";
import tw from "twin.macro";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import Date from "@utility/Date";
import AnimateKeyframes from "@utility/AnimateKeyframes";
import AnimateHeroText from "@utility/AnimateHeroText";
import ScrollInView from "@utility/ScrollInView";
import Link from "@utility/Link";
import SearchEngines from "@utility/Seo";
import Label from "@utility/Label";
import RichText from "@utility/RichText";
import RteBlock from "@utility/RichTextEditorStyles";
import { addSlash } from "@src/common";
import ArrowLeftFilled from "@utility/icons/ArrowLeftFilled";

const prettyURL = url => {
  const pretturl = url.replace(/^https?:\/\//i, "");
  return pretturl;
};

const renderNewsHeadline = (news_type, simple_headline, title) => {
  if (news_type !== "" && news_type === "simple_head") {
    if (simple_headline !== "") {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: simple_headline
          }}
        />
      );
    }
  }
  return title;
};

const renderNewsDate = date =>
  date !== "" && (
    <Date className="mb-8 type-1630-loose fade-in fade-in__1" content={date} />
  );

const renderNewsHtml = content => {
  if (typeof content === "string" || content instanceof String) {
    return (
      <ReactMarkdown tw="mb-8" escapeHtml={false}>
        {content}
      </ReactMarkdown>
    );
  }
  return <RichText content={content} />;
};

function NewsPostTemplate({
  blok: { seo, news_type, simple_headline, title, date, news_links, content },
  pageContext
}) {
  return (
    <div>
      <SearchEngines
        title={seo.title || pageContext.story.name || ""}
        description={seo.description || title || ""}
        date={date || ""}
      />
      <AnimateKeyframes twStyles={tw`pt-12 lg:pt-40`}>
        <div tw="grid-wrapper">
          <ScrollInView>
            <div tw="grid-row">
              <div tw="col-12 mt-8 mb-4 md:(col-offset-1 col-10) lg:(col-3 mb-0 margin-top[7px])">
                <Label className="type-1630-loose text-mono-600">
                  {renderNewsDate(date)}
                </Label>
              </div>
              <div tw="col-12 mt-10 mb-6 md:(col-offset-1 col-10 mt-0 mb-12) lg:(col-offset-0 col-5)">
                <AnimateHeroText>
                  <h1
                    tw="type-2830 md:type-3630 font-extralight text-mono-700 font-extralight"
                    className="fade-in fade-in__1"
                  >
                    {renderNewsHeadline(news_type, simple_headline, title)}
                  </h1>
                </AnimateHeroText>
              </div>
            </div>
          </ScrollInView>

          <ScrollInView>
            <div tw="grid-row">
              <div tw="mb-8 col-12 md:(col-offset-1 col-10) lg:(col-3 mb-0)">
                {news_links && news_links.length > 0 && (
                  <div className="fade-in fade-in__2">
                    <Label tw="mt-4 mb-8 text-mono-700 opacity-50">Links</Label>
                    {news_links.map((news_link, index) => (
                      <div tw="block mb-4" key={`${index}`}>
                        <Link
                          type="underline"
                          className="underline-links"
                          to={`${
                            news_link.link.url !== ""
                              ? news_link.link.cached_url
                              : `/${news_link.link.cached_url}`
                          }`}
                        >
                          {prettyURL(
                            addSlash(
                              news_link.link.url !== ""
                                ? news_link.link.cached_url
                                : `/${news_link.link.cached_url}`
                            )
                          )}
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div tw="col-12 mb-12 md:(col-offset-1 col-10 mb-32) lg:(col-offset-0 col-5)">
                <RteBlock className="fade-in fade-in__2 rte-content underline-links">
                  {renderNewsHtml(content)}
                </RteBlock>
                <Link
                  to="/news/"
                  type="hover"
                  className="fade-in fade-in__3 type-1630-loose w-auto inline-flex items-center"
                >
                  <ArrowLeftFilled />
                  <span tw="ml-3">Back to news list</span>
                </Link>
              </div>
            </div>
          </ScrollInView>
        </div>
      </AnimateKeyframes>
    </div>
  );
}

NewsPostTemplate.propTypes = {
  blok: PropTypes.shape({
    seo: PropTypes.object,
    news_type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    simple_headline: PropTypes.any,
    date: PropTypes.string.isRequired,
    news_links: PropTypes.array,
    content: PropTypes.any
  }),
  seo: PropTypes.objectOf(PropTypes.string),
  pageContext: PropTypes.shape({
    story: PropTypes.object
  })
};

NewsPostTemplate.defaultProps = {
  blok: {
    seo: {},
    simple_headline: "",
    news_links: {},
    content: ""
  },
  seo: {},
  pageContext: {}
};

export default NewsPostTemplate;
