import Intro from "@components/Intro";
import Service from "@components/Service";
import EngagementListing from "@components/EngagementListing";
import VerticalListing from "@components/VerticalListing";
import FeatureDriverListing from "@components/FeatureDriverListing";
import ComponentNotFound from "@components/ComponentNotFound";

const ComponentList = {
  intro: Intro,
  services_service: Service,
  list_engagements: EngagementListing,
  list_verticals: VerticalListing,
  list_feature_drivers: FeatureDriverListing
};

const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
