import React from "react";
import PropTypes from "prop-types";
import useWindowDimensions from "@utility/useWindowDimensions";

const TextArea = ({ text, className, nestedAnimation, mobile_text }) => {
  const { windowWidth } = useWindowDimensions();

  const textString =
    windowWidth <= 576 && mobile_text !== "" ? mobile_text : text;

  const formattedText = textString.split("\n").map((item, i) => {
    if (nestedAnimation) {
      return (
        <span key={i} className={`block ${className}`}>
          <span className="block">{item}</span>
        </span>
      );
    }
    return (
      <span key={i} className={`block ${className}`}>
        {item}
      </span>
    );
  });
  if (textString !== undefined) {
    return <>{formattedText}</>;
  }
  return <p>Define content</p>;
};
TextArea.defaultProps = {
  className: "",
  text: "",
  mobile_text: "",
  nestedAnimation: false
};

TextArea.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  mobile_text: PropTypes.string,
  nestedAnimation: PropTypes.bool
};

export default TextArea;
