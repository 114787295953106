import React from "react";
import templating from "@templates/Templating";

function StoryblokEntry({ pageContext, location = null }) {
  const story = { ...pageContext.story };
  const staticContent = { ...pageContext.static };
  story.content =
    pageContext.isStatic === true ? staticContent : JSON.parse(story.content);
  story.content.isStatic = pageContext.isStatic;

  const { content } = story;
  const contentType =
    pageContext.isStatic === true
      ? pageContext.contentType
      : story.content.component;

  return <div>{templating(contentType, content, location, pageContext)}</div>;
}

export default StoryblokEntry;
