import React from "react";
import SbEditable from "storyblok-react";
import tw, { css, styled } from "twin.macro";
import ScrollInView from "@utility/ScrollInView";
import TextArea from "@utility/TextArea";
import { BG_DARK } from "@src/common";
import { BgWashProps } from "@components/LogoTextListing";
import clsxm from "@helpers/clsxm";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1378%3A6176
 */

type Props = {
  blok: {
    bg_dark: boolean;
    component: string;
    description: string;
    title: string;
    mobile_title: string;
    _uid: string;
  };
};

const BackgroundWash = styled.div`
  ${({ bg_dark }: BgWashProps) =>
    bg_dark &&
    css`
      ${BG_DARK}
    `}
`;

const HeaderStyle = styled.h2`
  ${tw`type-serif-3660 md:type-serif-6460 mb-10 md:mb-12`}
`;

const LandingTextBlock: React.FC<Props> = ({
  blok,
  blok: { bg_dark, description, title, mobile_title }
}) => {
  return (
    <div tw="lg:col-10 lg:col-offset-1">
      <BackgroundWash
        className={clsxm(bg_dark && "is-dark-content")}
        bg_dark={bg_dark}
      >
        <SbEditable content={blok}>
          <div className="pt-14 pb-14 lg:pt-20 lg:pb-24">
            <div className="grid-wrapper">
              <div className="grid-row">
                <div className="col-12 lg:col-5 lg:col-offset-1">
                  <ScrollInView>
                    <div className="has-delay-container">
                      <HeaderStyle className={clsxm(bg_dark && "text-white")}>
                        <TextArea
                          text={title}
                          mobile_text={mobile_title}
                          nestedAnimation
                        />
                      </HeaderStyle>
                      {description && (
                        <p className="has-delay mb-10 type-2030 lg:type-1830">
                          {description}
                        </p>
                      )}
                    </div>
                  </ScrollInView>
                </div>
              </div>
            </div>
          </div>
        </SbEditable>
      </BackgroundWash>
    </div>
  );
};

export default LandingTextBlock;
