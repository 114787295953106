import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import PropTypes from "prop-types";
import RichText from "@utility/RichText";
import { screen } from "@helpers/media";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1464%3A0
 */

const RichTextStyle = styled(RichText)`
  ${tw`mb-12 text-mono-700`}
  h2 {
  ${tw`text-3xl`}
    @media ${screen.md} {
      ${tw`text-5xl`}
    }
  }
  p {
  ${tw`my-6 text-mono-700`}
    @media ${screen.md} {
      ${tw`mb-4`}
    }
  }
  image {
  ${tw`mb-12`}
    @media ${screen.md} {
      ${tw`mb-32`}
    }
  }
  a {
    ${tw`type-2030 text-mono-700 no-underline cursor-pointer`}
    border-bottom: 1px solid rgba(85, 85, 85, 0.5);
    padding-bottom: 0.2em;
    transition: border-bottom 0.3s ease;
    &:hover,
    &:focus {
      border-bottom: 1px solid rgba(85, 85, 85, 0.1);
    }
  }
  p.bold {
    font-weight:800;
  }
`;

const outputFont = font_type => {
  let classes = "";
  if (font_type === "poynter") {
    classes = "type-serif-4260";
  } else if (font_type === "gibson_book_large") {
    classes = "type-4820";
  } else if (font_type === "gibson_light") {
    classes = "type-3630 font-extralight";
  } else if (font_type === "gibson_book") {
    classes = "type-3630 font-light";
  } else if (font_type === "gibson_regular") {
    classes = "type-3630 font-medium";
  } else if (font_type === "gibson_medium") {
    classes = "type-3630 font-bold";
  } else {
    classes = "type-1830";
  }
  return classes;
};

const FullWidthText = ({ blok: { rich_text_sample, font_type } }) => {
  return (
    <div>
      <RichTextStyle
        className={`${outputFont(font_type)}`}
        content={rich_text_sample}
      />
    </div>
  );
};

FullWidthText.propTypes = {
  blok: PropTypes.shape({
    rich_text_sample: PropTypes.shape({
      content: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string
        })
      ),
      type: PropTypes.string
    }),
    font_type: PropTypes.string
  })
};

FullWidthText.defaultProps = {
  blok: {
    rich_text_sample: "",
    font_type: ""
  }
};

export default FullWidthText;
