import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import tw from "twin.macro";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1457%3A1
 */

const Container = styled.div`
  ${tw`mx-auto`}
  max-width: 1440px;
`;

const HeroInnerContainer = ({ children, className }) => (
  <Container className={className}>{children}</Container>
);

HeroInnerContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

HeroInnerContainer.defaultProps = {
  className: ""
};

export default HeroInnerContainer;
