import React from "react";

const ArrowRight = () => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
  >
    <path d="M12 1L16 5.5M16 5.5L12 10M16 5.5H0" stroke="#444444" />
  </svg>
);

export default ArrowRight;
