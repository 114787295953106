/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import tw, { styled } from "twin.macro";
import Image from "@utility/Image";
import Carousel from "@utility/Carousel";
import { WorkConfigType } from "@src/types";
import WorkSectionContainer from "@utility/WorkSectionContainer";
import MobileWrapper from "./MobileThreeUpWrapper";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1463%3A6184
 */

type Props = {
  blok: {
    has_background: boolean;
    carousel_duration: string;
    carousel_hide_controls: boolean;
    slides: {
      caption: string;
      component: "flexible_image";
      image: string;
      _editable: string;
      _uid: string;
    }[];
    component: string;
    _uid: string;
    work_config: WorkConfigType;
  };
};

const ImageWrapper = styled.div`
  ${tw`w-full sm:w-1/3 inline-block`}
`;

const MobileThreeUp: React.FC<Props> = ({
  blok,
  blok: { carousel_duration, carousel_hide_controls, slides }
}) => {
  // https://medium.com/@networkaaron/swiper-how-to-destroy-swiper-on-min-width-breakpoints-a947491ddec8
  // https://medium.com/swlh/using-window-matchmedia-for-media-queries-in-reactjs-97ddc66fca2e
  // https://github.com/gatsbyjs/gatsby/issues/5835#issuecomment-571700094
  const [hasRan, setHasRan] = useState<boolean>(false);
  const [mQuery, setMQuery] = useState<boolean | MediaQueryListEvent>(false);
  useEffect(() => {
    if (!hasRan) {
      setHasRan(true);
      setMQuery(window.innerWidth > 992);
    }
    const mediaQuery = window.matchMedia("(min-width: 992px)");
    mediaQuery.addListener(setMQuery);
    // this is the cleanup function to remove the listener
    return () => mediaQuery.removeListener(setMQuery);
  }, []);

  if (slides && slides.length >= 1) {
    const showCarousel = !mQuery || slides.length > 3;
    return (
      <WorkSectionContainer blok={blok} inline={!showCarousel}>
        <div className="col-12 space-y-20 lg:col-10 lg:col-offset-1 lg:space-y-0 text-center">
          {!showCarousel ? (
            slides.map((slide, index) => (
              <ImageWrapper key={index}>
                <MobileWrapper className="three-up--carousel">
                  <Image
                    className="rounded-2xl shadow-mobile xl:shadow-tablet"
                    image={slide.image}
                    blok={blok}
                    maxWidth={528}
                  />
                </MobileWrapper>
              </ImageWrapper>
            ))
          ) : (
            <div className="carousel-mobile-3up">
              <Carousel
                images={slides}
                duration={carousel_duration}
                carouselHideControls={carousel_hide_controls}
                maxSlides={3}
              >
                {slides.map((slide, index) => (
                  <ImageWrapper key={index}>
                    <MobileWrapper className="three-up--carousel">
                      <Image
                        className="rounded-2xl shadow-mobile xl:shadow-tablet"
                        image={slide.image}
                        blok={blok}
                        loading="eager"
                        maxWidth={528}
                      />
                    </MobileWrapper>
                  </ImageWrapper>
                ))}
              </Carousel>
            </div>
          )}
        </div>
      </WorkSectionContainer>
    );
  }

  return (
    <>
      <p>Please update mobile three up images component</p>
    </>
  );
};

export default MobileThreeUp;
