import React, { useRef } from "react";
import tw, { styled } from "twin.macro";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "@utility/FormInput";
import FormCheckbox from "@utility/FormCheckbox";
import Button from "@utility/Button";
import gtmPush from "@helpers/gtmPush";

/*
 * Visual Reference
 * https://www.figma.com/file/TWO2VuPSqYKYww3Ni83udq/Bike-Business-Landing-Page?node-id=440%3A413
 */
type Props = {
  blok: any;
};

const FormContainer = styled.div`
  ${tw`relative`}
  z-index:61;
`;

type FormData = {
  "cm-f-jrjyek": string;
  "cm-name": string;
  "cm-jljyxk-jljyxk": string;
  "cm-privacy-consent": boolean;
};
const schema = yup.object().shape({
  "cm-name": yup.string().required("Name is required."),
  "cm-jljyxk-jljyxk": yup
    .string()
    .required("Email is required.")
    .email("Please input valid email.")
});

const BikeForm: React.FC<Props> = ({ blok }) => {
  const { register, trigger, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const [state, setState] = React.useState<FormData>();
  const formRef = useRef<HTMLFormElement>(null);
  const submitButton = useRef<HTMLButtonElement>(null);

  const handleChange = (
    e: React.FormEvent & {
      target: HTMLFormElement;
    }
  ) => {
    trigger(e.target.name);
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    gtmPush("Bike Landing Campaign", "Form", "Submit");
    submitButton.current.click();
  };

  return (
    <FormContainer>
      <form
        ref={formRef}
        tw="mt-6 mb-0"
        className="js-cm-form"
        id="subForm"
        action="https://www.createsend.com/t/subscribeerror?description="
        method="post"
        data-id="191722FC90141D02184CB1B62AB3DC26EBB4B32230B5FEE6C177BE6DE471BE2B6967805C9C3C8B8FC32BAF85EBF85CA3C0A1ACEA747A49ABC8877B2978F5A878"
      >
        <div>
          <div tw="mb-4">
            <FormInput
              type="text"
              message="Name*"
              name="cm-name"
              id="fieldName"
              onChange={handleChange}
              ref={register}
              style={{
                borderColor: errors["cm-name"] && "#ff0303"
              }}
              errorMessage={errors["cm-name"]?.message}
            />
          </div>
          <div tw="mb-4">
            <FormInput
              type="email"
              message="Email*"
              name="cm-jljyxk-jljyxk"
              id="fieldEmail"
              inputClass="js-cm-email-input qa-input-email"
              onChange={handleChange}
              ref={register}
              style={{
                borderColor: errors["cm-jljyxk-jljyxk"] && "#ff0303"
              }}
              errorMessage={errors["cm-jljyxk-jljyxk"]?.message}
            />
          </div>
          <div tw="mb-4">
            <FormInput
              type="text"
              message="Company"
              name="cm-f-jrjyek"
              id="fieldjrjyek"
              onChange={handleChange}
              ref={register}
            />
          </div>
          <div tw="col-12 sm:(flex justify-between items-center my-4)">
            <div tw="sm:(flex justify-between items-center w-full)">
              <input
                id="cm-privacy-consent-hidden"
                name="cm-privacy-consent-hidden"
                type="hidden"
                value="true"
              />
              <FormCheckbox
                name="cm-privacy-consent"
                message="Join email subscriber list."
                value
                onChange={handleChange}
                tw="mt-4 sm:mt-0"
                ref={register}
              />
              <Button
                button
                onClick={handleSubmit(onSubmit)}
                label="Send"
                type="button"
                twStyles={tw`mt-8 sm:(mt-0 ml-8)`}
              />
              <button ref={submitButton} type="submit" className="hidden">
                Send
              </button>
            </div>
          </div>
        </div>
      </form>
      <script
        type="text/javascript"
        src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"
      />
    </FormContainer>
  );
};

export default BikeForm;
