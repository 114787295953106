/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SearchEngines({ description, lang, meta, title, image, date }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaImage = `https://${image}` || site.siteMetadata.image;
  const metaDate = date || site.date;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      defer={false}
      title={title}
      meta={[
        // add URL here and have it statically generated for each page (no need to add to individual pages)
        {
          name: `description`,
          content: metaDescription,
          key: `meta-description`
        },
        {
          property: `og:title`,
          content: title,
          key: `meta-ogtitle`
        },
        {
          property: `og:image`,
          content: metaImage,
          key: `meta-ogimage`
        },
        {
          property: `og:description`,
          content: metaDescription,
          key: `meta-ogdescription`
        },
        {
          property: `og:type`,
          content: `website`,
          key: `meta-ogtype`
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
          key: `meta-twcard`
        },
        {
          name: `twitter:image`,
          // content: metaImage,
          content: metaImage,
          key: `meta-twimage`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
          key: `meta-twcreator`
        },
        {
          name: `twitter:title`,
          content: title,
          key: `meta-twtitle`
        },
        {
          name: `twitter:description`,
          content: metaDescription,
          key: `meta-twdescription`
        },
        {
          name: `date`,
          content: metaDate,
          key: `meta-date`
        }
      ].concat(meta)}
    >
      <script type="text/javascript">dataLayer = [];</script>
    </Helmet>
  );
}

SearchEngines.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: ""
};

SearchEngines.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string
};

export default SearchEngines;
