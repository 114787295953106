import React from "react";
import SbEditable from "storyblok-react";
import tw, { styled } from "twin.macro";
import RichText from "@utility/RichText";
import ScrollInView from "@utility/ScrollInView";
import DynamicGlyphs from "@utility/glyphs/DynamicGlyphs";
import { screen } from "@helpers/media";
import { generateDynamicCol } from "@helpers/generateDynamicClasses";
import { Col, DynamicGlyphType } from "@src/types";
import clsxm from "@helpers/clsxm";
import GalleryImage from "./GalleryImage";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1456%3A0
 */

type Props = {
  blok: {
    title: string;
    description: any;
    mobile_image: string;
    image_columns: {
      _uid: string;
      column_width: Col;
      images: {
        _uid: string;
        image: string;
      }[];
    }[];
    image_rows: {
      _uid: string;
      alignment: string;
      images: {
        _uid: string;
        image: string;
      }[];
    }[];
    component: string;
    _uid: string;
    glyphs: DynamicGlyphType[];
  };
};

const GalleryContainer = styled.div`
  ${tw`pt-16 pb-28 grid-overflow`}

  @media ${screen.lg} {
    ${tw`py-50 `}
  }

  .rte {
    p {
      ${tw`pb-4`}
      &:last-child {
        ${tw`pb-0`}
      }
    }
  }
`;

const Gallery: React.FC<Props> = ({
  blok,
  blok: { title, description, image_columns, image_rows, glyphs }
}) => {
  return (
    <div className="w-full relative">
      <SbEditable content={blok}>
        {glyphs && <DynamicGlyphs glyphs={glyphs} />}
        <div className="grid-wrapper">
          <GalleryContainer>
            <ScrollInView>
              <div className="grid-row hidden md:flex">
                {image_columns &&
                  image_columns.map(({ column_width, images }, index) => {
                    return (
                      <div
                        key={`link${index}`}
                        css={[
                          tw`col-12`,
                          generateDynamicCol("md:col-", column_width),
                          index === 0 && tw`md:col-offset-1`
                        ]}
                      >
                        {images &&
                          images.map(element =>
                            React.createElement(GalleryImage, {
                              key: element._uid,
                              blok: element,
                              sizes: `(max-width: 1440px) calc(100vw * (${column_width}/12)), calc(1440px * (${column_width}/12))`
                            })
                          )}
                      </div>
                    );
                  })}
              </div>
            </ScrollInView>
            <ScrollInView>
              <div className="md:hidden block">
                {image_rows &&
                  image_rows.map(({ alignment, images, _uid }) => (
                    <div
                      className={clsxm(
                        "flex flex-nowrap",
                        alignment === "bottom" && "items-end",
                        alignment === "center" && "items-center"
                      )}
                      key={_uid}
                    >
                      {images &&
                        images.map(element =>
                          React.createElement(GalleryImage, {
                            key: element._uid,
                            blok: element,
                            sizes: "(max-width: 768px) 50vw, 50vw"
                          })
                        )}
                    </div>
                  ))}
              </div>
            </ScrollInView>
            {title && description && (
              <ScrollInView className="grid-overflow__clear">
                <div className="grid-row mt-20">
                  <div className="col-12 md:col-offset-1 md:col-4 lg:col-offset-2 lg:col-3">
                    <h4 className="text-mono-700 type-2830 md:pr-4 lg:pr-12">
                      {title}
                    </h4>
                  </div>
                  <div className="col-12 md:col-6 lg:col-5 md:mr-auto">
                    <RichText
                      className="type-1830 text-mono-700 md:pt-1 rte has-delay-container-richtext"
                      content={description}
                    />
                  </div>
                </div>
              </ScrollInView>
            )}
          </GalleryContainer>
        </div>
      </SbEditable>
    </div>
  );
};

export default Gallery;
