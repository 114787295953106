import React from "react";
import { WorkConfigType } from "@src/types";
import WorkSectionContainer from "@utility/WorkSectionContainer";
import Quote from ".";

/*
 * Visual Reference
 * https://www.figma.com/file/RfCCV6mDbDrecTopwi9sJQ/ED-Website-2020?node-id=1463%3A6256
 */

type Props = {
  blok: typeof Quote & {
    work_config: WorkConfigType;
    _uid: string;
    component: string;
  };
};

const QuoteBlock: React.FC<Props> = ({ blok }) => {
  return (
    <WorkSectionContainer blok={blok}>
      <Quote {...blok} />
    </WorkSectionContainer>
  );
};

export default QuoteBlock;
