import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import RichText from "@utility/RichText";
import Date from "@utility/Date";
import HeaderType from "@utility/Header";
import AnimateKeyframes from "@utility/AnimateKeyframes";
import AnimateHeroText from "@utility/AnimateHeroText";
import ScrollInView from "@utility/ScrollInView";
import TextArea from "@utility/TextArea";
import { screen } from "@helpers/media";
import Components from "@builder/ComponentsPerspectiveLanding";
import Image from "@utility/Image";
import CardAuthor from "@utility/CardAuthor";
import SearchEngines from "@utility/Seo";
import RteBlock from "@utility/RichTextEditorStyles";
import HeaderContainer from "@utility/HeaderContainer";
import HeroInnerContainer from "@utility/HeroInnerContainer";

const PerspectivesMeta = styled.ul`
  ${tw`font-extralight justify-between hidden flex-wrap items-start w-full p-0 md:flex mt-8 md:mt-16`}
  @media ${screen.lg} {
    ${tw`relative bg-transparent justify-center`}
  }
`;

const MetaItem = styled.li`
  ${tw` w-1/2`}
  @media ${screen.md} {
    ${tw`w-auto`}
  }
  @media ${screen.lg} {
    ${tw`w-auto pr-6`}
  }
`;

const MobileMeta = styled.div`
  ${tw`text-mono-600 pt-6 md:hidden`}
`;

const MobileMetaLower = styled.div`
  ${tw`text-mono-600 pt-10 md:hidden`}
`;

const SubTitle = styled.div`
  ${tw`mb-8`}
  h2 {
    ${tw`type-2830 font-extralight md:mb-12`}
  }
`;

function wordCount(str) {
  return str.split(" ").length;
}

const getDurationEstimate = massagedPerspectivesData => {
  if (massagedPerspectivesData.duration) {
    return `${massagedPerspectivesData.duration} minute read`;
  }
  let count = 0;
  massagedPerspectivesData.html.content.forEach(block => {
    block.content.forEach(part => {
      if (part.type && part.type === "text" && part.text) {
        count += wordCount(part.text);
      }
    });
  });
  const estimate = Math.ceil(count / 200);
  return `${estimate} minute read`;
};

const renderPerspectivesCategory = massagedPerspectivesData => {
  return massagedPerspectivesData.categories
    .split("-")
    .map(function capitalize(part) {
      return part.charAt(0).toUpperCase() + part.slice(1);
    })
    .join(" ");
};

const renderPerspectivesHtml = massagedPerspectivesData => {
  if (massagedPerspectivesData.html !== "") {
    return <RichText content={massagedPerspectivesData.html} />;
  }
};

const hasMobileImage = massagedPerspectivesData =>
  massagedPerspectivesData.thumbnail_image &&
  massagedPerspectivesData.thumbnail_image !== "";

const renderHeroImage = massagedPerspectivesData => {
  return (
    <Image
      image={massagedPerspectivesData.hero_image}
      blok={massagedPerspectivesData}
      maxWidth={2640}
      maxHeight={1632}
      quality={80}
      sizes="(max-width: 1440px) 100vw, 1400px"
      mobileImage={
        hasMobileImage ? massagedPerspectivesData.thumbnail_image : ""
      }
      mobileMaxWidth={720}
      mobileMaxHeight={960}
      loading="eager"
    />
  );
};

function PerspectivePostTemplate(props) {
  const {
    full_slug,
    blok: { body }
  } = props;
  const massagedPerspectivesData = {
    title: props.blok.title,
    date: props.blok.date,
    sub_title: props.blok.sub_title,
    intro_image: props.blok.intro_image,
    hero_image: props.blok.hero_image,
    thumbnail_image: props.blok.thumbnail_image,
    author: props.blok.author.name,
    author_uuid: props.blok.author.uuid,
    categories: props.blok.categories,
    duration: props.blok.duration,
    html: props.blok.html,
    seo_title: props.blok.seo.title,
    seo_description: props.blok.seo.description,
    seo_image: props.blok.seo.og_image || props.blok.hero_image
  };

  function wordCount(str) {
    return str.split(" ").length;
  }
  const getDurationEstimate = () => {
    if (massagedPerspectivesData.duration) {
      return `${massagedPerspectivesData.duration} minute read`;
    }
    let count = 0;
    massagedPerspectivesData.html.content.forEach(block => {
      block.content.forEach(part => {
        if (part.type && part.type === "text" && part.text) {
          count += wordCount(part.text);
        }
      });
    });
    const estimate = Math.ceil(count / 200);
    return `${estimate} minute read`;
  };

  const renderPerspectivesCategory = () => {
    if (massagedPerspectivesData.categories) {
      return massagedPerspectivesData.categories
        .split("-")
        .map(function capitalize(part) {
          return part.charAt(0).toUpperCase() + part.slice(1);
        })
        .join(" ");
    }
  };

  const renderPerspectivesHtml = () => {
    if (massagedPerspectivesData.html !== "") {
      return <RichText content={massagedPerspectivesData.html} />;
    }
  };

  const hasMobileImage = !!(
    massagedPerspectivesData.thumbnail_image &&
    massagedPerspectivesData.thumbnail_image !== ""
  );

  const renderHeroImage = () => {
    if (massagedPerspectivesData.hero_image !== null) {
      return (
        <>
          <Image
            image={massagedPerspectivesData.hero_image}
            blok={massagedPerspectivesData}
            maxWidth={2640}
            maxHeight={1632}
            quality={80}
            sizes="(max-width: 1440px) 100vw, 1400px"
            mobileImage={
              hasMobileImage ? massagedPerspectivesData.thumbnail_image : ""
            }
            mobileMaxWidth={720}
            mobileMaxHeight={960}
            loading="eager"
          />
        </>
      );
    }
  };
  return (
    <div>
      <SearchEngines
        title={massagedPerspectivesData.seo_title || ""}
        description={massagedPerspectivesData.seo_description || ""}
        image={massagedPerspectivesData.seo_image || ""}
        date={massagedPerspectivesData.date || ""}
      />
      <AnimateKeyframes>
        <HeaderContainer>
          <div className="grid-wrapper">
            <div className="grid-row">
              <div className="col-12 md:col-10 md:col-offset-1 lg:col-8 lg:col-offset-2">
                <AnimateHeroText>
                  <HeaderType className="">
                    <TextArea
                      text={massagedPerspectivesData.title}
                      className="auto-height md:text-center"
                      nestedAnimation
                    />
                  </HeaderType>
                </AnimateHeroText>

                <MobileMeta className="fade-in fade-in__3">
                  {massagedPerspectivesData.author !== null && (
                    <MetaItem className="inline">
                      By&nbsp;
                      <span>{massagedPerspectivesData.author}</span>
                    </MetaItem>
                  )}
                  &nbsp;&#8211;&nbsp;
                  <MetaItem className="inline">
                    {getDurationEstimate(massagedPerspectivesData)}
                  </MetaItem>
                </MobileMeta>

                <PerspectivesMeta className="fade-in fade-in__3">
                  <MetaItem>
                    {getDurationEstimate(massagedPerspectivesData)}
                  </MetaItem>
                  <MetaItem>
                    <Date content={massagedPerspectivesData.date} />
                  </MetaItem>
                  {massagedPerspectivesData.author !== null && (
                    <MetaItem>
                      By&nbsp;
                      <span>{massagedPerspectivesData.author}</span>
                    </MetaItem>
                  )}
                  {massagedPerspectivesData.categories !== null &&
                    massagedPerspectivesData.categories.length > 0 && (
                      <MetaItem>
                        Filed under&nbsp;
                        <span>
                          {renderPerspectivesCategory(massagedPerspectivesData)}
                        </span>
                      </MetaItem>
                    )}
                </PerspectivesMeta>
              </div>
            </div>
          </div>
        </HeaderContainer>
        <div className="grid-wrapper">
          <HeroInnerContainer>
            {massagedPerspectivesData.hero_image &&
              massagedPerspectivesData.hero_image.length > 0 && (
                <div className="grid-overflow">
                  <div className="grid-row">
                    <div className="col-12 fade-in fade-in__3">
                      {renderHeroImage(massagedPerspectivesData)}
                    </div>
                  </div>
                </div>
              )}
          </HeroInnerContainer>
          <MobileMetaLower className="fade-in fade-in__3">
            <MetaItem className="inline pr-5">
              <Date content={massagedPerspectivesData.date} />
            </MetaItem>
            {massagedPerspectivesData.categories !== null &&
              massagedPerspectivesData.categories.length > 0 && (
                <MetaItem className="inline">
                  &nbsp;&#8211;&nbsp;
                  <span>
                    {renderPerspectivesCategory(massagedPerspectivesData)}
                  </span>
                </MetaItem>
              )}
          </MobileMetaLower>
          <div className="grid-row">
            <div className="type-2030 col-12 mt-12 md:col-offset-1 md:col-10 md:mt-25 lg:col-offset-3 lg:col-6">
              <ScrollInView>
                <RteBlock className="rte-content">
                  {massagedPerspectivesData.sub_title !== "" && (
                    <SubTitle>
                      <h2>{massagedPerspectivesData.sub_title}</h2>
                    </SubTitle>
                  )}
                  {renderPerspectivesHtml(massagedPerspectivesData)}
                  <div className="mt-12 mb-24 lg:mb-50">
                    {massagedPerspectivesData.author_uuid !== null && (
                      <CardAuthor uid={massagedPerspectivesData.author_uuid} />
                    )}
                  </div>
                </RteBlock>
              </ScrollInView>
            </div>
          </div>
        </div>
      </AnimateKeyframes>
      <div>
        {body &&
          body.map(element =>
            React.createElement(Components(element.component), {
              key: element._uid,
              blok: element,
              current_post: full_slug
            })
          )}
      </div>
    </div>
  );
}

export default PerspectivePostTemplate;
