import React from "react";
import SbEditable from "storyblok-react";
import tw, { styled } from "twin.macro";
import LandingBenefit from "@utility/LandingBenefit";
import Glyph3Up from "@utility/glyphs/GlyphThreeUp";
import Label from "@utility/Label";
import ScrollInView from "@utility/ScrollInView";
import { screen } from "@helpers/media";
import { EngagementBlock } from "@src/types";
import clsxm from "@helpers/clsxm";

/*
 * Visual Reference
 * https://www.figma.com/file/zODzhJxGWO9syPmmnPEb7z/ED-Website-2022%2F23?node-id=3365%3A31148&t=GLpPWROvsT8oTjSe-4
 */

type Props = {
  blok: {
    bg_wash_desktop: boolean;
    bg_wash_mobile: boolean;
    component: string;
    description: string;
    engagement_blocks: EngagementBlock[];
    label: string;
    show_glyph: boolean;
    _uid: string;
  };
};

const GlyphContainerStyle = styled.div`
  ${tw`absolute text-mono-100`}
  top: 1vw;
  right: 0;
  width: 36vw;
  z-index: 1;
  @media ${screen.lg} {
    width: 360px;
    top: -90px;
  }
  @media ${screen.xl} {
    width: 400px;
    top: 2vw;
  }
  .js-svg {
    fill: #444444;
  }
`;

const renderGlyph = bool => {
  if (bool) {
    return (
      <GlyphContainerStyle>
        <Glyph3Up />
      </GlyphContainerStyle>
    );
  }
};

const LandingBenefitsListing: React.FC<Props> = ({
  blok,
  blok: {
    bg_wash_mobile,
    bg_wash_desktop,
    engagement_blocks,
    label = "Benefits",
    description,
    show_glyph
  }
}) => {
  return (
    <div
      className={clsxm(
        "w-full",
        bg_wash_mobile ? "bg_wash-mobile py-32" : "my-32",
        bg_wash_desktop ? "bg_wash-desktop md:py-50" : "md:my-50"
      )}
    >
      <SbEditable content={blok}>
        <div>
          <div className="grid-wrapper">
            <div className="grid-row">
              <div className="col-10 md:col-6 md:col-offset-1 lg:col-4">
                <ScrollInView>
                  <div className="has-delay-container">
                    <Label className="has-delay pb-10" largeText>
                      {label}
                    </Label>
                    {!!description && (
                      <p className="has-delay mb-8 type-1830">{description}</p>
                    )}
                  </div>
                </ScrollInView>
              </div>
            </div>
          </div>
          <div className="grid-wrapper">
            <div className="grid-row">
              <div className="col-12 lg:col-offset-1 lg:col-10">
                <ScrollInView>
                  <div className="has-delay-container">
                    <div className="flex flex-wrap flex-col md:flex-row md:-mx-5 lg:-mx-10">
                      {engagement_blocks &&
                        engagement_blocks.map((engagement_block, index) =>
                          React.createElement(LandingBenefit, {
                            key: engagement_block._uid,
                            blok: engagement_block,
                            isFirstBlock: index === 0
                          })
                        )}
                    </div>
                  </div>
                </ScrollInView>
              </div>
            </div>
          </div>
          {renderGlyph(show_glyph)}
        </div>
      </SbEditable>
    </div>
  );
};

export default LandingBenefitsListing;
